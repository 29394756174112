(function() {
  'use strict';

  angular
    .module('app')
    .controller('ForgotPassController', ForgotPassController);

  ForgotPassController.$inject = ['AuthService', '$stateParams', '$state', 'FixedContentService', 'LoginMessages', 'UsersService', '$modalInstance', 'toaster'];

  function ForgotPassController(AuthService, $stateParams, $state, FixedContentService, LoginMessages, UsersService, $modalInstance, toaster) {
    var vm = this;

    vm.loading = false;
    vm.ResetPassword = ResetPassword;

    function ResetPassword()
    {
      vm.loading = true;
      AuthService
        .ForgotPassword({
          Email: vm.User.Email,
          UserName: vm.User.UserName
        })
        .then(function() {
          vm.loading = false;
          toaster.pop('success', "Reset Password Sent", "An email has been sent to the specified email. Please follow the instructions there to reset your password.");
          $modalInstance.close();
        }, function(error) {
          vm.loading = false;
          //error.statusText
          toaster.pop('error', "Either the specified username or email does exist in the system", "");
        });
    }
  }

}());
