(function() {
  'use strict';

  angular
    .module('app.admin.configuration')
    .constant('LocalesConstant', [{
      Code: "el-gr",
      Name: "Greek / Greece",
    }, {
      Code: "en-gb",
      Name: "English / Great Britain",
    }])
    .constant('TimezonesConstant', [
      "Europe/Athens",
      "Europe/London"
    ]);

}());
