/* jshint -W097, -W033 */
(function() {
  'use strict';

  angular
    .module('app.shared')
    .directive('slMultilingualInput', slMultilingualInput)

  slMultilingualInput.$inject = []

  function slMultilingualInput() {

    var directive = {
      templateUrl: 'src/com.shared/_common/directives/multilingualinput/multilingualinput.html',
      restrict: 'EA',
      // link: link,
      scope: {
        Model: '=model',
        Label: '@label',
        Prop: '@prop',
      },
      controller: MultilingualInputController,
      controllerAs: 'vm',
      bindToController: true // because the scope is isolated
    };

    return directive;

    MultilingualInputController.$inject = ['$scope', 'LocaleMap', 'FlagsConstant'];

    function MultilingualInputController($scope, LocaleMap, FlagsConstant) {

      var vm = this;
      vm.Label = vm.Label || vm.Prop;
      vm.Languages = _.map(LocaleMap,function(loc){
        return loc
      });
      vm.Flags = FlagsConstant;

    }
  }

}());
