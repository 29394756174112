/* jshint -W097, -W033 */
(function() {
  'use strict';

  angular
    .module('app.admin.clients')
    .run(appRun);

  appRun.$inject = ['routerHelper'];

  function appRun(routerHelper) {
    routerHelper.configureStates(getStates());
  }

  function getStates() {
    return [{
      state: 'admin.clients.client.referrals',
      config: {
        url: '/referrals',
        params: {
          IsArchived: false,
          IsCaseClosed: false
        },
        templateUrl: 'src/com.admin/clients/referrals/dashboard/view.html',
        controller: "ReferralsDashboardController",
      }
    }, {
      state: 'admin.clients.client.archived',
      config: {
        url: '/archived',
        params: {
          IsArchived: true,
          IsCaseClosed: false
        },
        templateUrl: 'src/com.admin/clients/referrals/dashboard/view.html',
        controller: "ReferralsDashboardController",
      }
    }, {
      state: 'admin.clients.client.closedcases',
      config: {
        url: '/closedcases',
        params: {
          IsArchived: false,
          IsCaseClosed: true
        },
        templateUrl: 'src/com.admin/clients/referrals/dashboard/view.html',
        controller: "ReferralsDashboardController",
      }
    }, {
      state: 'admin.clients.client.calendar',
      config: {
        url: '/calendar',
        templateUrl: 'src/com.admin/clients/referrals/calendar/view.html',
        controller: "ReferralsCalendarController",
      }
    }, {
      state: 'admin.clients.client.audits',
      config: {
        url: "/audits",
        templateUrl: 'src/com.admin/clients/referrals/audits/view.html',
        controller: "AuditsController",
      }
    }, {
      state: 'admin.clients.client.survey',
      config: {
        abstract: true,
        url: "/referrals/:ReferralID",
        params: {
          ReferralID: null,
          HideFirstMeeting: false,
          IsArchived: false,
          ShowFinalDischarge: false,
          IsCaseClosed: false
        },
        templateUrl: 'src/com.admin/clients/referrals/survey/view.html',
        controller: "ReferralsSurveyController",
      }
    }, {
      state: 'admin.clients.client.survey.referral',
      config: {
        url: "/referral",
        templateUrl: 'src/com.admin/clients/referrals/referral/view.html',
        controller: "ReferralsReferralController",
      }
    }, {
      state: 'admin.clients.client.survey.phonetriage',
      config: {
        url: "/phonetriage",
        templateUrl: 'src/com.admin/clients/referrals/phonetriage/view.html',
        controller: "ReferralsPhoneTriageController",
      }
    }, {
      state: 'admin.clients.client.survey.firstmeeting',
      config: {
        url: "/firstmeeting",
        templateUrl: 'src/com.admin/clients/referrals/firstmeeting/view.html',
        controller: "ReferralsFirstMeetingController",
      }
    }, {
      state: 'admin.clients.client.survey.discharge',
      config: {
        url: "/discharge",
        templateUrl: 'src/com.admin/clients/referrals/discharge/view.html',
        controller: "ReferralsDischargeController",
      }
    }, {
      state: 'admin.clients.client.survey.managernotes',
      config: {
        url: "/managernotes",
        templateUrl: 'src/com.admin/clients/referrals/managernotes/view.html',
        controller: "ReferralsNotesController",
      }
    }, {
      state: 'admin.clients.client.survey.finaldischarge',
      config: {
        url: "/finaldischarge",
        templateUrl: 'src/com.admin/clients/referrals/finaldischarge/view.html',
        controller: "ReferralsFinalDischargeController",
      }
    }, {
      state: 'admin.clients.client.survey.appointments',
      config: {
        url: "/appointments",
        templateUrl: 'src/com.admin/clients/referrals/appointments/view.html',
        controller: "ReferralsAppointmentsController",
      }
    }];
  }
}());
