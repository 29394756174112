(function() {
  'use strict';

  angular
  .module('app.admin.clients')
  .controller('ClientSurveysEditController', ClientSurveysController);

  ClientSurveysController.$inject = ["ModalParams", 'SurveysService', '$modalInstance', 'ReferralRoles', 'toaster'];

  function ClientSurveysController(ModalParams, SurveysService, $modalInstance, ReferralRoles, toaster) {

    var vm = this;

    vm.Roles = ReferralRoles;

    // xrhsimopoieitai gia ton elegxo tou an exei svhstei apo to basic survey alla yparxei sto custom (search 'remote_from_custom')
    vm._basicQuestions = [];
    vm._surveyCode = "";

    // afto to array xrhsimopoieitai gia na elegxoume ean ta epilegmena surveys einai idiou savecode
    vm.LoadedSurveys = [];

    vm.CustomSurvey = {};
    vm.CustomSurvey.questions = {};
    vm.CustomSurvey.attributes = {};
    vm.CustomSurvey.groups = {};

    vm.LoadSurvey = LoadSurvey;
    vm.InitialLoadToGroups = InitialLoadToGroups;
    vm.LoadCustomSurvey = LoadCustomSurvey;

    vm.SaveSurvey = SaveSurvey;
    vm.AddNewGroup = AddNewGroup;
    vm.RemoveGroup = RemoveGroup;

    vm.GroupToBeEdited = [];
    vm.EnableGroupNameEdit = EnableGroupNameEdit;
    vm.EditGroupName = EditGroupName;
    vm.SimilarEntitiesFound = false;

    vm.AddToCustomSurveyGroups = AddToCustomSurveyGroups;
    vm.ItemMoved = ItemMoved;

    vm.ClearSelectedRolesAndSurveys = ClearSelectedRolesAndSurveys;
    vm.ClearAndNew = ClearAndNew;

    activate();

    function activate() {
      vm.LoadSurvey(false);
    }

    function LoadSurvey(RoleType) {
      vm.loading = true;
      // ean yparxei hdh o rolos tote prepei na ton vgaloume
      if(!_.isUndefined(vm.UserRoles) && vm.UserRoles !== null && vm.UserRoles.indexOf(RoleType) > -1) {
        _removeRoleAndSurvey(RoleType);
        vm.loading = false;
        vm.SimilarEntitiesFound = false;
      }
      else {
        SurveysService
          .GetSurveyById(ModalParams.SurveyID)
          .then(function(survey) {
            vm.Survey = survey;
            vm.Survey.questionnaire = JSON.parse(survey.Configuration);
          }, function(error) {
            toaster.pop('error', "Could not retrieve the data", error.statusText);
          })
          .finally(function() {
            vm.CustomSurvey = {};
            vm.CustomSurvey.code = vm.Survey.code;
            vm.CustomSurvey.Name = vm.Survey.Name;
            vm.CustomSurvey.Type = vm.Survey.Type;
            vm.CustomSurvey.IsCustom = true;
            vm.CustomSurvey.IsActive = vm.Survey.IsActive;
            vm.CustomSurvey.CanEdit = _initialRoleCanEdit(vm.Survey.code, RoleType);
            vm.CustomSurvey.Configuration = "";

            vm.CustomSurvey.groups = {};
            vm.CustomSurvey.groups.nogroup = {
              title: "No Group",
              questions: []
            };

            vm.InitialLoadToGroups();

            vm._surveyCode = vm.Survey.code;
            if(RoleType) {
              vm.LoadCustomSurvey(RoleType);
            }
            else {
              vm.loading = false;
            }
          });
      }
    }

    function InitialLoadToGroups() {
      _.each(vm.Survey.questionnaire.groups, function(group, group_alias) {
        vm.CustomSurvey.groups[group_alias] = {
          title: group.title,
          can_be_deleted: true,
          questions: []
        };

        _.each(group.questions, function(question_alias, question_index) {
          var _question = {
            alias: question_alias,
            mandatory: vm.Survey.questionnaire.questions[question_alias].mandatory,
            similar_entity: vm.Survey.questionnaire.questions[question_alias].similar_entity
          };

          if(_question.mandatory) {
            vm.CustomSurvey.groups[group_alias].can_be_deleted = false;
          }

          _.each(vm.Survey.questionnaire.questions[question_alias].attributes, function(attribute, key) {
            _question[key] = attribute.value;
          });

          vm.CustomSurvey.groups[group_alias].questions.push(_question);

          // prostethike gia tin anagh pou uparxoun similar components. to similar_entity theoreitai attribute, not basic attribute
          var _attributes = vm.Survey.questionnaire.questions[question_alias].attributes;
          // opote ean uparxei afto to attribute tote valto sth lista gia ton elegxo
          if(!_.isUndefined(vm.Survey.questionnaire.questions[question_alias].similar_entity)) {
            _attributes.similar_entity = vm.Survey.questionnaire.questions[question_alias].similar_entity;
          }
          vm._basicQuestions[question_alias] = _attributes;
        });
      });
    }

    function LoadCustomSurvey(RoleType) {
      vm.MissingDependenciesText = "";

      SurveysService
      .GetSurveyByRole(vm._surveyCode, true, RoleType)
      .then(function(customSurvey) {
        vm.CustomSurvey = customSurvey.CustomSurvey;

        var decodedCustomSurvey = JSON.parse(customSurvey.CustomSurvey.Configuration);
        vm.CustomSurvey.groups = decodedCustomSurvey.groups;

        // ginetai clone ton group giati ginotan mutate me to splice
        var clonedCustomSurveyGroups = decodedCustomSurvey.groups;
        // aftos o pinakas xreiazetai gia na elegxoume gia to poies erothseis den yparxoun sto custom kai na prostehoun. xrhsimopoietai parakato (search 'add_to_custom')
        var customQuestionsInGroups = [];

        // oi custom erothseis pernane apo elegxo gia na:
        //  1. doume an exei svhstei kapoia apo to basic tote prepei na pesei sto lako ths sparths
        //  2. doume ean exei allaksei kapoio attribute, etsi oste na to afairesoume an den xreiazetai
        _.each(clonedCustomSurveyGroups, function(group, group_alias) {
          _.each(group.questions, function(question, question_index) {

            // remote_from_custom: kathe erothsh pou exei svhsei apo to basic survey kai yphrxe sto custom svhnetai. prepei na ginei apothikeush gia na paiksei h allagh giati prepei na allaksei to custom json
            if(_.isUndefined(vm._basicQuestions[question.alias])) {
              vm.CustomSurvey.groups[group_alias].questions.splice(question_index,1);
            }
            else {
              // koitame ean yparxoun allages sta attribute kai svhnoume afta pou den yparxoun sto basic
              // h prosthetoume afta pou yparxoun apo to basic
              _.each(question, function(attribute_value, attribute_key) {

                // to alias & to mandatory & to similar_entity den theloume na elegxontai giati einai panta mesa kai einai systemika.
                if(attribute_key !== "alias" && attribute_key !== "mandatory") {
                  // ean den yparxei sto basic ena attribute apo to custom tote svhsto
                  if(_.isUndefined(vm._basicQuestions[question.alias][attribute_key])) {
                    delete vm.CustomSurvey.groups[group_alias].questions[question_index][attribute_key];
                  }

                  // gia kathe ena apo ta basic pou den yparxoun sto custom, tote prosthese ta
                  _.each(vm._basicQuestions[question.alias], function(basic_attribute, basic_attribute_key) {
                    if(_.isUndefined(vm.CustomSurvey.groups[group_alias].questions[question_index][basic_attribute_key])) {
                      vm.CustomSurvey.groups[group_alias].questions[question_index][basic_attribute_key] = basic_attribute;
                    }
                  });
                }
              });
              customQuestionsInGroups.push(question.alias);
            }
          });
        });

        // add_to_custom: kathe erothsh pou den yparxei sto custom prostithetai sto nogroup
        _.each(vm.Survey.questionnaire.questions, function(question, question_alias) {
          if(customQuestionsInGroups.indexOf(question_alias) === -1) {
            var _question = {
              alias: question_alias,
              mandatory: vm.Survey.questionnaire.questions[question_alias].mandatory,
              similar_entity: vm.Survey.questionnaire.questions[question_alias].similar_entity
            };

            _.each(question.attributes, function(attribute, key) {
              // ean einai pinakas tote vazoume atofio to attribute kathos to value einai olos o pinakas, allios to property value
              if(_.isArray(attribute)) {
                _question[key] = attribute;
              }
              else {
                _question[key] = attribute.value;
              }
            });

            vm.CustomSurvey.groups.nogroup.questions.push(_question);
          }
        });
      }, function(error) {
        toaster.pop('error', "Could not retrieve the data", error.statusText);
      })
      .finally(function() {
        // antikeimeno gia na vlepoume poia custom kai mh surveys exoun epilexthei
        var _loadedCustomSurvery = {
          ID: vm.CustomSurvey.ID,
          SaveCode: vm.CustomSurvey.SaveCode,
          RoleType: RoleType,
          CustomSurveyObject: angular.copy(vm.CustomSurvey)
        };

        vm.LoadedSurveys.push(_loadedCustomSurvery);

        // ean den einai idia tote emfanizoume minima (vm.ShowSurveyAlert = true) kai den mporei na apothikefsei (vm.CanSave = false)
        vm.ShowSurveyAlert = !_surveysAreTheSame();
        vm.CanSave = !vm.ShowSurveyAlert;

        if(vm.LoadedSurveys.length > 0) {
          // fortonoume to object tou protou index apo to array ton surveys
          vm.CustomSurvey = vm.LoadedSurveys[0].CustomSurveyObject;
        }

        vm.loading = false;
      });
    }

    function SaveSurvey() {
      var json = {};
      json.alias = vm.Survey.code;
      json.title = vm.Survey.Name;
      json.groups = vm.CustomSurvey.groups;

      // orizoume to configuration gia na apothikeftei, kathos kai to role type
      vm.CustomSurvey.Configuration = angular.toJson(json);
      vm.CustomSurvey.Sort = vm.Survey.Sort;

      // svhnoume ta helper properties gia na etoimasoume to object na petaksei sto Api
      delete vm.CustomSurvey.groups;

      var SurveysToSave = [];
      vm.CustomSurvey.SaveCode = _getGUID();
      _.each(vm.UserRoles, function(value, key) {
        var _customSurvey = angular.copy(vm.CustomSurvey);
        _customSurvey.RoleType = value;

        var indexOfRole = _indexOfRoleInLoadedSurvey(value);
        _customSurvey.ID = !_.isUndefined(vm.LoadedSurveys[indexOfRole].ID) ? vm.LoadedSurveys[indexOfRole].ID : -1;

        SurveysToSave.push(_customSurvey);
      });

      var _componentsWithDependenciesMissing = {};

      // apothikevoume ta nea
      SurveysService
        .SaveSurveys(SurveysToSave)
        .then(function(ComponentsWithDependenciesMissing){
          _componentsWithDependenciesMissing = ComponentsWithDependenciesMissing;
          toaster.pop('success',"Survey was saved.", "Saved!");
        }, function(error) {
          toaster.pop('error', "Could not save the data", error.statusText);
        })
        .finally(function() {
          if(_hasMissingDependencies(_componentsWithDependenciesMissing)) {
            _displayMissingDependencies(_componentsWithDependenciesMissing);
          }
          vm.UserRoles = null;
          vm.LoadedSurveys = [];
          vm.LoadSurvey(false);
        });
    }

    function AddNewGroup() {
      vm.AddToCustomSurveyGroups(vm.NewGroup, [], false);
    }

    function RemoveGroup(GroupAlias) {
      if(vm.CustomSurvey.groups[GroupAlias].can_be_deleted) {
        _.each(vm.CustomSurvey.groups[GroupAlias].questions, function(value, key) {
          vm.CustomSurvey.groups.nogroup.questions.push(value);
          if(!_.isUndefined(vm.ShowOptions) && !_.isUndefined(vm.ShowOptions[value.alias])) {
            vm.ShowOptions[value.alias] = false;
          }
        });

        delete vm.CustomSurvey.groups[GroupAlias];
      }
      else {
        toaster.pop('info',"Group can not be deleted.", "This Group has mandatory components and can not be deleted!");
      }
    }

    function EnableGroupNameEdit(GroupAlias, isEnabled) {
      if(_.isUndefined(vm.GroupToBeEdited[GroupAlias])) {
        vm.GroupToBeEdited[GroupAlias] = {};
      }

      vm.GroupToBeEdited[GroupAlias].enabled = isEnabled;

      if(isEnabled) {
        vm.GroupToBeEdited[GroupAlias].title = vm.CustomSurvey.groups[GroupAlias].title;
      }
      else {
        vm.CustomSurvey.groups[GroupAlias].title = vm.GroupToBeEdited[GroupAlias].title;
      }
    }

    function EditGroupName(OldGroupAlias) {
      vm.AddToCustomSurveyGroups(vm.CustomSurvey.groups[OldGroupAlias].title, vm.CustomSurvey.groups[OldGroupAlias].questions, OldGroupAlias);
    }

    function AddToCustomSurveyGroups(title, questions, oldGroupAlias) {
      var group_key = title.toLowerCase().replace(/ /g, '_').replace(/[^\w\s]/gi, '');
      if(_.isUndefined(vm.CustomSurvey.groups[group_key])) {
        vm.CustomSurvey.groups[group_key] = {
          title: title,
          can_be_deleted: true,
          questions: questions
        };
        // an den dothike old group alias tote etrekse apo new group
        if(!oldGroupAlias){
          vm.NewGroup = "";
        }
        else {
          delete vm.CustomSurvey.groups[oldGroupAlias];
          vm.GroupToBeEdited[oldGroupAlias] = false;
        }
      }
      else {
        toaster.pop('info',"Group Exists.", "This group already exists!");
      }
    }

    function ItemMoved(GroupAlias, index) {
      vm.CustomSurvey.groups[GroupAlias].questions.splice(index, 1);
      _checkIfGroupCanBeDeleted();
      _checkIfSimilarEntitiesExist();
    }

    function ClearSelectedRolesAndSurveys() {
      vm.UserRoles = [];
      vm.LoadedSurveys = [];
      vm.ShowSurveyAlert = false;
      vm.CanSave = false;
    }

    function ClearAndNew() {
      vm.LoadSurvey(false);
      vm.ShowSurveyAlert = false;
      vm.CanSave = true;
    }

    function _removeRoleAndSurvey(RoleType) {
      vm.UserRoles.splice(vm.UserRoles.indexOf(RoleType), 1);

      var indexOfRole = _indexOfRoleInLoadedSurvey(RoleType);
      vm.LoadedSurveys.splice(indexOfRole, 1);

      vm.ShowSurveyAlert = !_surveysAreTheSame();
      vm.CanSave = !vm.ShowSurveyAlert;

      if(vm.LoadedSurveys.length > 0) {
        // fortonoume to object tou protou index apo to array ton surveys
        vm.CustomSurvey = vm.LoadedSurveys[0].CustomSurveyObject;
      }
    }

    function _indexOfRoleInLoadedSurvey(RoleType) {
      var indexOfRole;
      _.find(vm.LoadedSurveys, function(roleType, roleTypeIdx){
        if(roleType.RoleType == RoleType) {
          indexOfRole = roleTypeIdx;
        }
      });
      return indexOfRole;
    }

    // elegxei ean ola ta epilegmena surveys exoun ton idio kodiko
    function _surveysAreTheSame() {
      var surveysAreTheSame = true;
      if(vm.LoadedSurveys.length > 0) {
        var _tempSurvey = vm.LoadedSurveys[0];
        _.each(vm.LoadedSurveys, function(survey, key){
          if(_tempSurvey.SaveCode !== survey.SaveCode) {
            surveysAreTheSame = false;
          }
          _tempSurvey = survey;
        });
      }
      return surveysAreTheSame;
    }

    // dhmiourgei guid kathe fora pou apothikevoume survey
    function _getGUID() {
       return 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'.replace(/[x]/g, function(c) {
         var num = Math.random() *16 | 0, v = c === 'x' ? num : (num&0x3|0x8);
         return v.toString(16);
        });
    }

    // kathe fora pou ginetai metakinhsh enos item, tote prepei na elegxoume tis listes me ta group gia to ean yparxei mandatory gia na min mporei na svhstei
    function _checkIfGroupCanBeDeleted() {
      _.each(vm.CustomSurvey.groups, function(group, group_alias) {
        group.can_be_deleted = true;
        _.each(group.questions, function(question, question_index) {
          if(question.mandatory) {
            vm.CustomSurvey.groups[group_alias].can_be_deleted = false;
          }
        });
      });
    }

    // kathe fora pou ginetai metakinhsh enos item, tote prepei na elegxoume tis listes me ta group gia to ean yparxei similar entity
    // etsi oste na mhn mporei na apothikefsei kai na vgainei katallhlo minima
    function _checkIfSimilarEntitiesExist() {
      vm.SimilarEntitiesFound = false;

      // reset all similar_entity_exists
      _.each(vm.CustomSurvey.groups, function(group, group_alias) {
        _.each(group.questions, function(question, question_index) {
          question.similar_entity_exists = false;
        });
      });

      // elegxoume ta components pou den einai sto nogroup gia na doume ean yparxoun similar_entity
      _.each(vm.CustomSurvey.groups, function(group, group_alias) {
        if(group_alias != "nogroup"){
          _.each(group.questions, function(question, question_index) {
            // ean vrethikan similar prepei na psaksoume na doume se poia group einai gia na deiksoume to info triangle
            if(question.similar_entity) {
              _.each(question.similar_entity, function(similar_entity, similar_entity_index) {
                // PROSOXH: gia kathe ena entity prepei na psakso se ola ta group na do ean yparxei to similar. den katafera na to kano kalytero, opote tha xreiastei sto mellon na to kanoume kalytero
                _.each(vm.CustomSurvey.groups, function(_group, _group_alias) {
                    if(_group_alias !== "nogroup"){
                      var foundArray = _.where(vm.CustomSurvey.groups[_group_alias].questions, {
                        alias: similar_entity
                      });

                      if(foundArray.length > 0) {
                        vm.SimilarEntitiesFound = true;
                        _.each(foundArray, function(found_component, found_component_index) {
                          found_component.similar_entity_exists = true;
                        });
                      }
                    }
                });
              });
            }
          });
        }
      });

      vm.CanSave = !vm.ShowSurveyAlert && !vm.SimilarEntitiesFound;
    }

    function _displayMissingDependencies(ComponentsWithDependenciesMissing) {
      var _text = "<ul>";
      _.each(ComponentsWithDependenciesMissing, function(componentWithDependenciesMissing, key) {
        _.each(componentWithDependenciesMissing.DisplayText, function(textToDisplay, key) {
          _text += "<li>" + textToDisplay + "</li>";
        });
      });
      _text += "</ul>";
      vm.MissingDependenciesText = _text;
    }

    function _hasMissingDependencies(ComponentsWithDependenciesMissing) {
      var hasMissingDependencies = false;
      _.each(ComponentsWithDependenciesMissing, function(componentWithDependenciesMissing, key) {
        _.each(componentWithDependenciesMissing.DependedComponents, function(dependedComponent, key) {
          if(dependedComponent.HasMissingDependencies) {
            hasMissingDependencies = true;
          }
        });
      });
      return hasMissingDependencies;
    }

    function _initialRoleCanEdit(surveyCode, role) {
      if(role) {
        var canEditArray = {
          referral: ["MODERATOR", "PHYSIO", "ANONYMOUS"],
          phonetriage: ["PHYSIO"],
          firstmeeting: ["PHYSIO"],
          discharge: ["PHYSIO"],
          finaldischarge: ["PHYSIO"],
          managernotes: ["MANAGER"],
          appointments: ["MODERATOR", "PHYSIO", "RECEPTIONIST"]
        };

        return canEditArray[surveyCode].indexOf(role) !== -1;
      }
      return false;
    }
  }
}());
