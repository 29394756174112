/* jshint -W097, -W064 */
(function() {
  'use strict';

  angular
    .module('app.admin.configuration')
    .factory('ConfigurationModals', ConfigurationModals);

  ConfigurationModals.$inject = ['modalHelper'];

  function ConfigurationModals(modalHelper) {
    return modalHelper.configureModals(getModals());

    function getModals() {
      return [{
        modal: "CompanyForm",
        config: {
          templateUrl: 'src/com.admin/configuration/company/company.form.modal.html',
          controller: "CompanyFormController",
        },
      }, {
        modal: "LocalizationForm",
        config: {
          templateUrl: 'src/com.admin/configuration/localization/localizationform.modal.html',
          controller: "LocalizationFormController",
          size: "md"
        }
      }, {
        modal: "PaypalForm",
        config: {
          templateUrl: 'src/com.admin/configuration/payments/paypal/paypal.form.modal.html',
          controller: "PaypalFormController",
          size: "md"
        }
      }, {
        modal: "AlphaBankForm",
        config: {
          templateUrl: 'src/com.admin/configuration/payments/alphabank/alphabank.form.modal.html',
          controller: "AlphabankFormController",
          size: "md"
        }
      },{
        modal: "PdfForm",
        config: {
          templateUrl: 'src/com.admin/configuration/pdf/pdf.form.modal.html',
          controller: "SurveyPdfFormController",
        },
      }];
    }
  }

}());
