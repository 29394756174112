(function() {
  'use strict';

  angular
    .module('app.admin.clients')
    .controller('ReportsController', ReportsController);

  ReportsController.$inject = ['$stateParams', 'DashboardService', 'AuthService', 'SurveysService', 'Reports', 'ReportService', 'Diagnosis', 'ConditionType'];

  function ReportsController($stateParams, DashboardService, AuthService, SurveysService, Reports, ReportService, Diagnosis, ConditionType) {

    var vm = this;

    vm.Diagnosis = Diagnosis;
    vm.ConditionType = ConditionType;

    vm.GetKPIBasicReport = GetKPIBasicReport;
    vm.GetKPIAdvancedReport = GetKPIAdvancedReport;
    vm.GetEfficiencyReport = GetEfficiencyReport;
    vm.GetTypeOfInjuryReport = GetTypeOfInjuryReport;
    vm.GetConditionTypeReport = GetConditionTypeReport;
    vm.GetSameInjuryWithPreviousReferralReport = GetSameInjuryWithPreviousReferralReport;
    vm.GetTypeOfInjuryPerDepartmentReport = GetTypeOfInjuryPerDepartmentReport;
    vm.GetHighestTypeOfInjuryPerDepartmentReport = GetHighestTypeOfInjuryPerDepartmentReport;
    vm.GetReferralsConfirmedWithin24HoursReport = GetReferralsConfirmedWithin24HoursReport;
    vm.GetHandlingRequestReport = GetHandlingRequestReport;
    vm.GetChronicityReport = GetChronicityReport;
    vm.GetTypeOfWorkReport = GetTypeOfWorkReport;
    vm.GetGeneralReport = GetGeneralReport;

    activate();

    function activate() {
      SurveysService._ClientID = $stateParams.ID;

      vm.Reports = Reports;
    }

    function GetKPIBasicReport(dateFrom, dateTo) {
      //console.log("dt",dateFrom);
      vm.ReportLoading = true;

      dateFrom.setHours(12);
      dateTo.setHours(12);

      dateFrom = dateFrom.toISOString();
      dateTo = dateTo.toISOString();

      ReportService
        .GetKPIBasicReport(dateFrom, dateTo)
        .then(function(result) {
          vm.KPIBasicReportResult = result;
          vm.ReportLoading = false;
        }, function(error) {
          vm.ReportLoading = false;
          toaster.pop('error', "Could not retrieve the data", error.statusText);
        });
    }

    function GetEfficiencyReport(dateFrom, dateTo) {
      vm.ReportLoading = true;

      dateFrom = dateFrom.toISOString();
      dateTo = dateTo.toISOString();

      ReportService
        .GetEfficiencyReport(dateFrom, dateTo)
        .then(function(result) {
          vm.EfficiencyReportResults = result;
          vm.ReportLoading = false;
        }, function(error) {
          vm.ReportLoading = false;
          toaster.pop('error', "Could not retrieve the data", error.statusText);
        });
    }

    function GetKPIAdvancedReport(dateFrom, dateTo) {
      vm.ReportLoading = true;

      dateFrom = dateFrom.toISOString();
      dateTo = dateTo.toISOString();

      ReportService
        .GetKPIAdvancedReport(dateFrom, dateTo)
        .then(function(result) {
          vm.KPIAdvancedReportResult = result;
          vm.ReportLoading = false;
        }, function(error) {
          vm.ReportLoading = false;
          toaster.pop('error', "Could not retrieve the data", error.statusText);
        });
    }

    function GetTypeOfInjuryReport(dateFrom, dateTo) {
      vm.ReportLoading = true;

      dateFrom = dateFrom.toISOString();
      dateTo = dateTo.toISOString();

      ReportService
        .GetTypeOfInjuryReport(dateFrom, dateTo)
        .then(function(result) {
          vm.TypeOfInjuryReportResults = result;
          vm.ReportLoading = false;
        }, function(error) {
          vm.ReportLoading = false;
          toaster.pop('error', "Could not retrieve the data", error.statusText);
        });
    }

    function GetConditionTypeReport(dateFrom, dateTo) {
      vm.ReportLoading = true;

      dateFrom = dateFrom.toISOString();
      dateTo = dateTo.toISOString();

      ReportService
        .GetConditionTypeReport(dateFrom, dateTo)
        .then(function(result) {
          vm.ConditionTypeReportResults = result;
          vm.ReportLoading = false;
        }, function(error) {
          vm.ReportLoading = false;
          toaster.pop('error', "Could not retrieve the data", error.statusText);
        });
    }

    function GetSameInjuryWithPreviousReferralReport(dateFrom, dateTo) {
      vm.ReportLoading = true;

      dateFrom = dateFrom.toISOString();
      dateTo = dateTo.toISOString();

      ReportService
        .GetSameInjuryWithPreviousReferralReport(dateFrom, dateTo)
        .then(function(result) {
          vm.SameInjuryWithPreviousReferralReportResults = result;
          vm.ReportLoading = false;
        }, function(error) {
          vm.ReportLoading = false;
          toaster.pop('error', "Could not retrieve the data", error.statusText);
        });
    }

    function GetTypeOfInjuryPerDepartmentReport(dateFrom, dateTo) {
      vm.ReportLoading = true;

      dateFrom = dateFrom.toISOString();
      dateTo = dateTo.toISOString();

      ReportService
        .GetTypeOfInjuryPerDepartmentReport(dateFrom, dateTo)
        .then(function(result) {
          vm.TypeOfInjuryPerDepartmentReportResults = result;
          vm.ReportLoading = false;
        }, function(error) {
          vm.ReportLoading = false;
          toaster.pop('error', "Could not retrieve the data", error.statusText);
        });
    }

    function GetHighestTypeOfInjuryPerDepartmentReport(dateFrom, dateTo) {
      vm.ReportLoading = true;

      dateFrom = dateFrom.toISOString();
      dateTo = dateTo.toISOString();

      ReportService
        .GetHighestTypeOfInjuryPerDepartmentReport(dateFrom, dateTo)
        .then(function(result) {
          vm.HighestTypeOfInjuryPerDepartmentReportResults = result;
          vm.ReportLoading = false;
        }, function(error) {
          vm.ReportLoading = false;
          toaster.pop('error', "Could not retrieve the data", error.statusText);
        });
    }

    function GetReferralsConfirmedWithin24HoursReport(dateFrom, dateTo) {
      vm.ReportLoading = true;

      dateFrom = dateFrom.toISOString();
      dateTo = dateTo.toISOString();

      ReportService
        .GetReferralsConfirmedWithin24HoursReport(dateFrom, dateTo)
        .then(function(result) {
          vm.ReferralsConfirmedWithin24HoursReportResults = result;
          vm.ReportLoading = false;
        }, function(error) {
          vm.ReportLoading = false;
          toaster.pop('error', "Could not retrieve the data", error.statusText);
        });
    }

    function GetHandlingRequestReport(dateFrom, dateTo) {
      vm.ReportLoading = true;

      dateFrom = dateFrom.toISOString();
      dateTo = dateTo.toISOString();

      ReportService
        .GetHandlingRequestReport(dateFrom, dateTo)
        .then(function(result) {
          vm.HandlingRequestReportResults = result;
          vm.ReportLoading = false;
        }, function(error) {
          vm.ReportLoading = false;
          toaster.pop('error', "Could not retrieve the data", error.statusText);
        });
    }

    function GetChronicityReport(dateFrom, dateTo) {
      vm.ReportLoading = true;

      dateFrom = dateFrom.toISOString();
      dateTo = dateTo.toISOString();

      ReportService
        .GetChronicityReport(dateFrom, dateTo)
        .then(function(result) {
          vm.ChronicityReportResults = result;
          vm.ReportLoading = false;
        }, function(error) {
          vm.ReportLoading = false;
          toaster.pop('error', "Could not retrieve the data", error.statusText);
        });
    }

    function GetTypeOfWorkReport(dateFrom, dateTo) {
      vm.ReportLoading = true;

      dateFrom = dateFrom.toISOString();
      dateTo = dateTo.toISOString();

      ReportService
        .GetTypeOfWorkReport(dateFrom, dateTo)
        .then(function(result) {
          vm.TypeOfWorkReportResults = result;
          vm.ReportLoading = false;
        }, function(error) {
          vm.ReportLoading = false;
          toaster.pop('error', "Could not retrieve the data", error.statusText);
        });
    }

    function GetGeneralReport(dateFrom, dateTo) {
      vm.ReportLoading = true;

      dateFrom = dateFrom.toISOString();
      dateTo = dateTo.toISOString();

      ReportService
        .GetGeneralReport(dateFrom, dateTo)
        .then(function(result) {
          window.location.href = result;
          vm.ReportLoading = false;
        }, function(error) {
          vm.ReportLoading = false;
          toaster.pop('error', "Could not retrieve the data", error.statusText);
        });
    }
  }
}());
