  (function() {
    'use strict';

    angular
      .module('app.admin.clients')
      .controller('ClientThemeFormController', ClientThemeFormController);

    ClientThemeFormController.$inject = ['ModalParams', '$modalInstance', 'ClientsService', 'toaster'];

    function ClientThemeFormController(ModalParams, $modalInstance, ClientsService, toaster) {
      var vm = this;

      vm.Save = Save;
      vm.SaveWithLogo = SaveWithLogo;
      vm.SimpleSave = SimpleSave;

      activate();

      function activate() {
        vm.Theme = ModalParams.Theme;
        vm.PortalRedirectTo = ModalParams.PortalRedirectTo;
        vm.PortalRedirectToMessage = ModalParams.PortalRedirectToMessage;
        vm.PortalRedirectToCounter = ModalParams.PortalRedirectToCounter;
        vm.Theme.ColorScheme = JSON.parse(ModalParams.Theme.ColorScheme);
      }

      function Save() {
        vm.loading = true;

        if(vm.File) {
          vm.SaveWithLogo();
        }
        else {
          vm.SimpleSave();
        }
      }

      function SaveWithLogo() {
        if(vm.File) {
          ClientsService
            .UploadFile({
              File: vm.File
            })
            .then(function(_logo) {
              vm.Theme.Logo = _logo;
              vm.SimpleSave();
            }, function(error) {
              toaster.pop('error', "Could not upload the logo", error.statusText);
            });
        }
      }

      function SimpleSave() {
        var _data = {};

        _.mapObject(vm.Theme, function(val, key) {
          _data["Theme." + key] = val;
        });

        _data["Theme.ColorScheme"] = JSON.stringify(_data["Theme.ColorScheme"]);

        _data["Extra.PortalRedirectTo"] = vm.PortalRedirectTo;
        _data["Extra.PortalRedirectToMessage"] = vm.PortalRedirectToMessage;
        _data["Extra.PortalRedirectToCounter"] = vm.PortalRedirectToCounter;

        ClientsService
          .SaveConfiguration(_data)
          .then(function(response) {
            vm.loading = false;

            toaster.pop('success',"Theme details were saved.", "Saved!");
            $modalInstance.close();
          }, function(error) {
            toaster.pop('error', "Could not save the data", error.statusText);
          });
      }
    }
  }());
