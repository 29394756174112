  (function() {
    'use strict';

    angular
      .module('app.admin.clients')
      .controller('ClientPdfFormController', ClientPdfFormController);

    ClientPdfFormController.$inject = ['ModalParams', '$modalInstance', 'ClientsService', 'toaster'];

    function ClientPdfFormController(ModalParams, $modalInstance, ClientsService, toaster) {
      var vm = this;

      vm.Save = Save;
      vm.SaveWithLogo = SaveWithLogo;
      vm.SimpleSave = SimpleSave;

      activate();

      function activate() {
        vm.Pdf = ModalParams.Pdf;
      }

      function Save() {
        vm.loading = true;

        if(vm.File) {
          vm.SaveWithLogo();
        }
        else {
          vm.SimpleSave();
        }
      }

      function SaveWithLogo() {
        if(vm.File) {
          ClientsService
            .UploadFile({
              File: vm.File
            })
            .then(function(_logo) {
              vm.Pdf.CompanyLogo = _logo;
              vm.SimpleSave();
            }, function(error) {
              toaster.pop('error', "Could not upload the logo", error.statusText);
            });
        }
      }

      function SimpleSave() {
        var _data = {};

        _.mapObject(vm.Pdf, function(val, key) {
          _data["Pdf." + key] = val;
        });

        ClientsService
          .SaveConfiguration(_data)
          .then(function(response) {
            vm.loading = false;

            toaster.pop('success',"Pdf details were saved.", "Saved!");
            $modalInstance.close();
          }, function(error) {
            toaster.pop('error', "Could not save the data", error.statusText);
          });
      }
    }
  }());
