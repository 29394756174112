(function () {
  'use strict';

  angular
    .module('app.admin.clients')
    .controller('ReferralsPhoneTriageController', ReferralsPhoneTriageController);

  ReferralsPhoneTriageController.$inject = [
    '$filter', '$scope', '$stateParams','EnumsService', 'AuthService','ClientsService', 'SurveysService', 'WorkStatus',
    'Diagnosis', 'AssessmentTypes', 'TriageAssessmentFindings', 'ActionPlan', 'ProposedManagement',
      'toaster', 'ReferralType', 'InitationNotify', 'InitationPeriod', 'Gender', 'SurveyPdfService', 'ConditionType', 'NatureOfWork', 'TypeOfReferral', 'BodyRegionOfInjury', 'MechanismOfInjury'];

  function ReferralsPhoneTriageController(
    $filter, $scope, $stateParams,EnumsService, AuthService,ClientsService, SurveysService, WorkStatus,
    Diagnosis, AssessmentTypes, TriageAssessmentFindings, ActionPlan, ProposedManagement,
      toaster, ReferralType, InitationNotify, InitationPeriod, Gender, SurveyPdfService, ConditionType, NatureOfWork, TypeOfReferral, BodyRegionOfInjury, MechanismOfInjury) {
    var vm = this;

    // enums
    vm.Gender = Gender;
    vm.InitationNotify = InitationNotify;
    vm.InitationPeriod = InitationPeriod;
    vm.ReferralType =EnumsService.GetClientEnum(ReferralType);
    vm.WorkStatus = EnumsService.GetClientEnum(WorkStatus);
    vm.Diagnosis = Diagnosis;
    vm.TriageAssessmentFindings = TriageAssessmentFindings;
    vm.AssessmentTypes = AssessmentTypes;
    vm.ActionPlan = ActionPlan;
    vm.ProposedManagement = ProposedManagement;
    vm.ConditionType = ConditionType;
    vm.NatureOfWork = NatureOfWork;
    vm.TypeOfReferral = EnumsService.GetClientEnum(TypeOfReferral);
    vm.BodyRegionOfInjury = BodyRegionOfInjury;
      vm.SplitNum = 2;
      vm.MechanismOfInjury = MechanismOfInjury;

    vm.LoadSurvey = LoadSurvey;
    vm.LoadPhoneTriage = LoadPhoneTriage;
    vm.DSEChanged = DSEChanged

    vm.Submit = Submit;
    vm.ExportPDF = ExportPDF;

    activate();

    function activate() {
    vm.ReferralType =EnumsService.GetClientEnum(ReferralType);
    vm.WorkStatus =EnumsService.GetClientEnum(WorkStatus);
      vm.Back = $scope.$parent.vm.Back;
      vm.LoadSurvey();
    }

    function LoadSurvey() {
      vm.loading = true;
      SurveysService
        .GetSurveyByCode("phonetriage", true)
        .then(function (CustomAndReferralSurvey) {
          vm.IsActive = CustomAndReferralSurvey.CustomSurvey.IsActive;
          vm.CanEdit = CustomAndReferralSurvey.CustomSurvey.CanEdit;
          vm.CustomQuestionnaire = vm.IsActive ? JSON.parse(CustomAndReferralSurvey.CustomSurvey.Configuration) : '';
          vm.Components = vm.IsActive ? SurveysService.ManageSurveyComponentsForView(vm.CustomQuestionnaire, vm.SplitNum) : '';
          console.log("vm.Components",vm.Components);
          vm.Questionnaire = JSON.parse(CustomAndReferralSurvey.ReferralSurvey.Configuration);
        }, function (error) {
          toaster.pop('error', "Could not retrieve the data", error.statusText);
        })
        .finally(function () {
          vm.LoadPhoneTriage();
        });
    }

    function LoadPhoneTriage() {
      vm.PhoneTriage = {};
      vm.PhoneTriage.ReferralID = $stateParams.ReferralID;
      vm.PhoneTriage.PhoneTriageDate = new Date();
      vm.PhoneTriage.PhoneTriageAbsenceDate = new Date();
      vm.PhoneTriage.PhoneTriageReturnedToWorkDate = new Date();
      vm.PhoneTriage.PhoneTriageExpectedDischargeDate = null; //new Date();
      vm.PhoneTriage.PhoneTriageExpectedDateOfReturn = new Date();
      vm.DisplaySaveAndSend = true;
      vm.WithoutAppointment = false;

      SurveysService
        .GetPhoneTriageByReferralId($stateParams.ReferralID)
        .then(function (phonetriageWithInfo) {
          phonetriageWithInfo = phonetriageWithInfo.data;
          vm.Referral = SurveysService.SetupReferralInfo(phonetriageWithInfo.Referral);

          if (phonetriageWithInfo.PhoneTriage !== null && !angular.isUndefined(phonetriageWithInfo.PhoneTriage.PhoneTriageFindings) && phonetriageWithInfo.PhoneTriage.PhoneTriageFindings !== null) {
            phonetriageWithInfo.PhoneTriage.PhoneTriageFindings = phonetriageWithInfo.PhoneTriage.PhoneTriageFindings.split(',').filter(function (el) { return el.length !== 0; });
          }
          if (phonetriageWithInfo.PhoneTriage !== null && !angular.isUndefined(phonetriageWithInfo.PhoneTriage.PhoneTriageActionplans) && phonetriageWithInfo.PhoneTriage.PhoneTriageActionplans !== null) {
            phonetriageWithInfo.PhoneTriage.PhoneTriageActionplans = phonetriageWithInfo.PhoneTriage.PhoneTriageActionplans.split(',').filter(function (el) { return el.length !== 0; });
          }
          if (phonetriageWithInfo.PhoneTriage !== null && !angular.isUndefined(phonetriageWithInfo.PhoneTriage.BodyRegionOfInjury) && phonetriageWithInfo.PhoneTriage.BodyRegionOfInjury !== null) {
            phonetriageWithInfo.PhoneTriage.BodyRegionOfInjury = phonetriageWithInfo.PhoneTriage.BodyRegionOfInjury.split(',').filter(function (el) { return el.length !== 0; });
          }

          // ean einai null den theloume na spasei to object pou ftiaksame tin arxh
          if (phonetriageWithInfo.PhoneTriage !== null) {
            vm.PhoneTriage = phonetriageWithInfo.PhoneTriage;
            vm.DisplayAltDiagnosis = vm.PhoneTriage.AltDiagnosis !== null ? true : false;
            vm.DisplaySaveAndSend = vm.PhoneTriage.SurveyStatus == "NOT_SENT";
          }
          else {
            // orizoume thn timh sto min gia na min exei NaN sto init
            var _questions = SurveysService.GetSurveyQuestions(vm.CustomQuestionnaire, ["pain_score", "no_treatment_recovery"]);
            vm.PhoneTriage.PhoneTriagePain = _questions.pain_score.min;
            vm.PhoneTriage.PhoneTriageNoTreatment = _questions.no_treatment_recovery.min;
            vm.DisplayAltDiagnosis = false;
          }

          vm.WithoutAppointment = phonetriageWithInfo.WithoutAppointment;
          vm.DisplaySaveAndSend = vm.DisplaySaveAndSend && !vm.WithoutAppointment;
        }, function (error) {
          toaster.pop('error', "Could not retrieve the data", error.statusText);
        })
        .finally(function () {
          vm.loading = false;
        });
    }

    function Submit(exportToPdf) {
      vm.loading = true;
      if (!angular.isUndefined(vm.PhoneTriage.PhoneTriageFindings) && vm.PhoneTriage.PhoneTriageFindings !== null) {
        vm.PhoneTriage.PhoneTriageFindings = vm.PhoneTriage.PhoneTriageFindings.join(',');
      }
      if (!angular.isUndefined(vm.PhoneTriage.PhoneTriageActionplans) && vm.PhoneTriage.PhoneTriageActionplans !== null) {
        vm.PhoneTriage.PhoneTriageActionplans = vm.PhoneTriage.PhoneTriageActionplans.join(',');
      }
      if (!angular.isUndefined(vm.PhoneTriage.BodyRegionOfInjury) && vm.PhoneTriage.BodyRegionOfInjury !== null) {
        vm.PhoneTriage.BodyRegionOfInjury = vm.PhoneTriage.BodyRegionOfInjury.join(',');
      }
      if (!vm.DisplayAltDiagnosis) {
        vm.PhoneTriage.AltDiagnosis = null;
        vm.PhoneTriage.PhoneTriageAltDiagnosisOther = null;
      }

      vm.PhoneTriage.SurveyStatus = vm.SendEmail ? "SENT" : "NOT_SENT";

      vm.PhoneTriage.InitiatedTime = vm.PhoneTriage.InitiatedTime == 1;
      vm.PhoneTriage.InitiatedMovement = vm.PhoneTriage.InitiatedMovement == 1;

      vm.PhoneTriage.CausedByAccident = vm.PhoneTriage.CausedByAccident == 1;
      vm.PhoneTriage.OccupationalHealthRepeatUser = vm.PhoneTriage.OccupationalHealthRepeatUser == 1;
      vm.PhoneTriage.AccidentReported = vm.PhoneTriage.AccidentReported == 1;
      vm.PhoneTriage.CausedByErgonomicFactors = vm.PhoneTriage.CausedByErgonomicFactors == 1;
      vm.PhoneTriage.IsAggravated = vm.PhoneTriage.IsAggravated == 1;

      SurveysService
        .SubmitPhoneTriage(vm.PhoneTriage)
        .then(function (phonetriage) {
          $scope.$parent.vm.HideFirstMeeting = vm.PhoneTriage.ProposedManagement == "ADVISE_AND_EDUCATION_PHONE_CONSULTING";
          toaster.pop('success', "Phone Triage was saved.", "Saved!");

          if (!angular.isUndefined(exportToPdf)) {
            vm.ExportPDF();
          }

          vm.LoadPhoneTriage();
        }, function (error) {
          vm.loading = false;
          toaster.pop('error', "Could not save the data", error.statusText);
        });
    }

    function ExportPDF() {
      if (angular.isUndefined(vm.PhoneTriage.PhoneTriageFindings) || vm.PhoneTriage.PhoneTriageFindings === null) {
        vm.PhoneTriage.PhoneTriageFindings = "";
      }
      else if (_.isArray(vm.PhoneTriage.PhoneTriageFindings)) {
        vm.PhoneTriage.PhoneTriageFindings = vm.PhoneTriage.PhoneTriageFindings.join(',');
      }
      if (angular.isUndefined(vm.PhoneTriage.PhoneTriageActionplans) || vm.PhoneTriage.PhoneTriageActionplans === null) {
        vm.PhoneTriage.PhoneTriageActionplans = "";
      }
      else if (_.isArray(vm.PhoneTriage.PhoneTriageActionplans)) {
        vm.PhoneTriage.PhoneTriageActionplans = vm.PhoneTriage.PhoneTriageActionplans.join(',');
      }
      if (angular.isUndefined(vm.PhoneTriage.BodyRegionOfInjury) || vm.PhoneTriage.BodyRegionOfInjury === null) {
        vm.PhoneTriage.BodyRegionOfInjury = "";
      }
      else if (_.isArray(vm.PhoneTriage.BodyRegionOfInjury)) {
        vm.PhoneTriage.BodyRegionOfInjury = vm.PhoneTriage.BodyRegionOfInjury.join(',');
      }

      var phoneTriageFindings = vm.PhoneTriage.PhoneTriageFindings.split(',');
      var phoneTriageFindingsArray = [];
      angular.forEach(phoneTriageFindings, function (phoneTriageFinding, index) {
        phoneTriageFindingsArray.push($filter('translate')(phoneTriageFinding));
      });
      vm.PhoneTriage.PhoneTriageFindings = phoneTriageFindingsArray.join(',');

      var phoneTriageActionPlans = vm.PhoneTriage.PhoneTriageActionplans.split(',');
      var phoneTriageActionPlansArray = [];
      angular.forEach(phoneTriageActionPlans, function (phoneTriageActionPlan, index) {
        phoneTriageActionPlansArray.push($filter('translate')(phoneTriageActionPlan));
      });
      vm.PhoneTriage.PhoneTriageActionplans = phoneTriageActionPlansArray.join(',');

      var phoneTriageBodyRegionOfInjury = vm.PhoneTriage.BodyRegionOfInjury.split(',');
      var phoneTriageBodyRegionOfInjuryArray = [];
      angular.forEach(phoneTriageBodyRegionOfInjury, function (bodyRegionOfInjury, index) {
        phoneTriageBodyRegionOfInjuryArray.push($filter('translate')(bodyRegionOfInjury));
      });
      vm.PhoneTriage.BodyRegionOfInjury = phoneTriageBodyRegionOfInjuryArray.join(',');

      SurveyPdfService
        .download(vm.CustomQuestionnaire, vm.PhoneTriage, vm.Questionnaire, vm.Referral);
    }

    function DSEChanged() {
      if (vm.PhoneTriage.PhoneTriageDseasses) {
        vm.PhoneTriage.PhoneTriageAdvanced = "STANDARD";
      }
      else {
        vm.PhoneTriage.PhoneTriageAdvanced = null;
      }
    }

  }
})();
