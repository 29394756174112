/* jshint -W097, -W033 */
(function() {
  'use strict';

  angular
    .module('app.shared')
    .config(LocationConfig);

  LocationConfig.$inject = ['configHelperProvider', '$locationProvider'];

  function LocationConfig(configHelperProvider, $locationProvider) {
    $locationProvider.html5Mode(true);

    configHelperProvider.$get().notifyConfig("Location HTML5", true);
  }

}());
