/* jshint -W097, -W033 */
(function() {
  'use strict';

  angular
    .module('app.admin.clients')
    .factory('ClientsModals', ClientsModals);

  ClientsModals.$inject = ['modalHelper'];

  function ClientsModals(modalHelper) {
    return modalHelper.configureModals(getModals());

    function getModals() {
      return [{
        modal: "Details",
        config: {
          templateUrl: 'src/com.admin/clients/details/view.html',
          controller: "ClientDetailsController",
          size:"md",
        },
      },{
        modal: "ValidateClients",
        config: {
          templateUrl: 'src/com.admin/clients/validate/view.html',
          controller: "ClientsValidateController"
        },
      },{
        modal: "EditSurvey",
        config: {
          templateUrl: 'src/com.admin/clients/surveys/edit.html',
          controller: "ClientSurveysEditController",
          size: 'lg'
        },
      },{
        modal: "Init",
        config: {
          templateUrl: 'src/com.admin/clients/init/view.html',
          controller: "ClientInitController",
          size:"md",
        },
      },{
        modal: "CompanyForm",
        config: {
          templateUrl: 'src/com.admin/clients/configuration/company/company.form.modal.html',
          controller: "ClientCompanyFormController",
        },
      },{
        modal: "CompanyTos",
        config: {
          templateUrl: 'src/com.admin/clients/configuration/tos/tos.form.modal.html',
          controller: "ClientTosFormController",
        },
      },{
        modal: "CompanyDisclaimer",
        config: {
          templateUrl: 'src/com.admin/clients/configuration/disclaimer/disclaimer.form.modal.html',
          controller: "ClientDisclaimerFormController",
        },
      },{
        modal: "ThemeForm",
        config: {
          templateUrl: 'src/com.admin/clients/configuration/theme/theme.form.modal.html',
          controller: "ClientThemeFormController",
        },
      },{
        modal: "PdfForm",
        config: {
          templateUrl: 'src/com.admin/clients/configuration/pdf/pdf.form.modal.html',
          controller: "ClientPdfFormController",
        },
      }, {
        modal: "LocalizationForm",
        config: {
          templateUrl: 'src/com.admin/clients/configuration/localization/localizationform.modal.html',
          controller: "ClientLocalizationFormController",
          size: "md"
        }
      }, {
        modal: "ExtrasForm",
        config: {
          templateUrl: 'src/com.admin/clients/configuration/extras/extras.form.modal.html',
          controller: "ClientExrasFormController",
          size: "md"
        }
      },{
        modal: "EmailByConditionTypeForm",
        config: {
          templateUrl: 'src/com.admin/clients/configuration/emailbyconditiontype/emailbyconditiontype.form.modal.html',
          controller: "EmailByConditionTypeFormController",
          size: "md"
        }
      },{
        modal: "AppointmentTypeCostForm",
        config: {
          templateUrl: 'src/com.admin/clients/configuration/appointmenttypecost/appointmenttypecost.form.modal.html',
          controller: "ClientAppointmentTypeCostController",
          size: 'md'
        },
      },{
        modal: "ImageItem",
        config: {
          templateUrl: 'src/com.admin/clients/imageitems/edit.html',
          controller: "ImageItemEditController",
          size: 'lg'
        },
      },{
        modal: "PortalManager",
        config: {
          templateUrl: 'src/com.admin/clients/portalmanagers/edit.html',
          controller: "PortalManagerEditController",
          size: 'md'
        },
      }, {
        modal: "ImportReferrals",
        config: {
          templateUrl: 'src/com.admin/clients/referrals/import/import.modal.html',
          controller: "ReferralImportController",
          size:"lg",
        },
      }];
    }
  }

}());
