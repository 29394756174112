(function() {
  'use strict';

  angular
    .module('app.admin.clients')
    .controller('ClientValidateController', ClientValidateController);

  ClientValidateController.$inject = ['ClientsService'];

  function ClientValidateController(ClientsService) {
    var vm = this;

    activate();

    function activate() {
      vm.loading = true;
      ClientsService
        .ValidateClients()
        .then(function(results) {
          console.log(results);
          vm.Results = results;
        })
        .finally(function() {
          vm.loading = false;
        });
    }

  }

}());
