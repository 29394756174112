/* jshint -W097, -W064 */
(function() {
  'use strict';

  angular
    .module('app.admin.configuration')
    .factory('OfflineModals', OfflineModals);

  OfflineModals.$inject = ['modalHelper'];

  function OfflineModals(modalHelper) {
    return modalHelper.configureModals(getModals());

    function getModals() {
      return [{
        modal: "OfflineMessage",
        config: {
          templateUrl: 'src/com.admin/configuration/offline/views/offline.message.modal.html',
          controller: "OfflineMessageController",
          size:"md"
        }
      }];
    }
  }

}());
