/* jshint -W097, -W033 */
(function() {
  'use strict';

  angular
    .module('app.shared')
    .factory('api', api)

  api.$inject = ['$resource'];

  function api($resource) {

    var service = {
      defaultConfig: {
        ID: '@ID'
      },
      extraMethods: {
        'update': {
          method: 'PUT'
        }
      },
      add: add,
    }

    return service;

    function add(config) {
      var params,
        url;

      // If the add() function is called with a
      // String, create the default configuration.
      if (angular.isString(config)) {
        var configObj = {
          resource: config,
          url: APP.Service + '/' + config
        };

        config = configObj;
      }

      // If the url follows the expected pattern, we can set cool defaults
      if (!config.unnatural) {
        var orig = angular.copy(service.defaultConfig);
        params = angular.extend(orig, config.params);
        url = config.url + '/:ID';

        // otherwise we have to declare the entire configuration.
      } else {
        params = config.params;
        url = config.url;
      }

      // If we supply a method configuration, use that instead of the default extra.
      var methods = config.methods || service.extraMethods;

      service[config.resource] = $resource(url, params, methods);
    }

  }

}());
