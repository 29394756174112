(function() {
  'use strict';

  angular
  .module('app.admin.clients')
  .controller('ImageItemEditController', ImageItemEditController);

  ImageItemEditController.$inject = ["ModalParams", 'SurveysService', 'ClientsService', '$modalInstance', 'toaster'];

  function ImageItemEditController(ModalParams, SurveysService, ClientsService, $modalInstance, toaster) {

    var vm = this;

    vm.LoadIllnesses = LoadIllnesses;
    vm.LoadImageItem = LoadImageItem;
    vm.Save = Save;
    vm.SaveWithLogo = SaveWithLogo;
    vm.SimpleSave = SimpleSave;
    vm.ChosenIllnesses = [];

    activate();

    function activate() {
      vm.LoadIllnesses();
    }

    function LoadIllnesses() {
      vm.loading = true;
      SurveysService
        .GetIllnesses()
        .then(function(illnesses) {
          vm.Illnesses = illnesses;
        })
        .finally(function() {
          vm.loading = false;
          if(!_.isUndefined(ModalParams.ImageItemID)) {
            vm.LoadImageItem();
          }
        });
    }

    function LoadImageItem() {
      vm.loading = true;
      SurveysService
        .GetImageItemById(ModalParams.ImageItemID)
        .then(function(imageItem) {
          vm.ImageItem = imageItem;
          InitImageIllnesses();
        })
        .finally(function() {
          vm.loading = false;
        });
    }

    function Save() {
      if(vm.File) {
        vm.SaveWithLogo();
      }
      else {
        vm.SimpleSave();
      }
    }

    function SaveWithLogo() {
      vm.ImageItem.Illnesses = ModifyImageIllnesses();
      ClientsService
        .UploadFile({
          File: vm.File
        })
        .then(function(image) {
          vm.ImageItem.ImageItemPhoto = image;
          vm.SimpleSave();
        });
    }

    function SimpleSave() {
      vm.ImageItem.Illnesses = ModifyImageIllnesses();
      SurveysService
        .SaveImageItem(vm.ImageItem)
        .then(function(imageItem){
          toaster.pop('success',"Excercise Image was saved.", "Saved!");
          $modalInstance.close();
        });
    }

    function InitImageIllnesses() {
      _.each(vm.ImageItem.Illnesses, function(existingImageIllness, existingImageIllnessIndex) {
         _.each(vm.Illnesses, function(imageIllness, imageIllnessIndex) {
          if(imageIllness.ID == existingImageIllness.IllnessID) {
            vm.ChosenIllnesses.push(existingImageIllness.IllnessID);
          }
        });
      });
    }

    function ModifyImageIllnesses() {
      return _.map(vm.ChosenIllnesses, function(imageIllnessID) {
        return {
          ImageID: -1, // to ekana etsi giati panta sto admin vazoume nea den kanoume pote update, apla to vazo gia na uparexei kati kai na mhn einai null
          IllnessID: imageIllnessID
        };
      });
    }
  }
}());
