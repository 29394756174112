/* jshint -W097, -W033 */
(function() {
  'use strict';

  angular
    .module('app.admin.users')
    .factory('UsersModals', UsersModals);

  UsersModals.$inject = ['modalHelper'];

  function UsersModals(modalHelper) {
    return modalHelper.configureModals(getModals());

    function getModals() {
      return [{
        modal: "UserForm",
        config: {
          templateUrl: 'src/com.admin/users/views/user.form.modal.html',
          controller: "UserFormController",
        }
      }, {
        modal: "UserChangePassword",
        config: {
          templateUrl: 'src/com.admin/users/views/modal.userchangepassword.html',
          controller: "UserChangePassController"
        },
      }];
    }
  }

}());
