  (function() {
    'use strict';

    angular
      .module('app.admin.users')
      .controller('UserChangePassController', UserChangePassController);

    UserChangePassController.$inject = ['UsersService', 'AuthService', 'ModalParams',  "$modalInstance", 'toaster'];

    function UserChangePassController(UsersService, AuthService, ModalParams,  $modalInstance, toaster) {
      var vm = this;
      vm.UserChangePassword = UserChangePassword;

      activate();

      function activate() {
        vm.User = ModalParams.User;
        // if (ModalParams.UserID) {
        //   LoadUser();
        // }
      }

      // function LoadUser() {
      //   vm.loading = true;
      //   UsersService
      //     .GetUser(ModalParams.UserID)
      //     .then(function(response) {
      //       vm.User = response;
      //       console.log(vm.User);
      //     })
      //     .finally(function() {
      //       vm.loading = false;
      //     });
      // }

      function UserChangePassword() {
        vm.loading = true;
        AuthService
          .ForgotPassword({
            Email: vm.User.Email,
            UserName: vm.User.UserName
          })
          .then(function() {
            vm.loading = false;
            toaster.pop('success', "Reset Password Sent", "An email has been sent to the user!");
            $modalInstance.close();
          }, function(error) {
            vm.loading = false;
            toaster.pop('error', "Reset Password not Sent", error.statusText);
          });

        // vm.loading = true;
        // UsersService
        //   .UserChangePassword(vm.User)
        //   .then(function(response) {
        //     $modalInstance.close(response);
        //   }, function(reason) {
        //     $modalInstance.dismiss(reason);
        //   })
        //   .finally(function() {
        //     vm.loading = false;
        //   });
      }
    }
  }());
