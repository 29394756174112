  (function() {
    'use strict';

    angular
      .module('app.admin.configuration')
      .controller('CompanyFormController', CompanyFormController);

    CompanyFormController.$inject = ['ModalParams', '$modalInstance', 'ConfigurationService', 'toaster'];

    function CompanyFormController(ModalParams, $modalInstance, ConfigurationService, toaster) {
      var vm = this;
      vm.Company = _.clone(APP.Client.Company);
      vm.Save = Save;
      vm.loading = false;

      activate();

      function activate() {}

      function Save() {
        vm.loading = true;

        var _data = {}

        _.mapObject(vm.Company, function(val, key) {
          _data["Company." + key] = val;
        });

        ConfigurationService
          .Save(_data)
          .then(function() {
            vm.loading = false;
            APP.Client.Company = vm.Company;

            toaster.pop('success',"Company details were saved.", "Saved!")
            $modalInstance.close();
          });

      }

    }

  }());
