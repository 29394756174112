(function() {
  'use strict';

  angular
    .module('app.admin.clients')
    .controller('ClientController', ClientController);

  ClientController.$inject = ["$stateParams", 'ClientsService', 'ClientMenu', 'AuthService'];

  function ClientController($stateParams, ClientsService, ClientMenu, AuthService) {
    var vm = this;

    vm.ClientMenu = _.filter(ClientMenu, function(item) {
      var result =true;
      // if (!item.Roles)
      // {
      //   result=true;
      // }
      if(_.contains(item.Roles, AuthService.Roles[0]))
      {
        _.each(item.Constraint, function(constraint) {
          if(constraint == "NOTEXTERNAL") {
            result = !AuthService.IsExternal;
          }
        });
      }
      else {
        result= false;
      }
      return result;
    });

    vm.Client = {
      ID: $stateParams.ID
    };

    vm.LoadClient = LoadClient;

    activate();

    function activate() {
      vm.LoadClient();
    }

    function LoadClient() {
      vm.loading = true;
      ClientsService
        .GetClient(vm.Client.ID)
        .then(function(client) {
          vm.Client = client;
        }, function(error) {
          toaster.pop('error', "Could not retrieve user data", error.statusText);
        })
        .finally(function() {
          ClientsService._ClientID = vm.Client.ID;
          ClientsService
            .GetConfiguration()
            .then(function(configuration) {
              vm.Logo = configuration["Company.Logo"];
              APP.Client.Company.Name = vm.Client.CompanyName;
              APP.Client.Logo = vm.Logo;
              APP.Client.Appointment = {};
              APP.Client.Business = {};
              APP.Client.Audit = {};
              APP.Client.Extra = {};
              APP.Client.Appointment.Duration = configuration["Appointment.Duration"];
              APP.Client.Appointment.Times = configuration["Appointment.Times"];
              APP.Client.Audit.AuditNumber = configuration["Audit.AuditNumber"];
              APP.Client.Audit.CommaSeperatedEmails = configuration["Audit.CommaSeperatedEmails"];
              APP.Client.Business.Duration = configuration["Business.Duration"];
              APP.Client.Business.HoursFrom = configuration["Business.HoursFrom"];
              APP.Client.Business.HoursTo = configuration["Business.HoursTo"];
              APP.Client.Extra.MinimumAppointmentsToDischarge = configuration["Extra.MinimumAppointmentsToDischarge"];
            })
            .finally(function() {
              vm.loading = false;
            });
        });
    }

  }

}());
