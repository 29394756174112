(function() {
  'use strict';

  angular
    .module('app.admin.manager')
    .controller('ManagerManagerCloseCaseController', ManagerCloseCaseController);

  ManagerCloseCaseController.$inject = ['$stateParams', '$modalInstance', 'SurveysService', 'toaster', 'ModalParams', 'ClosedWithoutReachingMinimumAppointmentsReason'];

  function ManagerCloseCaseController($stateParams, $modalInstance, SurveysService, toaster, ModalParams, ClosedWithoutReachingMinimumAppointmentsReason) {
    var vm = this;

    vm.ClosedWithoutReachingMinimumAppointmentsReason = ClosedWithoutReachingMinimumAppointmentsReason;
    vm.Submit = Submit;
    vm.CustomFilter = CustomFilter;

    activate();

    function activate() {
      vm.loading = true;
      SurveysService
        .GetReferralWithAppointmentCount(ModalParams.ReferralID)
        .then(function(referral) {
          referral = referral.data;
          vm.Referral = referral.Referral;
          vm.Referral.IsCaseClosed = true;

          vm.CanDischargeOrCloseCase = referral.CanDischargeOrCloseCase;
          vm.ValidDischargeWithPainScoreForFullRecovery = referral.ValidDischargeWithPainScoreForFullRecovery;
          vm.DisplayMessageForValidDischargeWithPainScoreForFullRecovery = vm.ValidDischargeWithPainScoreForFullRecovery && vm.Referral.ClosedWithoutReachingMinimumAppointmentsReason === 'HAS_MADE_EXCELLENT_PROGRESS_AND_CAN_SELF_MANAGE';
          vm.CanSubmit = vm.CanDischargeOrCloseCase;

          var minimumAppointmentsToDischarge = APP.Client.Extra.MinimumAppointmentsToDischarge !== null ? APP.Client.Extra.MinimumAppointmentsToDischarge : 0;
          vm.Referral.ClosedWithoutReachingMinimumAppointments = referral.AppointmentCount < minimumAppointmentsToDischarge && minimumAppointmentsToDischarge > 0 ? true : false;
          vm.HasAppointmentReason = null; // afto mporei na xreiastei na allaksei giati tora panta tou dino th dynatothta na allaksei afto pou epelekse apo to discharge
        }, function(error) {
          toaster.pop('error', "Could not retrieve the data", error.statusText);
        })
        .finally(function() {
          vm.loading = false;
        });
    }

    // TODO: NA GINEI ENA SERVICE
    function CustomFilter() {
      // afora to pain recovery kai an epileksei to excellent progress
      if(!vm.ValidDischargeWithPainScoreForFullRecovery && vm.Referral.ClosedWithoutReachingMinimumAppointmentsReason === 'HAS_MADE_EXCELLENT_PROGRESS_AND_CAN_SELF_MANAGE') {
        vm.CanSubmit = false;
        vm.DisplayMessageForValidDischargeWithPainScoreForFullRecovery = true;
      }
      else {
        vm.CanSubmit = true;
        vm.DisplayMessageForValidDischargeWithPainScoreForFullRecovery = false;
      }
    }

    function Submit() {
      vm.Referral.CaseClosedComment = vm.CaseClosedComment;
      SurveysService
        .SubmitReferral(vm.Referral)
        .then(function(referral) {
          toaster.pop('success',"Case was closed.", "Case Closed!");
          $modalInstance.close(referral);
        }, function(error) {
          toaster.pop('error', "Could not save the data", error.statusText);
        });
    }
  }
})();
