/* jshint -W097, -W064 */
(function() {
  'use strict';

  angular
    .module('app.landing.surveypdf')
    .run(appRun);

  appRun.$inject = ['routerHelper'];

  function appRun(routerHelper) {
    routerHelper.configureStates(getStates());
  }

  function getStates() {
    return [{
      state: "landing.surveypdf",
      config: {
        url: '/pdf/download?code',
        params: {
          code: null
        },
        controller: "SurveyPdfLandingController",
        controllerAs: "vm",
        templateUrl: 'src/com.landing/surveypdf/surveypdf.html',
      }
    }];
  }

}());
