/* jshint -W097, -W064 */
(function() {
  'use strict';

  angular
    .module('app.admin.configuration', [
      'textAngular',
      'colorpicker.module',
      'ngMap',
    ]);

}());
