(function() {
  'use strict';

  angular
    .module('app.admin.manage')
    .controller('ManageController', ManageController);

  ManageController.$inject = ['ManageMenu'];

  function ManageController(ManageMenu) {
    var vm = this;
    vm.Menu = ManageMenu;
  }

}());
