(function() {
  'use strict';

  angular
    .module('app.admin.manager')
    .controller('ManagerReferralsController', ManagerReferralsController);

  ManagerReferralsController.$inject = ['$state', '$stateParams','SurveysService', 'ReferralsModals', 'AuthService'];

  // ManagerModals
  function ManagerReferralsController($state, $stateParams, SurveysService, ReferralsModals, AuthService) {
    var vm = this;

    vm.LoadReferrals = LoadReferrals;
    vm.SearchReferralsModal = SearchReferralsModal;
    vm.ManageExtraSessionsModal = ManageExtraSessionsModal;
    vm.CloseCase = CloseCase;
    vm.filter = "all";
    vm.PhysioTypes = [];
    vm.setManagerIndicator = setManagerIndicator;

    activate();

    function activate() {
      vm.PhysioTypes.push({
        id: {},
        label: "-- Any --"
      });
      vm.PhysioTypes.push({
        id: false,
        label: "Internal"
      });
      vm.PhysioTypes.push({
        id: true,
        label: "External"
      });

      vm.IsArchived = $stateParams.IsArchived;
      vm.IsCaseClosed = $stateParams.IsCaseClosed;
      vm.Role = AuthService.Roles[0];
      vm.PhoneTriage = false;
      vm.FirstMeeting = false;
      vm.Discharge = false;
      vm.PhysioType = {};
      vm.LoadReferrals();
    }

    function LoadReferrals() {
      vm.loading = true;

      switch(vm.filter) {
        case "pt":
            vm.PhoneTriage = 0;
            vm.FirstMeeting = -1;
            vm.Discharge = -1;
            break;
        case "fm":
            vm.PhoneTriage = -1;
            vm.FirstMeeting = 0;
            vm.Discharge = -1;
            break;
        case "dc":
            vm.PhoneTriage = -1;
            vm.FirstMeeting = -1;
            vm.Discharge = 0;
            break;
        default:
            vm.PhoneTriage = -1;
            vm.FirstMeeting = -1;
            vm.Discharge = -1;
        break;
    }

      SurveysService
        .GetReferrals(vm.PhoneTriage, vm.FirstMeeting, vm.Discharge, vm.IsArchived, vm.IsCaseClosed, vm.PhysioType)
        .then(function(data) {
            vm.ReferralsWithInfo = data;
            vm.ClientID = $stateParams.ID;
          _.each(vm.ReferralsWithInfo, function(ref){ ref.backgroundStyle = ref.ManagerColorIndicator!=null?{'background-color':ref.ManagerColorIndicator}:{} }) ; 
        })
        .finally(function() {
          vm.loading = false;
        }); 
    }

    function SearchReferralsModal() {
      ReferralsModals.SearchReferrals()
        .then(function(referralInfo) {
          if(!_.isUndefined(referralInfo) && !_.isUndefined(referralInfo.referralID)) {
            $state.go("admin.manager_survey.referral", {
              ID: referralInfo.referralID,
              HideFirstMeeting: referralInfo.hideFirstMeeting,
              IsArchived: vm.IsArchived,
              ShowFinalDischarge: referralInfo.showFinalDischarge,
              IsCaseClosed: vm.IsCaseClosed
            });
          }
        });
    }

    function ManageExtraSessionsModal(ExtraSession) {
      ReferralsModals.ExtraSessions({
        ExtraSession: ExtraSession
      })
      .then(function(referralInfo) {
        vm.LoadReferrals();
      });
    }

    function CloseCase(_referralID) {
      ReferralsModals.CloseCase({
        ReferralID: _referralID
      })
        .then(function() {
          vm.LoadReferrals();
        });
    }

    function setManagerIndicator(Referral) {
      SurveysService.SetReferralManagerIndicator(Referral.ReferralID,Referral.ManagerColorIndicator);
      Referral.backgroundStyle = Referral.ManagerColorIndicator!=null?{'background-color':Referral.ManagerColorIndicator}:{} 
    }
  }
})();
