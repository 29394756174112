/* jshint -W097, -W033 */
(function() {
  'use strict';

  angular
    .module('app')
    .run(appRun);

  appRun.$inject = ['$rootScope', '$state', 'AuthService', 'ClientService'];

  function appRun($rootScope, $state, AuthService, ClientService) {

    ClientService.LoadSettings();
    AuthService.Init();

    $rootScope.APP = APP;

    $rootScope.hasChecked = checklistmodel;

    $rootScope.$on('$stateChangeStart', function(evt, to, params) {
      if (to.redirectTo) {
        evt.preventDefault();
        $state.go(to.redirectTo, params);
      }
    });


    if(!APP.Client.System.Debug)
    {
        var console = {};
        console.log = function(){};
        window.console = console;
    }

  }

  function checklistmodel(questionRequired, checklistmodel) {
    var result = true;
    if(!_.isUndefined(checklistmodel))
    {
      if (questionRequired && checklistmodel.length === 0) {
          result = false;
      }
    }
    return result;
  }

}());
