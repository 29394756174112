(function() {
  'use strict';

  angular
    .module('app.admin.fixedcontent')
    .controller('FixedContentController', FixedContentController);

  FixedContentController.$inject = ['$http', '$stateParams', 'FixedContentService','$sce'];

  function FixedContentController($http, $stateParams, FixedContentService,$sce) {
    var vm = this;

    vm.Title = $stateParams.Content.Title;
    vm.Content = null;

    activate();

    function activate() {
      vm.loading = true;

      FixedContentService
        .Load($stateParams.Content)
        .then(function(response) {
          
          vm.Content =  $sce.trustAsHtml(response.data);
        });

    }

  }

}());
