(function() {
  'use strict';

  angular
    .module('app.admin.manager')
    .controller('ManagerSurveyController', ManagerSurveyController);

  ManagerSurveyController.$inject = ['$stateParams', 'AuthService', 'SurveysService'];

  function ManagerSurveyController($stateParams, AuthService, SurveysService) {
    var vm = this;

    vm.LoadSurveys = LoadSurveys;
    vm.LoadReferral = LoadReferral;

    activate();

    function activate() {
      vm.Back = $stateParams.IsArchived ? "admin.manager_archived" : "admin.manager_referrals";
      vm.HideFirstMeeting = $stateParams.HideFirstMeeting;
      vm.ShowFinalDischarge = $stateParams.ShowFinalDischarge;
      vm.LoadSurveys();
    }

    function LoadSurveys() {
      vm.loading = true;
      SurveysService
        .GetSurveys(true)
        .then(function(surveys) {
          _.each(surveys, function(survey, key) {
            survey.Route = "admin.manager_survey." + survey.code;
          });

          vm.Surveys = surveys;
        }, function(error) {
          toaster.pop('error', "Could not retrieve the survey", error.statusText);
        })
        .finally(function() {
          vm.LoadReferral();
        });
    }

    function LoadReferral() {
      SurveysService
        .GetReferral($stateParams.ID)
        .then(function(referral) {
          vm.Referral = referral;
        }, function(error) {
          toaster.pop('error', "Could not retrieve the data", error.statusText);
        }
)
        .finally(function() {
          vm.loading = false;
        });
    }

  }
})();
