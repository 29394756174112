  (function() {
    'use strict';

    angular
      .module('app.admin.clients')
      .controller('ClientAppointmentTypeCostController', ClientAppointmentTypeCostController);

    ClientAppointmentTypeCostController.$inject = ['ModalParams', '$modalInstance','EnumsService', '$stateParams' , 'SurveysService', 'toaster', 'AppointmentType'];

    function ClientAppointmentTypeCostController(ModalParams, $modalInstance,EnumsService, $stateParams, SurveysService, toaster, AppointmentType) {
      var vm = this;

      vm.Save = Save;
      vm.AppointmentTypeCosts = {};

      activate();

      function activate() {
        SurveysService._ClientID = $stateParams.ID;

        vm.AppointmentTypes = EnumsService.GetClientEnum(AppointmentType); 

        vm.loading = true;
        SurveysService
          .GetAppointmentTypeCosts()
          .then(function(result) {
            result = result.data;
            _.each(result, function(appointmentTypeCost) {
              vm.AppointmentTypeCosts[appointmentTypeCost.AppointmentType] = appointmentTypeCost;
            });

            vm.loading = false;π
          }, function(error) {
            toaster.pop('error', "Could not retrieve the data", error.statusText);
          });
      }

      function Save() {
        vm.loading = true;

        var appointmentTypeCosts = [];
        _.each(vm.AppointmentTypeCosts, function(appointmentTypeCost,Key) {
          if(_.isUndefined(appointmentTypeCost.AppointmentType))
          {
            appointmentTypeCost.AppointmentType = Key;
          }
          appointmentTypeCosts.push(appointmentTypeCost);
        });

        //console.log(appointmentTypeCosts);
        SurveysService
          .SaveAppointmentTypeCosts(appointmentTypeCosts)
          .then(function(result) {
            vm.loading = false;

            toaster.pop('success',"Appointment type costs saved.", "Saved!");
            $modalInstance.close();
          }, function(error) {
            toaster.pop('error', "Could not save the data", error.statusText);
          });
      }
    }
  }());
