/* jshint -W097, -W033 */
(function() {
  'use strict';

  window.APP = null;

  window.bootstrap = {
    IsBootstraped: false,
    loader: document.getElementById("bootstrap-loading"),
    $http: angular.injector(["ng"]).get("$http"),
    LoadApp: LoadApp,
    LoadClient: LoadClient,
    Msg: Msg,
    LoadClientCss: LoadClientCss,
    AuthHeader: AuthHeader,
    LoadSettings: LoadSettings,
    LoadDomain: LoadDomain,
    ParseSettings: function() {

      _parseBool(APP.Client.System.Debug);
      _parseBool(APP.Client.System.Offline);

      if (APP.Domain !== null) {
        _parseUrl(APP.Client.Theme.Logo);
        _parseJson(APP.Client.Theme.ColorScheme);
      } else {
        _parseUrl(APP.Client.Company.Logo);
      }

    },
  };

  bootstrap.LoadApp();

  function LoadApp() {

    return bootstrap.$http({
        method: 'GET',
        url: '/config.json',
      })
      .then(function(response) {
          APP = response.data;
          bootstrap.LoadDomain();
        },
        function() {
          bootstrap.loader.innerHTML = bootstrap.Msg("CONFIGNOTFOUND");
        });

  }

  function LoadDomain() {

    APP.Domain = APP.OverideDomain || window.location.hostname.split(".").shift();
    console.log("%c DOMAIN ", 'background: #4CAF50; color: #FFF', APP.Domain);

    if (APP.Domain == "admin" || APP.Domain == "preprod-admin" || APP.Domain == "uat-admin") {
      APP.Domain = null;
    }

    bootstrap
      .LoadClient()
      .then(function() {
        if (!bootstrap.IsBootstraped) {
          _angular();
        }
      })

  }

  function LoadClient() {

    bootstrap.loader.innerHTML = bootstrap.Msg("LOADING");

    var _headers = {};
    _headers.Authorization = bootstrap.AuthHeader();
    if (APP.Domain) _headers['X-Domain'] = APP.Domain;

    return bootstrap.$http({
        method: 'GET',
        url: APP.Service + '/bootstrap',
        headers: _headers,
      })
      .then(function(response) {

        if (!response.data) {
          bootstrap.loader.innerHTML = bootstrap.Msg("EMPTYCLIENT");
          return;
        }

        bootstrap.LoadSettings(response.data);
        bootstrap.ParseSettings();

        // if (APP.CustomCSS && APP.Domain) {
        //   bootstrap.LoadClientCss();
        // }

        bootstrap.loader.parentNode.removeChild(bootstrap.loader);

        return response;

      }, function(errorResponse) {

        if (!errorResponse.status) {
          bootstrap.loader.innerHTML = bootstrap.Msg("NORESPONSE");
        } else {
          bootstrap.loader.innerHTML = bootstrap.Msg("GERROR", errorResponse.statusText);
        }

      });

  }

  function LoadSettings(settings) {

    APP.Client = {};

    _.mapObject(settings, function(val, key) {
      var itm = key.split(".");
      if (!APP.Client[itm[0]]) {
        APP.Client[itm[0]] = {};
      }
      if (itm[1]) {
        APP.Client[itm[0]][itm[1]] = val;
      } else {
        APP.Client[itm[0]] = val;
      }
    });

  }

  function Msg(code, custom) {

    var codes = {
      LOADING: {
        title: APP.Name,
        description: "Loading, please wait...",
        loader: true,
      },
      GERROR: {
        title: "GENERAL ERROR!",
        description: "please try again later or contact us",
        devmessage: custom,
        refresh: true,
      },
      OFFLINE: {
        title: "CURRENTLY OFFLINE",
        description: custom,
      },
      NORESPONSE: {
        title: "SERVICE UNAVAILABLE",
        description: "please try again later or contact us",
        devmessage: "SERVICE DOWN",
        refresh: true,
      },
      EMPTYCLIENT: {
        title: "SERVICE UNAVAILABLE",
        description: "client not initialized",
        devmessage: "EMPTY CONFIG",
        refresh: true,
      },
      CONFIGNOTFOUND: {
        title: "CONFIG NOT FOUND",
        description: "please try again later or contact us",
        devmessage: "EMPTY LOCAL CONFIG",
        refresh: true,
      }
    };

    var _template = "";

    if (codes[code].loader) {
      _template += "<span class='loader'></span>";
    }

    _template += "<h1>" + codes[code].title + "</h1>" + "<h2>" + codes[code].description + "</h2>";

    if (codes[code].refresh) {
      _template += "<button onclick='location.reload();'>Refresh</button>";
    }

    if (codes[code].devmessage) {
      _template += "<pre>" + codes[code].devmessage + "</pre>";
    }

    return _template;
  }

  function LoadClientCss() {
    var link = document.createElement("link");
    link.href = "assets/css/clients/" + APP.Domain + ".min.css";
    link.type = "text/css";
    link.rel = "stylesheet";
    link.media = "screen,print";
    document.getElementsByTagName("head")[0].appendChild(link);
  }

  function AuthHeader() {
    var authHeader = null;
    var authData = JSON.parse(localStorage.getItem(APP.Alias + '.AuthorizationData'));
    if (authData) {
      authHeader = 'Bearer ' + authData.Token;
    }
    return authHeader;
  }

  function _angular() {
    angular.element(document).ready(function() {
      angular.bootstrap(document, ["app"]);
      bootstrap.IsBootstraped = true;
    });
  }

  function _parseBool(variable) {
    variable = (variable === "TRUE");
  }

  function _parseUrl(variable) {
    variable = (variable === "FALSE") ? false : variable;
  }

  function _parseJson(variable) {
    try {
      variable = JSON.parse(variable);
    }
    catch(ex) {

    }
  }


}());
