(function() {
  'use strict';

  angular
    .module('app.admin.clients')
    .controller('AuditsController', AuditsController);

  AuditsController.$inject = ['$state', '$stateParams','SurveysService', 'AuditsService', 'ReferralsModals'];

  function AuditsController($state, $stateParams, SurveysService, AuditsService, ReferralsModals) {
    var vm = this;

    vm.LoadAudits = LoadAudits;
    vm.NewAuditModal = NewAuditModal;
    vm.AuditSettingsModal = AuditSettingsModal;

    activate();

    function activate() {
      SurveysService._ClientID = $stateParams.ID;
      vm.LoadAudits();
    }

    function LoadAudits() {
      vm.loading = true;
      AuditsService
        .getAudits()
        .then(function(Audits) {
          vm.Audits = Audits;
        })
        .finally(function() {
          vm.loading = false;
        });
    }

    function AuditSettingsModal() {
      ReferralsModals.AuditSettings({
            AuditNumber: APP.Client.Audit.AuditNumber,
            CommaSeperatedEmails: APP.Client.Audit.CommaSeperatedEmails
        })
        .then(function() {
          activate();
        });
    }

    function NewAuditModal() {
      ReferralsModals.NewAudit()
        .then(function() {
          activate();
        });
    }
  }
})();
