(function() {
  'use strict';

  angular
    .module('app.admin.clients')
    .controller('ClientDashboardController', ClientDashboardController);

  ClientDashboardController.$inject = ['$stateParams', '$state', 'DashboardService', 'AuthService', 'SurveysService'];

  function ClientDashboardController($stateParams, $state, DashboardService, AuthService, SurveysService) {

    var vm = this;

    activate();

    function activate() {
      // console.log(AuthService);
      if(AuthService.IsExternal) {
        $state.go("admin.clients.client.referrals");
      }

      SurveysService._ClientID = $stateParams.ID;

      GetChart();
      GetRecentReferrals();
      GetLateReferrals();
      GetStatistics();
      GetLatestAppointments();

      vm.Display = AuthService.Roles[0] === "MASTER" || AuthService.Roles[0] === "MODERATOR";
      vm.PhysioDisplay = AuthService.Roles[0] === "PHYSIO";

      GetDischargesPerMonth();

      if(vm.Display) {
        GetNewUsersPerMonth();
        GetNumberOfDaysSavedPerMonth();
      }

      if(vm.PhysioDisplay) {
        GetAppointmentsPerMonth();
        GetEfficiencyPerMonth();
      }
    }

    function GetChart() {
      vm.ChartLoading = true;
      DashboardService
        .GetChart()
        .then(function(result) {
          SetChart(result);
          vm.ChartLoading = false;
        });
    }

    function SetChart(serviceResults) {
      vm.Chart = {};
      vm.Chart.Data = [];
      vm.Chart.Labels = [];
      vm.Chart.Series = ['Archived Referrals', 'New Referrals', 'Total Referrals', 'Total Appointments'];
      vm.Chart.Options = {legend:{display:false}};
      //Chart.defaults.global.legend.display = false;

      var closedCases = [];
      var archivedReferrals = [];
      var activeReferrals = [];
      var totalReferrals = [];
      var totalAppointments = [];

      angular.forEach(serviceResults, function(result, label) {
        console.log(label,result.Data);
        vm.Chart.Labels.push(label);

        //closedCases.push(result.Data.CaseClosed);
        archivedReferrals.push(result.Data.ArchivedReferrals);
        activeReferrals.push(result.Data.ActiveReferrals);
        totalReferrals.push(result.Data.ArchivedReferrals +result.Data.CaseClosed + result.Data.ActiveReferrals);
        totalAppointments.push(result.Data.Appointments);
      });

      vm.Chart.Data.push(archivedReferrals, activeReferrals, totalReferrals, totalAppointments);
    }

    function GetRecentReferrals() {
      vm.RecentReferralsLoading = true;
      DashboardService
        .GetRecentReferrals()
        .then(function(result) {
          vm.RecentReferrals = result;
          vm.RecentReferralsLoading = false;
        });
    }

    function GetLateReferrals() {
      vm.LateReferralsLoading = true;
      DashboardService
        .GetLateReferrals()
        .then(function(result) {
          vm.LateReferrals = result;
          vm.LateReferralsLoading = false;
        });
    }

    function GetStatistics() {
      vm.StatisticsLoading = true;
      DashboardService
        .GetStatistics()
        .then(function(result) {
          vm.Statistics = result;
          vm.StatisticsLoading = false;
        });
    }

    function GetLatestAppointments() {
      vm.LatestAppointmentsLoading = true;
      DashboardService
        .GetLatestAppointments()
        .then(function(result) {
          vm.LatestAppointments = result;
          vm.LatestAppointmentsLoading = false;
        });
    }

    function GetNewUsersPerMonth() {
      vm.NewUsersPerMonthCount = 0;
      vm.NewUsersPerMonthCountLoading = true;
      DashboardService
        .GetNewUsersPerMonth()
        .then(function(result) {
          vm.NewUsersPerMonthCount = result;
          vm.NewUsersPerMonthCountLoading = false;
        });
    }

    function GetDischargesPerMonth() {
      vm.DischargesPerMonthCount = 0;
      vm.DischargesPerMonthCountLoading = true;
      DashboardService
        .GetDischargesPerMonth()
        .then(function(result) {
          vm.DischargesPerMonthCount = result;
          vm.DischargesPerMonthCountLoading = false;
        });
    }

    function GetNumberOfDaysSavedPerMonth() {
      vm.NumberOfDaysSaved = 0;
      vm.NumberOfDaysSavedLoading = true;
      DashboardService
        .GetNumberOfDaysSavedPerMonth()
        .then(function(result) {
          vm.NumberOfDaysSaved = result;
          vm.NumberOfDaysSavedLoading = false;
        });
    }

    function GetAppointmentsPerMonth() {
      vm.AppointmentsPerMonth = 0;
      vm.AppointmentsPerMonthLoading = true;
      DashboardService
        .GetAppointmentsPerMonth()
        .then(function(result) {
          vm.AppointmentsPerMonth = result;
          vm.AppointmentsPerMonthLoading = false;
        });
    }

    function GetEfficiencyPerMonth() {
      vm.EfficiencyPerMonth = 0;
      vm.EfficiencyPerMonthLoading = true;
      DashboardService
        .GetEfficiencyPerMonth()
        .then(function(result) {
          vm.EfficiencyPerMonth = result;
          vm.EfficiencyPerMonthLoading = false;
        });
    }

  }
}());
