(function() {
  'use strict';

  angular.module('app.shared')

  .constant('ApiEndpoints', [
    'filemanager',
    'users',
    'surveys',
    'clients',
    'referrals',
    'configuration',
    'phonetriages',
    'firstmeetings',
    'discharges',
    'managernotes',
    'appointments',
    'illnesses',
    'imageitems',
    'exercises',
    'surveypdfs',
    'audits',
    'notificationparser',
    'reports',
    'appointmentstypecosts',
    'portalmanagers',
    'ocupHealthNurses',
  ]);

}());
