(function() {
  'use strict';

  angular
    .module('app.admin.manager')
    .controller('ManagerReferralController', ManagerReferralController);

  ManagerReferralController.$inject = [
    '$scope', '$stateParams','EnumsService', 'SurveysService', 'AuthService', 'ReferralType', 'Gender',
    'WorkStatus', 'toaster', 'SurveyPdfService', 'ConditionType', 'NatureOfWork', 'TypeOfReferral', 'AnsaJobTitle', 'AnsaWorkLocation'];

  function ManagerReferralController(
    $scope, $stateParams,EnumsService, SurveysService, AuthService, ReferralType, Gender,
    WorkStatus, toaster, SurveyPdfService, ConditionType, NatureOfWork, TypeOfReferral, AnsaJobTitle, AnsaWorkLocation) {
    var vm = this;

    // enums
    vm.Gender = Gender;
    vm.ReferralType =EnumsService.GetClientEnum(ReferralType,APP.Domain);
    vm.WorkStatus = EnumsService.GetClientEnum(WorkStatus,APP.Domain);
    vm.ConditionType = ConditionType;
    vm.NatureOfWork = NatureOfWork;
    vm.TypeOfReferral = EnumsService.GetClientEnum(TypeOfReferral,APP.Domain);
    vm.AnsaJobTitle = EnumsService.GetClientEnum(AnsaJobTitle,APP.Domain);
    vm.AnsaWorkLocation = EnumsService.GetClientEnum(AnsaWorkLocation,APP.Domain);

    // custom variables
    vm.ClientManagers = [];

    vm.LoadSurvey = LoadSurvey;
    vm.LoadReferral = LoadReferral;

    vm.Submit = Submit;
    vm.ExportPDF = ExportPDF;

    activate();

    function activate() {
      vm.Back = $scope.$parent.vm.Back;
      vm.CanEditConfirm = AuthService.Roles[0] === 'RECEPTIONIST' ? true : false;
      vm.CanSubmit = AuthService.Roles[0] === 'MANAGER' ? true : false;
      vm.LoadSurvey();
    }

    function LoadSurvey() {
      vm.loading = true;
      SurveysService
        .GetSurveyByCode("referral", true)
        .then(function(CustomAndReferralSurvey) {
          vm.IsActive = CustomAndReferralSurvey.CustomSurvey.IsActive;
          vm.CanEdit = CustomAndReferralSurvey.CustomSurvey.CanEdit;
          vm.Questionnaire = vm.IsActive ? JSON.parse(CustomAndReferralSurvey.CustomSurvey.Configuration) : '';
        }, function(error) {
          toaster.pop('error', "Could not retrieve the survey", error.statusText);
        })
        .finally(function() {
          vm.LoadReferral();
        });
    }

    function LoadReferral() {
      vm.Referral = {};
      vm.Referral.ReferralAbsenceDate = new Date();
      vm.Referral.ReferralReturnedToWorkDate = new Date();
      vm.Referral.ReferralExpectedDateOfReturn = new Date();

      SurveysService
        .GetReferral($stateParams.ID)
        .then(function(referral) {
          vm.Referral = SurveysService.SetupReferralInfo(referral);
          vm.HasAppointmentReason = vm.Referral.ClosedWithoutReachingMinimumAppointmentsReason;
          // TODO: ean yparksei thema se afto to view tha prepei na ta fortono sto call. kanonika de tha eprepe na yparxei thema kathos eite apo to close calse,
          //       eite apo to discharge tha prepei na exei lhksei to opoio thema yphrxe. edo apla deixnoume thn apofash
          vm.CanDischargeOrCloseCase = true;
          vm.DisplayMessageForValidDischargeWithPainScoreForFullRecovery = false;
        }, function(error) {
          toaster.pop('error', "Could not retrieve the data", error.statusText);
        })
        .finally(function() {
          vm.loading = false;
        });
    }

    function Submit(exportToPdf) {
      SurveysService
        .SubmitReferral(vm.Referral)
        .then(function(referral) {
          toaster.pop('success',"Referral was saved.", "Saved!");

          if(!angular.isUndefined(exportToPdf)) {
            vm.ExportPDF();
            vm.loading = false;
          }
          else {
            vm.LoadReferral();
          }
        }, function(error) {
          vm.loading = false;
          toaster.pop('error', "Could not save the data", error.statusText);
        });
    }

    function ExportPDF() {
      SurveyPdfService
        .download(vm.Questionnaire, vm.Referral);
    }
  }
})();
