/* jshint -W097, -W033 */
(function() {
  'use strict';

  angular
    .module('app.admin.manager', [
      'chart.js'
    ])

}());
