/* jshint -W097, -W033 */
(function() {
  'use strict';

  angular
    .module('app.admin.fixedcontent')
    .constant('ContentConstant', [{
      Alias:"dataie",
      Title: "Data Import / Export",
      Url: "/dataie",
      State: "admin.manage.dataie",
      View: "uc.html"
    }, {
      Alias:"subscription",
      Title: "Subscription",
      Url: "/subscription",
      State: "admin.manage.subscription",
      View: "uc.html"
    }, {
      Alias:"help",
      Title: "Help / Support",
      Url: "/help",
      State: "admin.manage.help",
      View: "help.html"
    }, {
      Alias:"terms",
      Title: "Terms of Use",
      Url: "/terms",
      State: "admin.manage.terms",
      View: "terms.html"
    }])

}());
