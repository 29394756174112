/* jshint -W097, -W033 */
(function() {
  'use strict';

  angular
    .module('app.shared')
    .filter('htmlTrusted', htmlTrusted)

  htmlTrusted.$inject = ['$sce']

  function htmlTrusted($sce) {
    return function(text) {
      return $sce.trustAsHtml(text);
    }
  }

}());
