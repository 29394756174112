(function() {
  'use strict';

  angular.module('app.shared')

  .constant('ErrorPages', {
    "GENERIC": {
      "Icon": "exclamation-circle",
      "Message": "Generic Error"
    },
    "ERR_CONNECTION_REFUSED": {
      "Icon": "unlink",
      "Message": "Could not connect",
      "Description": "Server is anavailable. Please try again later."
    },
  })

}());
