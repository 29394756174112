  (function() {
    'use strict';

    angular
      .module('app.admin.clients')
      .controller('ClientExrasFormController', ClientExrasFormController);

    ClientExrasFormController.$inject = ['ModalParams', '$modalInstance', 'ClientsService', 'toaster', '$filter'];

    function ClientExrasFormController(ModalParams, $modalInstance, ClientsService, toaster, $filter) {
      var vm = this;

      vm.Save = Save;
      vm.loading = false;
      // vm.stripFormat = stripFormat;

      activate();

      function activate() {
        vm.MinimumAppointmentsToDischarge = ModalParams.MinimumAppointmentsToDischarge;
        // vm.PortalRedirectTo = ModalParams.PortalRedirectTo;
        // vm.PortalRedirectToMessage = ModalParams.PortalRedirectToMessage;
        // vm.PortalRedirectToCounter = ModalParams.PortalRedirectToCounter;
      }

      function Save() {
        vm.loading = true;

        ClientsService
          .SaveConfiguration({
            "Extra.MinimumAppointmentsToDischarge": vm.MinimumAppointmentsToDischarge,
            // "Extra.PortalRedirectTo": vm.PortalRedirectTo,
            // "Extra.PortalRedirectToMessage": vm.PortalRedirectToMessage,
            // "Extra.PortalRedirectToCounter": vm.PortalRedirectToCounter
          })
          .then(function() {
            vm.loading = false;

            toaster.pop('success',"Extra Configuration was saved.", "Saved!");
            $modalInstance.close();
          }, function(error) {
            toaster.pop('error', "Could not save the data", error.statusText);
          });
      }

      // function stripFormat(html) {
      //   return $filter('htmlToPlaintext')(html);
      // }
    }
  }());
