(function() {
  'use strict';

  angular.module('app.shared')

  .constant('LocaleMap', {
    "greek": "el-gr",
    "english": "en-gb",
  });

}());
