/* jshint -W097, -W033 */
(function() {
  'use strict';

  angular
    .module('app.shared')
    .config(LocalStoreageConfig);

  LocalStoreageConfig.$inject = ['configHelperProvider', 'localStorageServiceProvider'];

  function LocalStoreageConfig(configHelperProvider, localStorageServiceProvider) {

    var _defaultType = "localStorage";

    localStorageServiceProvider
      .setPrefix(APP.Alias)
      .setStorageType(_defaultType); // or localStorage for long term

    configHelperProvider.$get().notifyConfig("LocalStorage", "Type: " + _defaultType);

  }

}());
