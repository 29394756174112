/* jshint -W097, -W033 */
(function() {
  'use strict';

  angular
    .module('app.admin.users')
    .run(appRun);

  appRun.$inject = ['routerHelper'];

  function appRun(routerHelper) {
    routerHelper.configureStates(getStates());
  }

  function getStates() {
    return [{
      state: 'admin.manage.users',
      config: {
        url: '/users',
        controller: "UsersController",
        templateUrl: 'src/com.admin/users/views/users.html'
      }
    }];
  }
}());
