(function() {
  'use strict';

  angular
    .module('app.admin.clients')
    .controller('ManagerExercisesController', ManagerExercisesController);

  ManagerExercisesController.$inject = ['$scope', '$stateParams','EnumsService', 'AuthService', 'SurveysService', 'toaster', 'ReferralType', 'Gender', 'WorkStatus', 'ReferralsModals'];

  function ManagerExercisesController($scope, $stateParams,EnumsService, AuthService, SurveysService, toaster, ReferralType, Gender, WorkStatus, ReferralsModals) {
    var vm = this;

    // enums
    vm.Gender = Gender;
    vm.ReferralType =EnumsService.GetClientEnum(ReferralType,APP.Domain);
    vm.WorkStatus = EnumsService.GetClientEnum(WorkStatus,APP.Domain);

    vm.LoadSurvey = LoadSurvey;
    vm.LoadExercises = LoadExercises;

    vm.ExerciseModal = ExerciseModal;
    vm.DeleteExercise = DeleteExercise;

    activate();

    function activate() {
      vm.Back = $scope.$parent.vm.Back;
      vm.Role = AuthService.Roles[0];

      vm.LoadSurvey();
    }

    function LoadSurvey() {
      vm.loading = true;
      SurveysService
        .GetSurveyByCode("exercises", true)
        .then(function(CustomAndReferralSurvey) {
          vm.IsActive = CustomAndReferralSurvey.CustomSurvey.IsActive;
          vm.CanEdit = CustomAndReferralSurvey.CustomSurvey.CanEdit;
          vm.CustomQuestionnaire = vm.IsActive ? JSON.parse(CustomAndReferralSurvey.CustomSurvey.Configuration) : '';
          vm.Questionnaire = JSON.parse(CustomAndReferralSurvey.ReferralSurvey.Configuration);
        }, function(error) {
          toaster.pop('error', "Could not retrieve the survey", error.statusText);
        })
        .finally(function() {
          vm.LoadExercises();
        });
    }

    function LoadExercises() {
      vm.ExercisesList = [];

      SurveysService
        .GetExerciseByReferralID($stateParams.ID, false)
        .then(function(exerciseWithInfo) {
          exerciseWithInfo = exerciseWithInfo.data;
          vm.Referral = SurveysService.SetupReferralInfo(exerciseWithInfo.Referral);

          // ean einai null den theloume na spasei h lista pou ftiaksame tin arxh
          if(exerciseWithInfo.Exercises !== null) {
            vm.ExercisesList = exerciseWithInfo.Exercises;
          }
        }, function(error) {
          toaster.pop('error', "Could not retrieve the data", error.statusText);
        })
        .finally(function() {
          vm.loading = false;
        });
    }

    function ExerciseModal(Exercise) {
      ReferralsModals.Exercise({
        Exercise: Exercise,
        CustomQuestionnaire: vm.CustomQuestionnaire,
        ReferralID: $stateParams.ID
      })
      .then(function() {
        vm.loading = true;
        vm.LoadExercises();
      });
    }

    function DeleteExercise(ExerciseID) {
      vm.loading = true;
      SurveysService
        .DeleteExercise(ExerciseID)
        .then(function(response) {
          vm.loading = false;
          vm.LoadExercises();
        }, function(error) {
          vm.loading = false;
          toaster.pop('error', "Could not delete the data", error.statusText);
        });
    }
  }
})();
