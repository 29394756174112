/* jshint -W097, -W033 */
(function() {
  'use strict';

  angular
    .module('app.admin.clients')
    .factory('ClientsService', ClientsService);

  ClientsService.$inject = ['$data', '$http', '$q', '$resource', 'Upload'];

  function ClientsService($data, $http, $q, $resource, Upload) {
    var service = {
      _ClientID: null,
      _ClientDomain:null,
      
      GetClients: GetClients,
      GetClient: GetClient,
      SaveClient: SaveClient,

      ValidateClients: ValidateClients,

      GetConfiguration: GetConfiguration,
      SaveConfiguration: SaveConfiguration,
      UploadFile: UploadFile,

    };

    return service;

    // Clients
    function GetClients() {
      return $data
        .list("clients");
    }

    function GetClient(ClientID) {
      return $data
        .get("clients", {
          ID: ClientID
        })
        .then(function(results) {
          return results;
        })
    }

    function SaveClient(Client) {
      return $data
        .save("clients", Client)
        .then(function(results) {
          return results;
        })
    }

    // Validation
    function ValidateClients() {
      return $http
        .get(APP.Service + "/clients/validate")
        .then(function(response) {
          return response.data;
        });
    }

    function GetConfiguration() {
      return $data
        .get("configuration", {
          CID: service._ClientID,
        })
        .then(function(result) {
          return result;
        });
    }

    function SaveConfiguration(Configuration) {
      return $http
        .post(APP.Service + '/configuration?CID='+service._ClientID, Configuration)
        .then(function(results) {
            return results.data;
        });
    }

    function UploadFile(data) {
      var deferred = $q.defer();

      Upload
        .upload({
          url: APP.Service + '/configuration/upload',
          file: data.File,
        })
        .progress(function(evt) {
          deferred.notify(parseInt(100.0 * evt.loaded / evt.total));
        })
        .then(function(response) {
          deferred.resolve(response.data);
        });

      return deferred.promise;
    }
  }

}());
