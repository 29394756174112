/* jshint -W097, -W033 */
(function() {
  'use strict';

  angular
    .module('app.admin.clients')
    .factory('ReferralsModals', ReferralsModals);

  ReferralsModals.$inject = ['modalHelper'];

  function ReferralsModals(modalHelper) {
    return modalHelper.configureModals(getModals());

    function getModals() {
      return [{
        modal: "SearchReferrals",
        config: {
          templateUrl: 'src/com.admin/clients/referrals/dashboard/search.modal.html',
          controller: "ReferralSearchController",
          size:"lg",
        },
      }, {
        modal: "CloseCase",
        config: {
          templateUrl: 'src/com.admin/clients/referrals/dashboard/closecase.modal.html',
          controller: "CloseCaseController",
          size:"lg",
        },
      }, {
        modal: "Appointment",
        config: {
          templateUrl: 'src/com.admin/clients/referrals/appointments/appointment.modal.html',
          controller: "AppointmentController",
          size:"lg",
        },
      }, {
        modal: "Exercise",
        config: {
          templateUrl: 'src/com.admin/clients/referrals/exercises/exercise.modal.html',
          controller: "ExerciseController",
          size:"lg",
        },
      }, {
        modal: "AuditSettings",
        config: {
          templateUrl: 'src/com.admin/clients/referrals/audits/settings.modal.html',
          controller: "AuditSettingsController",
          size:"md",
        },
      }, {
        modal: "NewAudit",
        config: {
          templateUrl: 'src/com.admin/clients/referrals/audits/newaudit.modal.html',
          controller: "NewAuditController",
          size:"md",
        },
      }];
    }
  }

}());
