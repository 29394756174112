(function() {
  'use strict';

  angular
    .module('app.admin.clients')
    .controller('NewAuditController', NewAuditController);

  NewAuditController.$inject = ['$state', '$stateParams', 'toaster', '$modalInstance', 'AuditsService'];

  function NewAuditController($state, $stateParams, toaster, $modalInstance, AuditsService) {
    var vm = this;

    vm.Save = Save;

    activate();

    function activate() {

    }

    function Save() {
      var _response;
      vm.loading = true;
      AuditsService
        .createAudit()
        .then(function(response) {
          vm.loading = false;
          toaster.pop('success', "Saved!", "New audit was created successfully.");
          $modalInstance.close();
        }, function(responseFail) {
          toaster.pop('warning', "Not Saved!", responseFail.data.Message);
        })
        .finally(function() {
            vm.loading = false;
        });
    }
  }
})();
