(function() {
  'use strict';

  angular
    .module('app.admin.clients')
    .controller('ClientTosFormController', ClientTosFormController);

  ClientTosFormController.$inject = ['ModalParams', '$modalInstance', 'ClientsService', 'toaster'];

  function ClientTosFormController(ModalParams, $modalInstance, ClientsService, toaster) {
    var vm = this;

    vm.Save = Save;
    vm.loading = false;

    activate();

    function activate() {
      vm.Tos = ModalParams.Tos;
    }

    function Save() {
      vm.loading = true;

      ClientsService
        .SaveConfiguration({
          "Company.Tos": vm.Tos
        }, false)
        .then(function() {
          vm.loading = false;

          toaster.pop('success',"Terms of Use were saved.", "Saved!");
          $modalInstance.close();
        }, function(error) {
          toaster.pop('error', "Could not save the data", error.statusText);
        });
    }
  }
}());
