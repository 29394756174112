/* jshint -W097, -W033 */
(function() {
  'use strict';

  angular
    .module('app.shared')
    .factory('SurveysService', SurveysService);

  SurveysService.$inject = ['$data', '$http', '$state', '$cookies', 'CalendarLanguages', 'Upload'];

  function SurveysService($data, $http, $state, $cookies, CalendarLanguages, Upload) {
    var service = {
      _ClientID: null,

      GetSurveys: GetSurveys,
      GetSurveyById: GetSurveyById,
      GetSurveyByCode: GetSurveyByCode,
      GetAnonymousSurvey: GetAnonymousSurvey,
      GetSurveyByRole: GetSurveyByRole,
      SaveSurvey: SaveSurvey,
      SaveSurveys: SaveSurveys,

      GetReferrals: GetReferrals,
      SearchReferrals: SearchReferrals,
      GetReferral: GetReferral,
      GetReferralWithAppointmentCount: GetReferralWithAppointmentCount,
      GetReferralWithInfo:GetReferralWithInfo,
      SubmitReferral: SubmitReferral,
      DeleteReferral: DeleteReferral,
      SetReferralIndicator:SetReferralIndicator,
      SetReferralManagerIndicator:SetReferralManagerIndicator,
      MessagesSend: MessagesSend,
      ImportReferrals: ImportReferrals,

      GetPhoneTriageByReferralId: GetPhoneTriageByReferralId,
      SubmitPhoneTriage: SubmitPhoneTriage,

      GetFirstMeetingByReferralId: GetFirstMeetingByReferralId,
      SubmitFirstMeeting: SubmitFirstMeeting,

      GetDischargeByReferralId: GetDischargeByReferralId,
      SubmitDischarge: SubmitDischarge,
      SubmitExtraSession: SubmitExtraSession,

      GetManagerNoteByReferralId: GetManagerNoteByReferralId,
      SubmitManagerNote: SubmitManagerNote,

      GetAppointmentByReferralId: GetAppointmentByReferralId,
      SubmitAppointment: SubmitAppointment,
      DeleteAppointment: DeleteAppointment,

      GetAppointmentTypeCosts: GetAppointmentTypeCosts,
      SaveAppointmentTypeCosts: SaveAppointmentTypeCosts,

      CalendarOptions: CalendarOptions,
      CalendarEventClickCallback: CalendarEventClickCallback,
      GetCalendarEvents: GetCalendarEvents,

      DisableSurveyQuestions: DisableSurveyQuestions,
      SetupReferralInfo: SetupReferralInfo,
      GetSurveyQuestions: GetSurveyQuestions,
      ManageSurveyComponentsForView: ManageSurveyComponentsForView,

      GetIllnesses: GetIllnesses,

      GetImageItems: GetImageItems,
      GetImageItemById: GetImageItemById,
      SaveImageItem: SaveImageItem,
      DeleteImageItem: DeleteImageItem,

      GetPortalManagers: GetPortalManagers,
      GetPortalManagerById: GetPortalManagerById,
      SavePortalManager: SavePortalManager,
      DeletePortalManager: DeletePortalManager,

      GetOcupHealthNurseList: GetOcupHealthNurseList,

      GetExerciseByReferralID: GetExerciseByReferralID,
      SubmitExercise: SubmitExercise,
      DeleteExercise: DeleteExercise,

      GetClientManagers: GetClientManagers,
    };

    return service;

    // Surveys
    function GetSurveys(IsCustom) {
      return $data
        .list("surveys", {
          IsCustom: IsCustom,
          CID: service._ClientID,
          rl: $cookies.get('rl'),
        });
    }

    function GetSurveyById(SurveyID) {
      return $data
        .get("surveys", {
          ID: SurveyID,
          CID: service._ClientID,
          rl: $cookies.get('rl'),
        });
    }

    function GetSurveyByCode(SurveyCode, IsCustom) {
      return $data
        .get("surveys", {
          Code: SurveyCode,
          IsCustom: IsCustom,
          CID: service._ClientID,
          rl: $cookies.get('rl'),
        });
    }

    // xrhsimopoieitai otan kaloume to survey apo to portal
    function GetAnonymousSurvey() {
      return $http.get(APP.Service + '/surveys/portal');
    }

    // xrhsimopoietai otan kaloume survey sto admin gia na epeksergastoume ta components tou
    function GetSurveyByRole(SurveyCode, IsCustom, RoleType) {
      return $data
        .get("surveys", {
          Code: SurveyCode,
          IsCustom: IsCustom,
          Role: RoleType,
          CID: service._ClientID,
          rl: $cookies.get('rl'),
        });
    }

    // DEPRECATED - pleon xrhsimopoietai h SaveSurveys
    function SaveSurvey(Survey) {
      if(Survey.ID) {
        return $http.put(APP.Service + '/surveys/'+ Survey.ID+'/?CID='+service._ClientID, Survey);
      }
      else {
        return $http.post(APP.Service + '/surveys', Surveys);
      }
    }

    function SaveSurveys(Surveys) {
      return $http.post(APP.Service + '/surveys/save_multiple_surveys?CID=' + service._ClientID + '&rl=' + $cookies.get('rl'), Surveys);
    }

    // Referrals
    function GetReferrals(PhoneTriage, FirstMeeting, Discharge, IsArchived, IsCaseClosed, IsExternal) {
      return $data
        .list("referrals", {
          filterPhonetriage: PhoneTriage,
          filterFirstMeeting: FirstMeeting,
          filterDischarge: Discharge,
          isArchived: IsArchived,
          isCaseClosed: IsCaseClosed,
          isExternal: IsExternal,
          CID: service._ClientID
        });
    }

    function GetReferralWithInfo(ReferralID) {
      return $http.get(APP.Service + '/referrals/referralWithInfo?id=' + ReferralID + "&CID=" + service._ClientID);
    }



    function SearchReferrals(_term, _isArchived, _isCaseClosed) {
      return $data
        .list("referrals", {
          term: _term,
          isArchived: _isArchived,
          isCaseClosed: _isCaseClosed,
          CID: service._ClientID,
        });
    }

    function GetReferral(ReferralID) {
      return $data
        .get("referrals", {
          ID: ReferralID,
          CID: service._ClientID,
        });
    }

    function GetReferralWithAppointmentCount(ReferralID) {
        if(service._ClientID !== null) {
          return $http.get(APP.Service + '/referrals/referralwithappointmentcount?id=' + ReferralID + "&CID=" + service._ClientID);
        }
        else {
          return $http.get(APP.Service + '/referrals/referralwithappointmentcount?id=' + ReferralID);
        }
    }

    function SubmitReferral(Referral) {
      if(service._ClientID !== null) {
        if(Referral.ID) {
          return $http.put(APP.Service + '/referrals/'+ Referral.ID+'/?CID='+service._ClientID, Referral);
        }
        else {
          return $http.post(APP.Service + '/referrals?CID='+service._ClientID, Referral);
        }
      }
      else {
        return $data.save("referrals", Referral);
      }
    }

    function SetReferralIndicator(ReferralID,Indicator) {
      if(service._ClientID !== null) {
          return $http.put(APP.Service + '/referrals/SetReferralIndicator?id='+ ReferralID+'&indicator='+Indicator.replace("#", "%23")+'&CID='+service._ClientID); 
      }
      
    }

    function SetReferralManagerIndicator(ReferralID,Indicator) {
          return $http.put(APP.Service + '/referrals/SetManagerReferralIndicator?id='+ ReferralID+'&indicator='+Indicator.replace("#", "%23")); 
    }
    

    function DeleteReferral(ReferralID) {
      return $http.delete(APP.Service + '/referrals/' + ReferralID + "/?CID=" + service._ClientID);
    }

    function ImportReferrals(FileUris) {
      if(service._ClientID !== null) {
        return $http.post(APP.Service + '/notificationparser/notifications?CID='+service._ClientID, FileUris);
      }
      else {
        return $http.post(APP.Service + '/notificationparser/notifications', FileUris)
      }
    }

    function MessagesSend(files) {
      var _data = {};
      if (files.length > 1)
        _data.file = files;
      else {
        _data.files = files;
      }

      if(service._ClientID !== null) {
        return Upload
          .upload({
            url: APP.Service + '/notificationparser/upload?CID='+service._ClientID,
            data: _data
          });
      }
      else {
        return Upload
          .upload({
            url: APP.Service + '/notificationparser/upload',
            data: _data
          });
      }
    }

    // PhoneTriages
    function GetPhoneTriageByReferralId(ReferralID) {
      if(service._ClientID !== null) {
        return $http.get(APP.Service + '/phonetriages/byreferral?referralID=' + ReferralID + "&CID=" + service._ClientID);
      }
      else {
        return $http.get(APP.Service + '/phonetriages/byreferral?referralID=' + ReferralID);
      }
    }

    function SubmitPhoneTriage(PhoneTriage) {
      if(service._ClientID !== null) {
        if(PhoneTriage.ID) {
          return $http.put(APP.Service + '/phonetriages/'+ PhoneTriage.ID+'/?CID='+service._ClientID, PhoneTriage);
        }
        else {
          return $http.post(APP.Service + '/phonetriages?CID='+service._ClientID, PhoneTriage);
        }
      }
      else {
        return $data.save("phonetriages", PhoneTriage);
      }
    }

    // FirstMeetings
    function GetFirstMeetingByReferralId(ReferralID) {
      if(service._ClientID !== null) {
        return $http.get(APP.Service + '/firstmeetings/byreferral?referralID=' + ReferralID + "&CID=" + service._ClientID);
      }
      else {
        return $http.get(APP.Service + '/firstmeetings/byreferral?referralID=' + ReferralID);
      }
    }

    function SubmitFirstMeeting(FirstMeeting) {
      if(service._ClientID !== null) {
        if(FirstMeeting.ID) {
          return $http.put(APP.Service + '/firstmeetings/'+ FirstMeeting.ID+'/?CID='+service._ClientID, FirstMeeting);
        }
        else {
          return $http.post(APP.Service + '/firstmeetings?CID='+service._ClientID, FirstMeeting);
        }
      }
      else {
        return $data.save("firstmeetings", FirstMeeting);
      }
    }

    // Discharges
    function GetDischargeByReferralId(ReferralID, IsFinal) {
      if(service._ClientID !== null) {
        return $http.get(APP.Service + '/discharges/byreferral?referralID=' + ReferralID + "&isFinal=" + IsFinal + "&CID=" + service._ClientID);
      }
      else {
        return $http.get(APP.Service + '/discharges/byreferral?referralID=' + ReferralID + "&isFinal=" + IsFinal);
      }
    }

    function SubmitDischarge(Discharge) {
      if(service._ClientID !== null) {
        if(Discharge.ID) {
          return $http.put(APP.Service + '/discharges/'+ Discharge.ID+'/?CID='+service._ClientID, Discharge);
        }
        else {
          return $http.post(APP.Service + '/discharges?CID='+service._ClientID, Discharge);
        }
      }
      else {
        return $data.save("discharges", Discharge);
      }
    }

    function SubmitExtraSession(ExtraSession) {
      if(service._ClientID !== null) {
        console.log("den mporei na ginei kati apo moderator, physio");
      }
      else {
        if(ExtraSession.ID) {
          return $http.put(APP.Service + '/discharges/saveextrasession?id='+ ExtraSession.ID, ExtraSession);
        }
        else {
          console.log("den mporei na ginei apothikefsh apo afto to shmeio, mono update!");
        }
      }
    }

    // ManagerNotes
    function GetManagerNoteByReferralId(ReferralID, IsFinal) {
      if(service._ClientID !== null) {
        return $http.get(APP.Service + '/managernotes/byreferral?referralID=' + ReferralID + "&CID=" + service._ClientID);
      }
      else {
        return $http.get(APP.Service + '/managernotes/byreferral?referralID=' + ReferralID);
      }
    }

    function SubmitManagerNote(ManagerNote) {
      if(service._ClientID !== null) {
        if(ManagerNote.ID) {
          return $http.put(APP.Service + '/managernotes/'+ ManagerNote.ID+'/?CID='+service._ClientID, ManagerNote);
        }
        else {
          return $http.post(APP.Service + '/managernotes?CID='+service._ClientID, ManagerNote);
        }
      }
      else {
        return $data
          .save("managernotes", ManagerNote);
      }
    }

    // Appointments
    function GetAppointmentByReferralId(ReferralID, IsFinal) {
      if(service._ClientID !== null) {
        return $http.get(APP.Service + '/appointments/byreferral?referralID=' + ReferralID + "&CID=" + service._ClientID);
      }
      else {
        return $http.get(APP.Service + '/appointments/byreferral?referralID=' + ReferralID);
      }
    }

    function SubmitAppointment(Appointment) {
      if(service._ClientID !== null) {
        if(Appointment.ID) {
          return $http.put(APP.Service + '/appointments/'+ Appointment.ID+'/?CID='+service._ClientID, Appointment);
        }
        else {
          return $http.post(APP.Service + '/appointments?CID='+service._ClientID, Appointment);
        }
      }
      else {
        return $data.save("appointments", Appointment);
      }
    }

    function DeleteAppointment(AppointmentID) {
      if(service._ClientID !== null) {
        return $http.delete(APP.Service + '/appointments/' + AppointmentID + "/?CID=" + service._ClientID);
      }
      else {
        return $http.delete(APP.Service + '/appointments/' + AppointmentID);
      }
    }

    // Appointment Type Costs
    function GetAppointmentTypeCosts() {
      if(service._ClientID !== null) {
        return $http.get(APP.Service + '/appointmenttypecosts?CID=' + service._ClientID);
      }
      else {
        return $http.get(APP.Service + '/appointmenttypecosts');
      }
    }

    function SaveAppointmentTypeCosts(AppointmentTypeCosts) {
      return $http.post(APP.Service + '/appointmenttypecosts/save_multiple_typecosts?CID=' + service._ClientID + '&rl=' + $cookies.get('rl'), AppointmentTypeCosts);
    }

    // Calendar
    function CalendarOptions(params) {
      var _options = {
        minTime: moment.utc(APP.Client.Business.HoursFrom, "HH:mm").format("HH:mm:ss"),
        maxTime: moment.utc(APP.Client.Business.HoursTo, "HH:mm").format("HH:mm:ss"),
        defaultView: 'agendaWeek',
        contentHeight: 'auto',
        lang: CalendarLanguages[APP.Client.System.Language],
        editable: true,
        allDaySlot: false,
        lazyFetching: false,

        // timezone: APP.Client.System.Timezone,

        timeFormat: 'HH:mm',
        slotDuration: APP.Client.Business.Duration,

        header: {
          left: 'today agendaDay,agendaWeek',
          center: '',
          right: 'prev,next'
        },
        eventClick: service.CalendarEventClickCallback
      }

      angular.extend(_options,params);
      return _options;
    }

    function CalendarEventClickCallback(appointment) {
      if (appointment.ReferralID) {
        if(service._ClientID !== null) {
          $state.go("admin.clients.client.survey.referral", {
            ReferralID: appointment.ReferralID,
            HideFirstMeeting: appointment.HideFirstMeeting,
            IsArchived: false
          });
        }
        else {
          $state.go("admin.manager_survey.referral", {
            ID: appointment.ReferralID,
            HideFirstMeeting: appointment.HideFirstMeeting,
            IsArchived: false
          });
        }
      }
    }

    function GetCalendarEvents(params) {
      // Params: From, To
      if(service._ClientID !== null) {
        return $http.get(APP.Service + '/appointments/calendar?from=' + params.From + "&to=" + params.To + "&CID=" + service._ClientID);
      }
      else {
        return $http.get(APP.Service + '/appointments/calendar', {params: params});
      }
    }

    // Custom
    function DisableSurveyQuestions(survey) {
      _.each(survey, function(questionnaires, key) {
        _.each(questionnaires, function(questionnaire, key) {
          _.each(questionnaire.questions, function(question, key) {
            question.disabled = true;
          })
        })
      });
      return survey;
    }

    function SetupReferralInfo(referral) {
      var _dateOfBirthday = new Date(referral.ReferralDateOfBirth);
      var _day = _dateOfBirthday.getDate();
      var _month = _dateOfBirthday.getMonth();
      _day = _day > 9 ? _day : "0" + _day;
      _month = _month + 1; // giati ksekinaei apo 0
      _month = _month > 9 ? _month : "0" + _month; // an einai 1-9 tote vazoume kai to 0 mprosta giati allios gamietai kai ekane to bug me thn -1 mera
      var _year = _dateOfBirthday.getFullYear();

      referral.ReferralDateOfBirth = new Date(_year + "-" + _month + "-" + _day);
      return referral;
    }

    // TODO: gia na kalyterepsoume tous xronous tha mporousame na vlepoume ean to length ton duo pinakon - questionKeys & returned array - einai idio
    //       kai an einai tote na kanoume break, alla den douleve to break kai den ithela na spataliso allo xrono. 15/02/2015
    function GetSurveyQuestions(survey, questionKeys) {
      var _questions = [];
      _.each(survey, function(questionnaires, key) {
        _.each(questionnaires, function(questionnaire, key) {
          _.each(questionnaire.questions, function(question, key) {
            if(questionKeys.indexOf(question.alias) !== -1) {
              _questions[question.alias] = question;
            }
          })
        })
      });
      return _questions;
    }

    // apo ta surveys, gia kathe group, spaei to view se questions.length / splitIn gia na deiksei sto layout
    function ManageSurveyComponentsForView(survey, splitIn) {
      var groups = {};
      _.each(survey.groups, function(group, alias) {
        groups[alias] = {};
        for(var i = 0; i < group.questions.length; i+=splitIn) {
          groups[alias][i] = [];
          for(var j = 0; j < splitIn; j++) {
            if(!_.isUndefined(group.questions[i+j])) {
              groups[alias][i].push(group.questions[i+j]);
            }
          }
        }
      });
      return groups;
    }

    // Illnesses
    function GetIllnesses() {
      return $data
        .list("illnesses", {
          CID: service._ClientID
        });
    }

    // ImageItems
    function GetImageItems() {
      return $data
        .list("imageitems", {
          CID: service._ClientID
        });
    }

    function GetImageItemById(ImageItemID) {
      return $data
        .get("imageitems", {
          ID: ImageItemID,
          CID: service._ClientID,
          rl: $cookies.get('rl')
        });
    }

    function SaveImageItem(ImageItem) {
      if(ImageItem.ID) {
        return $http.put(APP.Service + '/imageitems/'+ ImageItem.ID + '/?CID=' + service._ClientID, ImageItem);
      }
      else {
        return $http.post(APP.Service + '/imageitems/?CID=' + service._ClientID, ImageItem);
      }
    }

    function DeleteImageItem(ImageItemID) {
      return $http.delete(APP.Service + '/imageitems/' + ImageItemID + "/?CID=" + service._ClientID);
    }

    // PortalManagers
    function GetPortalManagers() {
      return $data
        .list("portalmanagers", {
          CID: service._ClientID
        });
    }  

    function GetPortalManagerById(PortalManagerID) {
      return $data
        .get("portalmanagers", {
          ID: PortalManagerID,
          CID: service._ClientID,
          rl: $cookies.get('rl')
        });
    }

    function SavePortalManager(PortalManager) {
      if(PortalManager.ID) {
        return $http.put(APP.Service + '/portalmanagers/'+ PortalManager.ID + '/?CID=' + service._ClientID, PortalManager);
      }
      else {
        return $http.post(APP.Service + '/portalmanagers/?CID=' + service._ClientID, PortalManager);
      }
    }

    function DeletePortalManager(PortalManagerID) {
      return $http.delete(APP.Service + '/portalmanagers/' + PortalManagerID + "/?CID=" + service._ClientID);
    }

    
    // Occupational health nurses
    function GetOcupHealthNurseList() {
      return $data
        .list("ocupHealthNurses", {
          CID: service._ClientID
        });
    }


    // Exercises
    function GetExerciseByReferralID(ReferralID) {
      if(service._ClientID !== null) {
        return $http.get(APP.Service + '/exercises/byreferral?referralID=' + ReferralID + "&CID=" + service._ClientID);
      }
      else {
        return $http.get(APP.Service + '/exercises/byreferral?referralID=' + ReferralID);
      }
    }

    function SubmitExercise(Exercise) {
      if(service._ClientID !== null) {
        if(Exercise.ID) {
          return $http.put(APP.Service + '/exercises/'+ Exercise.ID +'/?CID='+service._ClientID, Exercise);
        }
        else {
          return $http.post(APP.Service + '/exercises?CID='+service._ClientID, Exercise);
        }
      }
      else {
        return $data.save("exercises", Exercise);
      }
    }

    function DeleteExercise(ExerciseID) {
      if(service._ClientID !== null) {
        return $http.delete(APP.Service + '/exercises/' + ExerciseID + "/?CID=" + service._ClientID);
      }
      else {
        return $http.delete(APP.Service + '/exercises/' + ExerciseID);
      }
    }

    // DEPRECATED: Xrhsimopoieitai to PortalManagers pleon, giati ithele ksexoristh diaxeirish. ego to krathsa parola afta giati mporei na allaksei kati sto mellon
    // Client Managers list
    function GetClientManagers() {
      if(service._ClientID !== null) {
        return $http.get(APP.Service + '/users/getclientmanagers?CID=' + service._ClientID);
      }
      else {
        return $http.get(APP.Service + '/users/getclientmanagers');
      }
    }
  }

}());
