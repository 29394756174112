/* jshint -W097, -W064 */
(function() {
  'use strict';

  angular
    .module('app.shared')
    .factory('DashboardService', DashboardService);

  DashboardService.$inject = ['$q', '$http', '$filter', '$cookies', 'SurveysService'];

  function DashboardService($q, $http, $filter, $cookies, SurveysService) {

    var service = {
      GetChart: GetChart,
      GetRecentReferrals: GetRecentReferrals,
      GetLateReferrals: GetLateReferrals,
      GetStatistics: GetStatistics,
      GetLatestAppointments: GetLatestAppointments,
      GetDischargesPerMonth: GetDischargesPerMonth,
      GetNewUsersPerMonth: GetNewUsersPerMonth,
      GetNumberOfDaysSavedPerMonth: GetNumberOfDaysSavedPerMonth,
      GetAppointmentsPerMonth: GetAppointmentsPerMonth,
      GetEfficiencyPerMonth: GetEfficiencyPerMonth,
    };

    return service;

    function GetChart() {
      if(SurveysService._ClientID !== null) {
          return $http
            .get(APP.Service + "/dashboard/chart/?CID=" + SurveysService._ClientID + '&rl=' + $cookies.get('rl'))
            .then(function(results){
              return results.data;
            });
        }
        else {
          return $http
            .get(APP.Service + "/dashboard/chart")
            .then(function(results){
              return results.data;
            });
        }
    }

    function GetRecentReferrals() {
      if(SurveysService._ClientID !== null) {
          return $http
            .get(APP.Service + "/dashboard/recent_referrals/?CID=" + SurveysService._ClientID + '&rl=' + $cookies.get('rl'))
            .then(function(results){
              return results.data;
            });
        }
        else {
          return $http
            .get(APP.Service + "/dashboard/recent_referrals")
            .then(function(results){
              return results.data;
            });
        }
    }

    function GetLateReferrals() {
      if(SurveysService._ClientID !== null) {
          return $http
            .get(APP.Service + "/dashboard/late_referrals/?CID=" + SurveysService._ClientID + '&rl=' + $cookies.get('rl'))
            .then(function(results){
              return results.data;
            });
        }
        else {
          return $http
            .get(APP.Service + "/dashboard/late_referrals")
            .then(function(results){
              return results.data;
            });
        }
    }

    function GetStatistics() {
      if(SurveysService._ClientID !== null) {
          return $http
            .get(APP.Service + "/dashboard/statistics/?CID=" + SurveysService._ClientID + '&rl=' + $cookies.get('rl'))
            .then(function(results){
              return results.data;
            });
        }
        else {
          return $http
            .get(APP.Service + "/dashboard/statistics")
            .then(function(results){
              return results.data;
            });
        }
    }

    function GetLatestAppointments() {
      if(SurveysService._ClientID !== null) {
          return $http
            .get(APP.Service + "/dashboard/latest_appointments/?CID=" + SurveysService._ClientID + '&rl=' + $cookies.get('rl'))
            .then(function(results){
              return results.data;
            });
        }
        else {
          return $http
            .get(APP.Service + "/dashboard/latest_appointments")
            .then(function(results){
              return results.data;
            });
        }
    }

    function GetDischargesPerMonth() {
      if(SurveysService._ClientID !== null) {
          return $http
            .get(APP.Service + "/dashboard/discharges_per_month/?CID=" + SurveysService._ClientID + '&rl=' + $cookies.get('rl'))
            .then(function(results){
              return results.data;
            });
        }
        else {
          return $http
            .get(APP.Service + "/dashboard/discharges_per_month")
            .then(function(results){
              return results.data;
            });
        }
    }

    function GetNewUsersPerMonth() {
      if(SurveysService._ClientID !== null) {
          return $http
            .get(APP.Service + "/dashboard/new_users_per_month/?CID=" + SurveysService._ClientID + '&rl=' + $cookies.get('rl'))
            .then(function(results){
              return results.data;
            });
        }
        else {
          return $http
            .get(APP.Service + "/dashboard/new_users_per_month")
            .then(function(results){
              return results.data;
            });
        }
    }

    function GetNumberOfDaysSavedPerMonth() {
      if(SurveysService._ClientID !== null) {
          return $http
            .get(APP.Service + "/dashboard/number_of_days_saved_per_month/?CID=" + SurveysService._ClientID + '&rl=' + $cookies.get('rl'))
            .then(function(results){
              return results.data;
            });
        }
        else {
          return $http
            .get(APP.Service + "/dashboard/number_of_days_saved_per_month")
            .then(function(results){
              return results.data;
            });
        }
    }

    function GetAppointmentsPerMonth() {
      if(SurveysService._ClientID !== null) {
          return $http
            .get(APP.Service + "/dashboard/appointments_per_month/?CID=" + SurveysService._ClientID + '&rl=' + $cookies.get('rl'))
            .then(function(results){
              return results.data;
            });
        }
        else {
          return $http
            .get(APP.Service + "/dashboard/appointments_per_month")
            .then(function(results){
              return results.data;
            });
        }
    }

    function GetEfficiencyPerMonth() {
      if(SurveysService._ClientID !== null) {
          return $http
            .get(APP.Service + "/dashboard/efficiency_per_month/?CID=" + SurveysService._ClientID + '&rl=' + $cookies.get('rl'))
            .then(function(results){
              return results.data;
            });
        }
        else {
          return $http
            .get(APP.Service + "/dashboard/efficiency_per_month")
            .then(function(results){
              return results.data;
            });
        }
    }

  }

}());
