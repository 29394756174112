/* jshint -W097, -W033 */
(function() {
  'use strict';

  angular
    .module('app.admin.manager')
    .factory('ReferralsModals', ReferralsModals);

  ReferralsModals.$inject = ['modalHelper'];

  function ReferralsModals(modalHelper) {
    return modalHelper.configureModals(getModals());

    function getModals() {
      return [{
        modal: "SearchReferrals",
        config: {
          templateUrl: 'src/com.admin/manager/referrals/views/search.modal.html',
          controller: "ReferralSearchController",
          size:"lg",
        },
      }, {
        modal: "CloseCase",
        config: {
          templateUrl: 'src/com.admin/manager/referrals/views/closecase.modal.html',
          controller: "CloseCaseController",
          size:"lg",
        },
      }, {
        modal: "ExtraSessions",
        config: {
          templateUrl: 'src/com.admin/manager/referrals/views/extrasession.modal.html',
          controller: "ExtraSessionController",
          size:"lg",
        },
      }, {
        modal: "Appointment",
        config: {
          templateUrl: 'src/com.admin/manager/appointments/appointment.modal.html',
          controller: "ManagerAppointmentController",
          size:"lg",
        },
      }, {
        modal: "Exercise",
        config: {
          templateUrl: 'src/com.admin/clients/referrals/exercises/exercise.modal.html',
          controller: "ManagerExerciseController",
          size:"lg",
        },
      }, {
        modal: "AuditSettings",
        config: {
          templateUrl: 'src/com.admin/clients/referrals/audits/settings.modal.html',
          controller: "AuditSettingsController",
          size:"md",
        },
      }, {
        modal: "NewAudit",
        config: {
          templateUrl: 'src/com.admin/clients/referrals/audits/newaudit.modal.html',
          controller: "NewAuditController",
          size:"md",
        },
      }, {
        modal: "ImportReferrals",
        config: {
          templateUrl: 'src/com.admin/clients/referrals/import/import.modal.html',
          controller: "ReferralImportController",
          size:"lg",
        },
      }];
    }
  }

}());
