(function() {
  'use strict';

  angular
    .module('app.admin.clients')
    .controller('ClientDetailsController', ClientDetailsController);

  ClientDetailsController.$inject = ['ClientsService', 'ModalParams', '$modalInstance'];

  function ClientDetailsController(ClientsService, ModalParams, $modalInstance) {
    var vm = this;

    vm.Client = {};
    vm.LoadClient = LoadClient;
    vm.SaveClient = SaveClient;

    activate();

    function activate() {
      if (ModalParams.ID) {
        vm.LoadClient();
      }
    }

    function LoadClient() {
      vm.loading = true;
      ClientsService
        .GetClient(ModalParams.ID)
        .then(function(Client) {
          vm.Client = Client;
        })
        .finally(function() {
          vm.loading = false;
        });
    }

    function SaveClient() {
      vm.loading = true;
      ClientsService
        .SaveClient(vm.Client)
        .then(function(Client) {
          $modalInstance.close(Client);
        })
        .finally(function() {
          vm.loading = false;
        });
    }

  }

}());
