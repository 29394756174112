(function() {
  'use strict';

  angular
    .module('app.admin.clients')
    .controller('ReferralsAppointmentsController', ReferralsAppointmentsController);

  ReferralsAppointmentsController.$inject = ['$scope', '$stateParams','EnumsService', 'AuthService', 'SurveysService', 'toaster', 'AttendStatus', 'ReferralType', 'Gender', 'WorkStatus', 'AppointmentType', 'ReferralsModals'];

  function ReferralsAppointmentsController($scope, $stateParams,EnumsService, AuthService, SurveysService, toaster, AttendStatus, ReferralType, Gender, WorkStatus, AppointmentType, ReferralsModals) {
    var vm = this;

    // enums
    vm.AttendStatus = AttendStatus;
    vm.Gender = Gender;
    vm.ReferralType =EnumsService.GetClientEnum(ReferralType);
    vm.WorkStatus = EnumsService.GetClientEnum(WorkStatus);
    vm.AppointmentType = EnumsService.GetClientEnum(AppointmentType);

    vm.LoadSurvey = LoadSurvey;
    vm.LoadAppointments = LoadAppointments;

    vm.AppointmentModal = AppointmentModal;
    vm.DeleteAppointment = DeleteAppointment;

    activate();

    function activate() {
      vm.Back = $scope.$parent.vm.Back;
      vm.Role = AuthService.Roles[0];

      vm.LoadSurvey();
    }

    function LoadSurvey() {
      vm.loading = true;
      SurveysService
        .GetSurveyByCode("appointments", true)
        .then(function(CustomAndReferralSurvey) {
          vm.IsActive = CustomAndReferralSurvey.CustomSurvey.IsActive;
          vm.CanEdit = CustomAndReferralSurvey.CustomSurvey.CanEdit;
          vm.CustomQuestionnaire = vm.IsActive ? JSON.parse(CustomAndReferralSurvey.CustomSurvey.Configuration) : '';
          vm.Questionnaire = JSON.parse(CustomAndReferralSurvey.ReferralSurvey.Configuration);
          // vm.Questionnaire = SurveysService.DisableSurveyQuestions(vm.Questionnaire);
        }, function(error) {
          toaster.pop('error', "Could not retrieve the survey", error.statusText);
        })
        .finally(function() {
          vm.LoadAppointments();
        });
    }

    function LoadAppointments() {
      vm.AppointmentTime = null;
      vm.Appointment = {};
      vm.Appointment.ReferralID = $stateParams.ReferralID;
      vm.Appointment.AppointmentDate = new Date();

      vm.AppointmentsList = [];

      SurveysService
        .GetAppointmentByReferralId($stateParams.ReferralID, false)
        .then(function(appointmentWithInfo) {
          appointmentWithInfo = appointmentWithInfo.data;
          vm.Referral = SurveysService.SetupReferralInfo(appointmentWithInfo.Referral);

          // ean einai null den theloume na spasei h lista pou ftiaksame tin arxh
          if(appointmentWithInfo.Appointments !== null) {
            vm.AppointmentsList = appointmentWithInfo.Appointments;
          }
          
        }, function(error) {
          toaster.pop('error', "Could not retrieve the data", error.statusText);
        })
        .finally(function() {
          vm.loading = false;
        });
    }

    function AppointmentModal(Appointment) {
      ReferralsModals.Appointment({
        Appointment: Appointment,
        CustomQuestionnaire: vm.CustomQuestionnaire,
        ReferralID: $stateParams.ReferralID
      })
      .then(function() {
        vm.loading = true;
        vm.LoadAppointments();
      });
    }

    function DeleteAppointment(AppointmentID) {
      vm.loading = true;
      SurveysService
        .DeleteAppointment(AppointmentID)
        .then(function(response) {
          vm.loading = false;
          vm.LoadAppointments();
        }, function(error) {
          toaster.pop('error', "Could not delete the data", error.statusText);
        });
    }
  }
})();
