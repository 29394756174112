/* jshint -W097, -W033 */
(function() {
  'use strict';

  angular
    .module('app.shared.filemanager')
    .factory('FileManagerService', FileManagerService);

  FileManagerService.$inject = ['$data', 'Upload'];

  function FileManagerService($data, Upload) {

    var service = {
      Send: Send,
      GetFile: GetFile,
      SaveFile: SaveFile,
    };

    return service;

    function Send(files) {

      var _data = {};
      if (files.length > 1)
        _data.file = files;
      else {
        _data.files = files;
      }

      return Upload
        .upload({
          url: APP.Service + '/filemanager/upload',
          data: _data
        })
        .then(function(response) {
          return response.data;
        });

    }

    function GetFile(FileID) {
      return $data
        .get("filemanager", {
          ID: FileID
        })
    }

    function SaveFile(File) {
      return $data
        .save("filemanager", File)
    }
  }

}());
