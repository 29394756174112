/* jshint -W097, -W033 */
(function() {
  'use strict';

  angular
    .module('app.shared.client')
    .factory('ClientService', ClientService)

  ClientService.$inject = ['$http', '$translate', 'tmhDynamicLocale'];

  function ClientService($http, $translate, tmhDynamicLocale) {

    var service = {
      LoadSettings: LoadSettings,
      GetSettings: GetSettings,
      PostSettings: PostSettings,
    }

    return service;

    function LoadSettings() {
      $translate.use(APP.Client.System.Locale);
      tmhDynamicLocale.set(APP.Client.System.Locale);
      moment.locale(APP.Client.System.Locale);
      // moment.tz.setDefault(APP.Client.System.Timezone);
    }

    function GetSettings() {
      return $http
        .get(APP.Service + '/configuration')
        .then(function(response) {
          window.bootstrap.LoadSettings(response.data);
          window.bootstrap.ParseSettings();
        });
    }

    function PostSettings(Settings) {
      return $http
        .post(APP.Service + '/configuration', Settings)
        .then(function(response) {
          APP.Client = response.data;
        });
    }

  }
}());
