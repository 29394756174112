/* jshint -W097, -W033 */
(function() {
  'use strict';

  angular
    .module('app.admin.manager')
    .run(appRun);

  appRun.$inject = ['routerHelper'];

  function appRun(routerHelper) {
    routerHelper.configureStates(getStates());
  }

  function getStates() {
    return [{
        state: 'admin.manager',
        config: {
          url: '/dashboard',
          templateUrl: 'src/com.admin/manager/dashboard/view.html',
          controller: "ManagerDashboardController",
        }
      }, {
      state: 'admin.manager_referrals',
      config: {
        url: '/referrals',
        params: {
          IsArchived: false,
          IsCaseClosed: false
        },
        templateUrl: 'src/com.admin/manager/referrals/views/view.html',
        controller: "ManagerReferralsController",
      }
    }, {
      state: 'admin.manager_archived',
      config: {
        url: '/archived',
        params: {
          IsArchived: true,
          IsCaseClosed: false
        },
        templateUrl: 'src/com.admin/manager/referrals/views/view.html',
        controller: "ManagerReferralsController",
      }
    }, {
      state: 'admin.manager_closedcases',
      config: {
        url: '/closedcases',
        params: {
          IsArchived: false,
          IsCaseClosed: true
        },
        templateUrl: 'src/com.admin/manager/referrals/views/view.html',
        controller: "ManagerReferralsController",
      }
    }, {
      state: 'admin.manager_calendar',
      config: {
        url: '/calendar',
        templateUrl: 'src/com.admin/manager/calendar/view.html',
        controller: "ManagerCalendarController",
      }
    }, {
      state: 'admin.manager_survey',
      config: {
        abstract: true,
        url: "/referrals/:ID",
        params: {
          ID: null,
          HideFirstMeeting: false,
          IsArchived: false,
          ShowFinalDischarge: false,
          IsCaseClosed: false
        },
        templateUrl: 'src/com.admin/manager/survey/view.html',
        controller: "ManagerSurveyController",
      }
    }, {
      state: 'admin.manager_survey.referral',
      config: {
        url: "/referral",
        templateUrl: 'src/com.admin/manager/referral/view.html',
        controller: "ManagerReferralController",
      }
    }, {
      state: 'admin.manager_survey.phonetriage',
      config: {
        url: "/phonetriage",
        templateUrl: 'src/com.admin/manager/phonetriage/view.html',
        controller: "ManagerPhoneTriageController",
      }
    }, {
      state: 'admin.manager_survey.firstmeeting',
      config: {
        url: "/firstmeeting",
        templateUrl: 'src/com.admin/manager/firstmeeting/view.html',
        controller: "ManagerFirstMeetingController",
      }
    }, {
      state: 'admin.manager_survey.discharge',
      config: {
        url: "/discharge",
        templateUrl: 'src/com.admin/manager/discharge/view.html',
        controller: "ManagerDischargeController",
      }
    }, {
      state: 'admin.manager_survey.managernotes',
      config: {
        url: "/managernotes",
        templateUrl: 'src/com.admin/manager/managernotes/view.html',
        controller: "ManagerNotesController",
      }
    }, {
      state: 'admin.manager_survey.finaldischarge',
      config: {
        url: "/finaldischarge",
        templateUrl: 'src/com.admin/manager/finaldischarge/view.html',
        controller: "ManagerFinalDischargeController",
      }
    }, {
      state: 'admin.manager_survey.appointments',
      config: {
        url: "/appointments",
        templateUrl: 'src/com.admin/manager/appointments/view.html',
        controller: "ManagerAppointmentsController",
      }
    }, {
      state: 'admin.manager_survey.exercises',
      config: {
        url: "/exercises",
        templateUrl: 'src/com.admin/manager/exercises/view.html',
        controller: "ManagerExercisesController",
      }
    }, {
      state: 'admin.manager_reports',
      config: {
        url: "/exercises",
        templateUrl: 'src/com.admin/manager/reports/view.html',
        controller: "ManagerReportsController",
      }
    }];
  }
}());
