(function() {
  'use strict';

  angular
    .module('app.admin.clients')
    .controller('ClientSurveysController', ClientSurveysController);

  ClientSurveysController.$inject = ['$stateParams', 'SurveysService', 'ClientsModals'];

  function ClientSurveysController($stateParams, SurveysService, ClientsModals) {

    var vm = this;

    vm.LoadSurveys = LoadSurveys;
    vm.EditSurvey = EditSurvey;

    activate();

    function activate() {
      SurveysService._ClientID = $stateParams.ID;
      vm.LoadSurveys();
    }

    function LoadSurveys() {
      vm.loading = true;
      SurveysService
        .GetSurveys(false)
        .then(function(surveys) {
          vm.Surveys = surveys;
        })
        .finally(function() {
          vm.loading = false;
        });
    }

    function EditSurvey(_surveyID) {
      ClientsModals
        .EditSurvey({
          SurveyID: _surveyID
        })
        .then(function(client) {
          vm.LoadSurveys();
        });
    }

  }
}());
