(function() {
  'use strict';

  angular
    .module('app.shared.auth')
    .controller('AuthConfirmController', AuthConfirmController);

  AuthConfirmController.$inject = ['$state', '$stateParams', 'AuthService'];

  function AuthConfirmController($state, $stateParams, AuthService) {
    var vm = this;

    vm.Alert = false;
    vm.password = "";
    vm.repeatpassword = "";

    vm.Confirm = Confirm;
    vm.FormValidate = FormValidate;

    activate();

    function activate() {

    }

    function Confirm() {
      vm.loading = true;

      if (vm.FormValidate()) {
        vm.loading = false;
        return false;
      }

      AuthService
        .ConfirmAccount({
          UserName: $stateParams.u,
          Token: $stateParams.token,
          Password: vm.password
        })
        .then(function() {
          $state.go("auth.login", {
            ref: "re"
          });
        }, function(reason) {
          vm.Alert = reason.data.Message;
        })
        .finally(function() {
          vm.loading = false;
        });
    }

    function FormValidate() {

      vm.Alert = false;

      if (vm.password.length < 6 || vm.password.length > 64) {
        vm.Alert = "IsRequired";
      }
      if (vm.password != vm.repeatpassword) {
        vm.Alert = "Match";
      }
      if (vm.password.length < 6 || vm.password.length > 64 && vm.password.length !== 0) {
        vm.Alert = "Length";
      }

      return vm.Alert;

    }

  }

}());
