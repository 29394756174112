/* jshint -W097, -W064 */
(function() {
  'use strict';

  angular
    .module('app.landing')
    .factory('LandingModals', LandingModals);

  LandingModals.$inject = ['modalHelper'];

  function LandingModals(modalHelper) {
    return modalHelper.configureModals(getModals());

    function getModals() {
      return [{
        modal: "Terms",
        config: {
          templateUrl: 'src/com.landing/_common/views/terms.modal.html'
        },
      }];
    }
  }

}());
