(function() {
  'use strict';

  angular
    .module('app.admin.clients')
    .controller('ReferralsDashboardController', ReferralsDashboardController);

  ReferralsDashboardController.$inject = ['$stateParams', '$state', 'SurveysService', 'ReferralsModals', 'AuthService'];

  function ReferralsDashboardController($stateParams, $state, SurveysService, ReferralsModals, AuthService) {
    var vm = this;

    vm.LoadReferrals = LoadReferrals;
    vm.SearchReferralsModal = SearchReferralsModal;
    vm.CloseCase = CloseCase;
    vm.ImportReferralsModal = ImportReferralsModal;
    vm.setIndicator = setIndicator;
    vm.filter = "all";
    // vm.DeleteReferral = DeleteReferral;

    vm.PhysioTypes = [];

    activate();

    function activate() {
      vm.PhysioTypes.push({
        id: {},
        label: "-- Any --"
      });
      vm.PhysioTypes.push({
        id: false,
        label: "Internal"
      });
      vm.PhysioTypes.push({
        id: true,
        label: "External"
      });

      vm.IsArchived = $stateParams.IsArchived;
      vm.IsCaseClosed = $stateParams.IsCaseClosed;
      vm.Role = AuthService.Roles[0];
      vm.PhoneTriage = vm.Role == "PHYSIO" ? true : false;
      vm.FirstMeeting = false;
      vm.Discharge = false;
      SurveysService._ClientID = $stateParams.ID;
      vm.PhysioType = {};
      vm.LoadReferrals();
    }

    function LoadReferrals() {
      vm.loading = true;

      switch(vm.filter) {
        case "pt":
            vm.PhoneTriage = 0;
            vm.FirstMeeting = -1;
            vm.Discharge = -1;
            break;
        case "fm":
            vm.PhoneTriage = -1;
            vm.FirstMeeting = 0;
            vm.Discharge = -1;
            break;
        case "dc":
            vm.PhoneTriage = -1;
            vm.FirstMeeting = -1;
            vm.Discharge = 0;
            break;
        default:
            vm.PhoneTriage = -1;
            vm.FirstMeeting = -1;
            vm.Discharge = -1;
        break;
    }

      SurveysService
        .GetReferrals(vm.PhoneTriage, vm.FirstMeeting, vm.Discharge, vm.IsArchived, vm.IsCaseClosed, vm.PhysioType)
        .then(function(data) {
            vm.ReferralsWithInfo = data;
            vm.ClientID = $stateParams.ID;
          _.each(vm.ReferralsWithInfo, function(ref){ ref.backgroundStyle = ref.ColorIndicator!=null?{'background-color':ref.ColorIndicator}:{} }) ; 
        }, function(error) {
          toaster.pop('error', "Could not retrieve the data", error.statusText);
        })
        .finally(function() {
          vm.loading = false;
        });
    }

    function SearchReferralsModal() {
      ReferralsModals.SearchReferrals()
        .then(function(referralInfo) {
          if(!_.isUndefined(referralInfo) && !_.isUndefined(referralInfo.referralID)) {
            $state.go("admin.clients.client.survey.referral", {
              ReferralID: referralInfo.referralID,
              HideFirstMeeting: referralInfo.hideFirstMeeting,
              IsArchived: vm.IsArchived,
              ShowFinalDischarge: referralInfo.showFinalDischarge,
              IsCaseClosed: vm.IsCaseClosed
            });
          }
        }, function(error) {
          toaster.pop('error', "Could not retrieve the data", error.statusText);
        });
    }

    function CloseCase(_referralID) {
      ReferralsModals.CloseCase({
        ReferralID: _referralID
      })
      .then(function() {
        vm.LoadReferrals();
      });
    }

    function ImportReferralsModal() {
      ReferralsModals.ImportReferrals()
        .then(function(importedReferralsInfo) {
          vm.LoadReferrals();
        });
    }

    function setIndicator(Referral) {
      SurveysService.SetReferralIndicator(Referral.ReferralID,Referral.ColorIndicator);
      console.log('refColor',Referral.ColorIndicator);
      Referral.backgroundStyle = Referral.ColorIndicator!=null?{'background-color':Referral.ColorIndicator}:{} 
    }

    // function DeleteReferral(ReferralID) {
    //   vm.loading = true;
    //   SurveysService
    //     .DeleteReferral(ReferralID)
    //     .then(function(response) {
    //       vm.loading = false;
    //       vm.LoadReferrals();
    //     });
    // }
  }
})();
