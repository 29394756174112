
/* jshint -W097, -W033 */
(function () {
  'use strict';

  angular
      .module('app.shared')
      .directive('autoSubmitForm', autoSubmitForm)

  autoSubmitForm.$inject = ['$timeout', '$sce']

  function autoSubmitForm($timeout, $sce) {

    var directive = {
      replace: true,
      scope: {},
      template: '<form action="https://alpha.test.modirum.com/vpos/shophandlermpi" method="{{formData.redirectMethod}}">' +
          '<div ng-repeat="(key,val) in formData.redirectData">' +
          '<input type="hidden" name="{{key}}" value="{{val}}" />' +
          '</div>' +
          '</form>',
      link: function ($scope, element, $attrs) {
        $scope.$on($attrs['event'], function (event, data) {
         /* console.log('DATA', data);
          data.redirectUrl = $sce.trustAsUrl(data.redirectUrl),
          console.log('DATA2', data);*/
          $scope.formData = data;
          console.log('redirecting now!',element);
          var timer = $timeout(function () {
              element.submit();
              //$timeout.cancel(timer);
          },500);
        
        })
      }
    };

    return directive;
  }

}());