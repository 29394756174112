/* jshint -W097, -W033 */
(function() {
  'use strict';

  angular
    .module('app.shared')
    .run(PermissionsConfig);

  PermissionsConfig.$inject = ['configHelper', 'Permission', 'AuthService', 'RoleTypes'];

  function PermissionsConfig(configHelper, Permission, AuthService, RoleTypes) {

    Permission.defineRole('Anonymous', function() {
      return !AuthService.IsAuth;
    });

    _.each(RoleTypes, function(roletype) {
      Permission.defineRole(roletype, function() {
        return AuthService.IsAuth && _.contains(AuthService.Roles, roletype);
      })
    });

    configHelper.notifyConfig("Permissions", RoleTypes)

  }

}());
