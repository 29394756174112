  (function() {
    'use strict';

    angular
      .module('app.admin.configuration')
      .controller('OfflineConfigurationController', OfflineConfigurationController);

    OfflineConfigurationController.$inject = ['OfflineModals'];

    function OfflineConfigurationController(OfflineModals) {
      var vm = this;

      vm.OfflineMessage = OfflineMessage;

      activate();

      function activate() {}

      function OfflineMessage() {
        OfflineModals
          .OfflineMessage();
      }

    }

  }());
