(function() {
  'use strict';

  angular
    .module('app.admin.users')
    .constant('UserRoles', [
      "PHYSIO",
      "MODERATOR",
      "MANAGER",
      "RECEPTIONIST"
    ])

    // xrhsimopoieitai sto login gia na paroume thn lista me ton rolo pou exei kapoios user
    .constant('LoginRoles', [
      "MASTER",
      "PHYSIO",
      "MODERATOR",
      "MANAGER",
      "RECEPTIONIST"
    ])

    // h diafora me to apo pano einai oti xrhsimopoieitai sta surveys, pou yparxei kai o anonymous
    .constant('ReferralRoles', [
      "ANONYMOUS",
      "PHYSIO",
      "MODERATOR",
      "MANAGER",
      "RECEPTIONIST"
    ]);

}());
