/* jshint -W097, -W033 */
(function() {
  'use strict';

  angular
    .module('app.shared')
    .run(ApiConfig);

  ApiConfig.$inject = ["configHelper", 'api', 'ApiEndpoints'];

  function ApiConfig(configHelper, api, ApiEndpoints) {

    _.each(ApiEndpoints, function(ApiEndpoint) {
      api.add(ApiEndpoint);
    })

    configHelper.notifyConfig("Api", ApiEndpoints);

  }

}());
