(function() {
  'use strict';

  angular
    .module('app.admin.configuration')
    .controller('ConfigurationController', ConfigurationController);

  ConfigurationController.$inject = ['ConfigurationModals', 'ConfigurationService', '$state', 'LocalesConstant'];

  function ConfigurationController(ConfigurationModals, ConfigurationService, $state, LocalesConstant) {

    var vm = this;
    vm.SwitchDebug = SwitchDebug;
    vm.Modal = Modal;


    activate();

    function activate() {
      APP.Client.System.Debug = APP.Client.System.Debug === "TRUE";
      findLocale();
    }

    function SwitchDebug() {
      APP.Client.System.Debug = !APP.Client.System.Debug;
      ConfigurationService
        .Save({
          "System.Debug": APP.Client.System.Debug
        })
        .then(function() {
          activate();
        });
    }

    function Modal(modal) {
      ConfigurationModals[modal]()
        .then(function() {
          activate();
        });
    }

    function findLocale(){
      vm.Locale = _.find(LocalesConstant,function(locale){
        return locale.Code == APP.Client.System.Locale;
      });
    }

  }
}());
