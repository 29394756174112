/* jshint -W097, -W064 */
(function() {
  'use strict';

  angular
    .module('app.shared')
    .factory('ReportService', ReportService);

  ReportService.$inject = ['$q', '$http', '$filter', '$cookies', 'SurveysService'];

  function ReportService($q, $http, $filter, $cookies, SurveysService) {

    var service = {
      GetKPIBasicReport: GetKPIBasicReport,
      GetKPIAdvancedReport: GetKPIAdvancedReport,
      GetEfficiencyReport: GetEfficiencyReport,
      GetTypeOfInjuryReport: GetTypeOfInjuryReport,
      GetConditionTypeReport: GetConditionTypeReport,
      GetSameInjuryWithPreviousReferralReport: GetSameInjuryWithPreviousReferralReport,
      GetTypeOfInjuryPerDepartmentReport: GetTypeOfInjuryPerDepartmentReport,
      GetHighestTypeOfInjuryPerDepartmentReport: GetHighestTypeOfInjuryPerDepartmentReport,
      GetReferralsConfirmedWithin24HoursReport: GetReferralsConfirmedWithin24HoursReport,
      GetHandlingRequestReport: GetHandlingRequestReport,
      GetChronicityReport: GetChronicityReport,
      GetTypeOfWorkReport: GetTypeOfWorkReport,
      GetGeneralReport: GetGeneralReport,
    };

    return service;

    function GetKPIBasicReport(dateFrom, dateTo) {
      if(SurveysService._ClientID !== null) {
          return $http
            .get(APP.Service + "/reports/kpi_basic_report/?CID=" + SurveysService._ClientID + '&rl=' + $cookies.get('rl') + "&dateTimeFrom=" + dateFrom + "&dateTimeTo=" + dateTo)
            .then(function(results){
              return results.data;
            });
        }
        else {
          return $http
            .get(APP.Service + "/reports/kpi_basic_report/" + "?dateTimeFrom=" + dateFrom + "&dateTimeTo=" + dateTo)
            .then(function(results){
              return results.data;
            });
        }
    }

    function GetKPIAdvancedReport(dateFrom, dateTo) {
      if(SurveysService._ClientID !== null) {
          return $http
            .get(APP.Service + "/reports/kpi_advanced_report/?CID=" + SurveysService._ClientID + '&rl=' + $cookies.get('rl') + "&dateTimeFrom=" + dateFrom + "&dateTimeTo=" + dateTo)
            .then(function(results){
              return results.data;
            });
        }
        else {
          return $http
            .get(APP.Service + "/reports/kpi_advanced_report/" + "?dateTimeFrom=" + dateFrom + "&dateTimeTo=" + dateTo)
            .then(function(results){
              return results.data;
            });
        }
    }

    function GetEfficiencyReport(dateFrom, dateTo) {
      if(SurveysService._ClientID !== null) {
          return $http
            .get(APP.Service + "/reports/efficiency_report/?CID=" + SurveysService._ClientID + '&rl=' + $cookies.get('rl') + "&dateTimeFrom=" + dateFrom + "&dateTimeTo=" + dateTo)
            .then(function(results){
              return results.data;
            });
        }
        else {
          return $http
            .get(APP.Service + "/reports/efficiency_report/" + "?dateTimeFrom=" + dateFrom + "&dateTimeTo=" + dateTo)
            .then(function(results){
              return results.data;
            });
        }
    }

    function GetTypeOfInjuryReport(dateFrom, dateTo) {
      if(SurveysService._ClientID !== null) {
          return $http
            .get(APP.Service + "/reports/type_of_injury_report/?CID=" + SurveysService._ClientID + '&rl=' + $cookies.get('rl') + "&dateTimeFrom=" + dateFrom + "&dateTimeTo=" + dateTo)
            .then(function(results){
              return results.data;
            });
        }
        else {
          return $http
            .get(APP.Service + "/reports/type_of_injury_report/" + "?dateTimeFrom=" + dateFrom + "&dateTimeTo=" + dateTo)
            .then(function(results){
              return results.data;
            });
        }
    }

    function GetConditionTypeReport(dateFrom, dateTo) {
      if(SurveysService._ClientID !== null) {
          return $http
            .get(APP.Service + "/reports/condition_type_report/?CID=" + SurveysService._ClientID + '&rl=' + $cookies.get('rl') + "&dateTimeFrom=" + dateFrom + "&dateTimeTo=" + dateTo)
            .then(function(results){
              return results.data;
            });
        }
        else {
          return $http
            .get(APP.Service + "/reports/condition_type_report/" + "?dateTimeFrom=" + dateFrom + "&dateTimeTo=" + dateTo)
            .then(function(results){
              return results.data;
            });
        }
    }

    function GetSameInjuryWithPreviousReferralReport(dateFrom, dateTo) {
      if(SurveysService._ClientID !== null) {
          return $http
            .get(APP.Service + "/reports/same_injury_with_previous_referral_report/?CID=" + SurveysService._ClientID + '&rl=' + $cookies.get('rl') + "&dateTimeFrom=" + dateFrom + "&dateTimeTo=" + dateTo)
            .then(function(results){
              return results.data;
            });
        }
        else {
          return $http
            .get(APP.Service + "/reports/same_injury_with_previous_referral_report/" + "?dateTimeFrom=" + dateFrom + "&dateTimeTo=" + dateTo)
            .then(function(results){
              return results.data;
            });
        }
    }

    function GetTypeOfInjuryPerDepartmentReport(dateFrom, dateTo) {
      if(SurveysService._ClientID !== null) {
          return $http
            .get(APP.Service + "/reports/type_of_injury_per_department_report/?CID=" + SurveysService._ClientID + '&rl=' + $cookies.get('rl') + "&dateTimeFrom=" + dateFrom + "&dateTimeTo=" + dateTo)
            .then(function(results){
              return results.data;
            });
        }
        else {
          return $http
            .get(APP.Service + "/reports/type_of_injury_per_department_report/" + "?dateTimeFrom=" + dateFrom + "&dateTimeTo=" + dateTo)
            .then(function(results){
              return results.data;
            });
        }
    }

    function GetHighestTypeOfInjuryPerDepartmentReport(dateFrom, dateTo) {
      if(SurveysService._ClientID !== null) {
          return $http
            .get(APP.Service + "/reports/highest_type_of_injury_per_department_report/?CID=" + SurveysService._ClientID + '&rl=' + $cookies.get('rl') + "&dateTimeFrom=" + dateFrom + "&dateTimeTo=" + dateTo)
            .then(function(results){
              return results.data;
            });
        }
        else {
          return $http
            .get(APP.Service + "/reports/highest_type_of_injury_per_department_report/" + "?dateTimeFrom=" + dateFrom + "&dateTimeTo=" + dateTo)
            .then(function(results){
              return results.data;
            });
        }
    }

    function GetReferralsConfirmedWithin24HoursReport(dateFrom, dateTo) {
      if(SurveysService._ClientID !== null) {
          return $http
            .get(APP.Service + "/reports/referrals_confirmed_within_24_hours_report/?CID=" + SurveysService._ClientID + '&rl=' + $cookies.get('rl') + "&dateTimeFrom=" + dateFrom + "&dateTimeTo=" + dateTo)
            .then(function(results){
              return results.data;
            });
        }
        else {
          return $http
            .get(APP.Service + "/reports/referrals_confirmed_within_24_hours_report/" + "?dateTimeFrom=" + dateFrom + "&dateTimeTo=" + dateTo)
            .then(function(results){
              return results.data;
            });
        }
    }

    function GetHandlingRequestReport(dateFrom, dateTo) {
      if(SurveysService._ClientID !== null) {
          return $http
            .get(APP.Service + "/reports/handling_request_report/?CID=" + SurveysService._ClientID + '&rl=' + $cookies.get('rl') + "&dateTimeFrom=" + dateFrom + "&dateTimeTo=" + dateTo)
            .then(function(results){
              return results.data;
            });
        }
        else {
          return $http
            .get(APP.Service + "/reports/handling_request_report/" + "?dateTimeFrom=" + dateFrom + "&dateTimeTo=" + dateTo)
            .then(function(results){
              return results.data;
            });
        }
    }

    function GetChronicityReport(dateFrom, dateTo) {
      if(SurveysService._ClientID !== null) {
          return $http
            .get(APP.Service + "/reports/chronicity_report/?CID=" + SurveysService._ClientID + '&rl=' + $cookies.get('rl') + "&dateTimeFrom=" + dateFrom + "&dateTimeTo=" + dateTo)
            .then(function(results){
              return results.data;
            });
        }
        else {
          return $http
            .get(APP.Service + "/reports/chronicity_report/" + "?dateTimeFrom=" + dateFrom + "&dateTimeTo=" + dateTo)
            .then(function(results){
              return results.data;
            });
        }
    }

    function GetTypeOfWorkReport(dateFrom, dateTo) {
      if(SurveysService._ClientID !== null) {
          return $http
            .get(APP.Service + "/reports/type_of_work_report/?CID=" + SurveysService._ClientID + '&rl=' + $cookies.get('rl') + "&dateTimeFrom=" + dateFrom + "&dateTimeTo=" + dateTo)
            .then(function(results){
              return results.data;
            });
        }
        else {
          return $http
            .get(APP.Service + "/reports/type_of_work_report/" + "?dateTimeFrom=" + dateFrom + "&dateTimeTo=" + dateTo)
            .then(function(results){
              return results.data;
            });
        }
    }

    function GetGeneralReport(dateFrom, dateTo) {
      if(SurveysService._ClientID !== null) {
          return $http
            .get(APP.Service + "/reports/general_report/?CID=" + SurveysService._ClientID + '&rl=' + $cookies.get('rl') + "&dateTimeFrom=" + dateFrom + "&dateTimeTo=" + dateTo)
            .then(function(results){
              return results.data;
            });
        }
        else {
          return $http
            .get(APP.Service + "/reports/general_report/" + "?dateTimeFrom=" + dateFrom + "&dateTimeTo=" + dateTo)
            .then(function(results){
              return results.data;
            });
        }
    }
  }

}());
