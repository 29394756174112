/* jshint -W097, -W033 */
(function () {
  'use strict';

  angular
    .module('app.admin.users')
    .factory('UsersService', UsersService);

  UsersService.$inject = ['$data', '$q', '$http'];

  function UsersService($data, $q, $http) {
    var service = {
      GetUsers: GetUsers,
      GetUser: GetUser,
      SaveUser: SaveUser,
      DeleteUser: DeleteUser,
      UserChangePassword: UserChangePassword,
      ForceExpirePassword: ForceExpirePassword,
    }

    return service;

    function GetUsers() {
      return $data
        .list("users")
        .then(function (results) {
          return results;
        })
    }

    function GetUser(UserID) {
      return $data
        .get("users", {
          ID: UserID
        })
        .then(function (results) {
          return results;
        })
    }

    function SaveUser(user) {
      if (user.Id) {
        return $http
          .put(APP.Service + '/users/' + user.Id, user)
          .then(function (results) {
            return results.data;
          });
      } else {
        return $data
          .save("users", user)
          .then(function (results) {
            return results;
          })
      }
    }

    function UserChangePassword(user) {
      return $http.put(APP.Service + '/users/changepassword', {
          userid: user.Id,
          oldPass: user.oldPass,
          newPass: user.newPass
        })
        .then(PasswordChanged)
        .catch(PasswordChangeFailed);

      function PasswordChanged(response) {
        return response.data;
      }

      function PasswordChangeFailed(reason) {
        return $q.reject(reason);
      }
    }

    function DeleteUser(user) {
        return $http.delete(APP.Service + '/users/DeleteUser?id=' + user.Id)
    }

    function ForceExpirePassword(user) {
      return $http.get(APP.Service + '/users/' + user.Id + '/force-exp-password')
    }
  }
}());