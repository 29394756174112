(function() {
  'use strict';

  angular
    .module('app.shared.auth')
    .factory('AuthInterceptor', AuthInterceptor);

  AuthInterceptor.$inject = ['$q', '$injector', 'localStorageService'];

  function AuthInterceptor($q, $injector, localStorageService) {

    return {
      request: function(config) {
        config.headers = config.headers || {};
        var authData = localStorageService.get('AuthorizationData');
        if (authData) {
          config.headers.Authorization = 'Bearer ' + authData.Token;
        }
        return config;
      },
      responseError: function(rejection) {
        if (rejection.status === 401) {
          var AuthService = $injector.get('AuthService');
          AuthService.SignOut();
          var $state = $injector.get('$state');
          $state.go('auth.login', {
            ref: "au"
          });
        }
        return $q.reject(rejection);
      }
    };

  }
})();
