(function() {
  'use strict';

  angular
    .module('app.admin.clients')
    .controller('PortalManagersController', PortalManagersController);

  PortalManagersController.$inject = ['$stateParams', 'SurveysService', 'ClientsModals'];

  function PortalManagersController($stateParams, SurveysService, ClientsModals) {

    var vm = this;

    vm.LoadPortalManagers = LoadPortalManagers;
    vm.PortalManagerModal = PortalManagerModal;
    vm.DeletePortalManager = DeletePortalManager;

    activate();

    function activate() {
      SurveysService._ClientID = $stateParams.ID;
      vm.LoadPortalManagers();
    }

    function LoadPortalManagers() {
      vm.loading = true;
      SurveysService
        .GetPortalManagers()
        .then(function(PortalManagers) {
          vm.PortalManagers = PortalManagers;
        })
        .finally(function() {
          vm.loading = false;
        });
    }

    function PortalManagerModal(_PortalManagerID) {
      ClientsModals
        .PortalManager({
          PortalManagerID: _PortalManagerID
        })
        .then(function() {
          vm.LoadPortalManagers();
        });
    }

    function DeletePortalManager(PortalManagerID) {
      vm.loading = true;
       SurveysService
         .DeletePortalManager(PortalManagerID)
         .then(function(response) {
           vm.loading = false;
           vm.LoadPortalManagers();
         });
    }
  }
}());
