  (function() {
    'use strict';

    angular
      .module('app.admin.configuration')
      .controller('OfflineMessageController', OfflineMessageController);

    OfflineMessageController.$inject = ["$modalInstance", 'ConfigurationService'];

    function OfflineMessageController($modalInstance, ConfigurationService) {
      var vm = this;

      vm.Description = angular.copy(APP.Client.Offline.Description);
      vm.Save = Save;

      activate();

      function activate() {}

      function Save() {
        vm.loading = true;

        ConfigurationService
          .Save({
            "Offline.Description": vm.Description,
          })
          .then(function() {
            APP.Client.Offline.Description = angular.copy(vm.Description);
            $modalInstance.close();
          });

      }

    }

  }());
