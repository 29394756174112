(function() {
  'use strict';

  angular
    .module('app.admin.clients')
    .controller('ReferralsReferralController', ReferralsReferralController);

  ReferralsReferralController.$inject = [
    '$scope', '$stateParams','EnumsService', 'SurveysService', 'AuthService','ClientsService', 'ReferralType', 'Gender',
    'WorkStatus', 'toaster', 'SurveyPdfService', 'ConditionType', 'NatureOfWork', 'TypeOfReferral', 'AnsaJobTitle', 'AnsaWorkLocation'];

  function ReferralsReferralController(
    $scope, $stateParams,EnumsService, SurveysService, AuthService,ClientsService, ReferralType, Gender,
    WorkStatus, toaster, SurveyPdfService, ConditionType, NatureOfWork, TypeOfReferral, AnsaJobTitle, AnsaWorkLocation) {
    var vm = this;

    // enums
    vm.Gender = Gender;
    vm.ReferralType =EnumsService.GetClientEnum(ReferralType);
    vm.WorkStatus =EnumsService.GetClientEnum(WorkStatus);
    vm.ConditionType = ConditionType;
    vm.NatureOfWork = NatureOfWork;
    vm.TypeOfReferral = EnumsService.GetClientEnum(TypeOfReferral);
    vm.AnsaJobTitle = EnumsService.GetClientEnum(AnsaJobTitle);
    vm.AnsaWorkLocation = EnumsService.GetClientEnum(AnsaWorkLocation);

    // custom variables
    vm.ClientManagers = [];

    vm.ManagerListChanged = ManagerListChanged;
    vm.LoadSurvey = LoadSurvey;
    vm.LoadReferral = LoadReferral;

    vm.Submit = Submit;
    vm.ExportPDF = ExportPDF;

    activate();

    function activate() {
      vm.Back = $scope.$parent.vm.Back;
      vm.CanEditConfirm = AuthService.Roles[0] === 'RECEPTIONIST' ? true : false;
      vm.CanEditDraft = AuthService.Roles[0] === 'MODERATOR' ? true : false;
      vm.LoadSurvey();
    }

    function LoadSurvey() {
      vm.loading = true;
      SurveysService
        .GetSurveyByCode("referral", true)
        .then(function(CustomAndReferralSurvey) {
          vm.IsActive = CustomAndReferralSurvey.CustomSurvey.IsActive;
          vm.CanEdit = CustomAndReferralSurvey.CustomSurvey.CanEdit;
          vm.Questionnaire = vm.IsActive ? JSON.parse(CustomAndReferralSurvey.CustomSurvey.Configuration) : '';
          vm.Questionnaire.CanEdit = vm.CanEdit;
        }, function(error) {
          toaster.pop('error', "Could not retrieve the survey", error.statusText);
        })
        .finally(function() {
          vm.LoadReferral();
        });
    }

    function LoadReferral() {
      vm.Referral = {};
      vm.Referral.ReferralAbsenceDate = new Date();
      vm.Referral.ReferralReturnedToWorkDate = new Date();
      vm.Referral.ReferralExpectedDateOfReturn = new Date();

      SurveysService
        .GetReferral($stateParams.ReferralID)
        .then(function(referral) {
          vm.Referral = SurveysService.SetupReferralInfo(referral);
          vm.IsDraft = vm.Referral.IsDraft;
          vm.HasAppointmentReason = vm.Referral.ClosedWithoutReachingMinimumAppointmentsReason;

          // TODO: ean yparksei thema se afto to view tha prepei na ta fortono sto call. kanonika de tha eprepe na yparxei thema kathos eite apo to close calse,
          //       eite apo to discharge tha prepei na exei lhksei to opoio thema yphrxe. edo apla deixnoume thn apofash
          vm.CanDischargeOrCloseCase = true;
          vm.DisplayMessageForValidDischargeWithPainScoreForFullRecovery = false;
        }, function(error) {
          toaster.pop('error', "Could not retrieve the referral", error.statusText);
        })
        .finally(function() {
          getManagerList();
          vm.loading = false;
        });
    }

    function getManagerList() {
      SurveysService
        .GetPortalManagers()
        .then(function(portalManagers) {
          vm.ClientManagers = portalManagers;
          vm.selectedPortalManager = _.filter(vm.ClientManagers, function(portalManager){
            return portalManager.Person.Full == vm.Referral.ReferralManager.First &&
              portalManager.Person.Email.Address == vm.Referral.ReferralManager.Email.Address;
          })[0];

          // ean einai undefined tote kata 99% o manager exei svhstei apo th lista opote theloume na deiksoume to onoma tou kai na mporei na to allaksei xeirokinhta
          vm.DisplayManagerListInput = _.isUndefined(vm.selectedPortalManager);
        }, function(error) {
          toaster.pop('error', "Could not retrieve the manager list", error.statusText);
        });
    }

    function ManagerListChanged() {
      if(_.isUndefined(vm.Referral.ReferralManager)) {
        vm.Referral.ReferralManager = {};
        vm.Referral.ReferralManager.Email = {};
      }
      vm.Referral.ReferralManager.First = vm.selectedPortalManager.Person.Full;
      vm.Referral.ReferralManager.Email.Address = vm.selectedPortalManager.Person.Email.Address;
    }

    function Submit(exportToPdf) {
      
        //Ήταν προσαρμογή γιατί το ng-combodate έδινε ημερομηνία με timeoffset τώρα διορθώθηκε και δίνει καθαρή ημερομηνία χωρίς ώρα
        //πλέον δεν είναι χρήσιμο. 28/10/2017
      //console.log("ref1",vm.Referral.ReferralDateOfBirth);
      //vm.Referral.ReferralDateOfBirth = new Date(vm.Referral.ReferralDateOfBirth);
      //var userTimezoneOffset = vm.Referral.ReferralDateOfBirth.getTimezoneOffset() * 60000;
      //vm.Referral.ReferralDateOfBirth = new Date(vm.Referral.ReferralDateOfBirth.getTime() - userTimezoneOffset);
      //console.log("userTimezoneOffset",userTimezoneOffset);
      //console.log("ref2",vm.Referral.ReferralDateOfBirth);

      SurveysService
        .SubmitReferral(vm.Referral)
        .then(function(referral) {
          toaster.pop('success',"Referral was saved.", "Saved!");

          if(!angular.isUndefined(exportToPdf)) {
            vm.ExportPDF();
            vm.loading = false;
          }
          else {
            vm.LoadReferral();
          }
        }, function(error) {
          vm.loading = false;
          toaster.pop('error', "Could not save the data", error.statusText);
        });
    }

    function ExportPDF() {
      SurveyPdfService
        .download(vm.Questionnaire, vm.Referral);
    }
  }
})();
