/* jshint -W097, -W064 */
(function() {
  'use strict';

  angular
    .module('app.admin.configuration')
    .run(appRun);

  appRun.$inject = ['routerHelper'];

  function appRun(routerHelper) {
    routerHelper.configureStates(getStates());
  }

  function getStates() {
    return [{
      state: 'admin.manage.configuration',
      config: {
        abstract: true,
        url: '/configuration',
        template: '<ui-view/>',
      }
    }, {
      state: 'admin.manage.configuration.system',
      config: {
        url: '/system',
        controller: "ConfigurationController",
        templateUrl: 'src/com.admin/configuration/_layout.html'
      }
    }, {
      state: 'admin.manage.configuration.theme',
      config: {
        url: '/theme',
        controller: "ThemeConfigurationController",
        templateUrl: 'src/com.admin/configuration/theme/views/theme._layout.html'
      }
    }, {
      state: 'admin.manage.configuration.booking',
      config: {
        url: '/booking',
        controller: "BookingConfigurationController",
        templateUrl: 'src/com.admin/configuration/booking/views/_layout.html'
      }
    }, {
      state: 'admin.manage.configuration.offline',
      config: {
        url: '/offline',
        controller: "OfflineConfigurationController",
        templateUrl: 'src/com.admin/configuration/offline/views/_layout.html'
      }
    }, {
      state: 'admin.manage.configuration.payments',
      config: {
        url: '/payments',
        controller: "PaymentsConfigurationController",
        templateUrl: 'src/com.admin/configuration/payments/_layout.html'
      }
    }];
  }

}());
