(function() {
  'use strict';

  angular
    .module('app.admin.clients')
    .controller('ClientsOverviewController', ClientsOverviewController);

  ClientsOverviewController.$inject = ['$state', 'ClientsService', 'AuthService', 'ClientsModals', 'ClientStatuses'];

  function ClientsOverviewController($state, ClientsService, AuthService, ClientsModals, ClientStatuses) {
    var vm = this;

    vm.ClientStatuses = ClientStatuses;

    vm.LoadClients = LoadClients;
    vm.CreateClient = CreateClient;

    activate();

    function activate() {
      vm.CanCreateClient = AuthService.Roles[0] === 'MASTER' ? true : false;
      vm.LoadClients();
    }

    function LoadClients() {
      vm.loading = true;
      ClientsService
        .GetClients()
        .then(function(data) {
          vm.Clients = data;
        })
        .finally(function() {
          vm.loading = false;
        });
    }

    function CreateClient() {
      ClientsModals
        .Init()
        .then(function(client) {
          $state.go("admin.clients.client.dashboard", {
            ID: client.ID
          });
        });
    }

  }
})();
