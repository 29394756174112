(function() {
  'use strict';

  angular
    .module('app.shared')
    .constant('FlagsConstant', {
      "el-gr":"gr",
      "en-gb":"gb"
    });

}());
