(function() {
  'use strict';

  angular
    .module('app.admin.manager')
    .controller('ManagerDischargeController', ManagerDischargeController);

  ManagerDischargeController.$inject = [
    '$scope', '$stateParams','EnumsService', 'WorkStatus', 'AuthService', 'SurveysService',
    'toaster', 'ReferralType', 'Gender', 'SurveyPdfService',
  ];

  function ManagerDischargeController(
    $scope, $stateParams,EnumsService, WorkStatus, AuthService, SurveysService,
    toaster, ReferralType, Gender, SurveyPdfService,
    ClosedWithoutReachingMinimumAppointmentsReason
  ) {
    var vm = this;

    // enums
    vm.Gender = Gender;
    vm.ReferralType =EnumsService.GetClientEnum(ReferralType,APP.Domain);
    vm.WorkStatus = EnumsService.GetClientEnum(WorkStatus,APP.Domain);
    vm.SplitNum = 2;
    vm.ClosedWithoutReachingMinimumAppointmentsReason = ClosedWithoutReachingMinimumAppointmentsReason;

    vm.LoadSurvey = LoadSurvey;
    vm.LoadDischarge = LoadDischarge;

    vm.Submit = Submit;
    vm.ExportPDF = ExportPDF;
    vm.CustomFilter = CustomFilter;

    activate();

    function activate() {
      vm.Back = $scope.$parent.vm.Back;
      vm.LoadSurvey();
    }

    function LoadSurvey() {
      vm.loading = true;
      SurveysService
        .GetSurveyByCode("discharge", true)
        .then(function(CustomAndReferralSurvey) {
          vm.IsActive = CustomAndReferralSurvey.CustomSurvey.IsActive;
          vm.CanEdit = CustomAndReferralSurvey.CustomSurvey.CanEdit;
          vm.CustomQuestionnaire = vm.IsActive ? JSON.parse(CustomAndReferralSurvey.CustomSurvey.Configuration) : '';
          vm.Components = vm.IsActive ? SurveysService.ManageSurveyComponentsForView(vm.CustomQuestionnaire, vm.SplitNum) : '';
          vm.Questionnaire = JSON.parse(CustomAndReferralSurvey.ReferralSurvey.Configuration);
        }, function(error) {
          toaster.pop('error', "Could not retrieve the survey", error.statusText);
        })
        .finally(function() {
          vm.LoadDischarge();
        });
    }

    function LoadDischarge() {
      vm.Discharge = {};
      vm.Discharge.ReferralID = $stateParams.ID;
      vm.Discharge.DischargeDate = new Date();
      vm.Discharge.DischargeAbsenceDate = new Date();
      vm.Discharge.DischargeReturnedToWorkDate = new Date();
      vm.Discharge.DischargeExpectedDateOfReturn = new Date();
      vm.DisplaySaveAndSend = true;
      vm.WithoutAppointment = false;

      SurveysService
        .GetDischargeByReferralId($stateParams.ID, false)
        .then(function(dischargeWithInfo) {
          dischargeWithInfo = dischargeWithInfo.data;
          vm.Referral = SurveysService.SetupReferralInfo(dischargeWithInfo.Referral);

          // ean einai null den theloume na spasei to object pou ftiaksame tin arxh
          if(dischargeWithInfo.Discharge !== null) {
            vm.Discharge = dischargeWithInfo.Discharge;
            vm.CanDischarge = true;
            vm.CanDischargeOrCloseCase = true;
            vm.DisplaySaveAndSend = vm.Discharge.SurveyStatus == "NOT_SENT";
          }
          else{
            // orizoume thn timh sto min gia na min exei NaN sto init
            var _questions = SurveysService.GetSurveyQuestions(vm.CustomQuestionnaire, ["pain_score","more_sessions"]);
            vm.Discharge.DischargePain = _questions.pain_score.min;
            vm.Discharge.DischargeExtraSessionsNumber = _questions.more_sessions.min;
            vm.CanDischarge = dischargeWithInfo.CanDischarge;
            vm.CanDischargeOrCloseCase = dischargeWithInfo.CanDischargeOrCloseCase;
          }

          // afto einai se update kai eno afora to canDischarge mporei na allaksei, opote to ekana ksexoristo
          vm.CanDischarge = vm.CanDischarge && vm.CanDischargeOrCloseCase;

          vm.WithoutAppointment = firstmeetingWithInfo.WithoutAppointment;
          vm.DisplaySaveAndSend = vm.DisplaySaveAndSend && !vm.WithoutAppointment;

          var minimumAppointmentsToDischarge = APP.Client.Extra.MinimumAppointmentsToDischarge !== null ? APP.Client.Extra.MinimumAppointmentsToDischarge : 0;
          vm.Referral.ClosedWithoutReachingMinimumAppointments = dischargeWithInfo.AppointmentCount < minimumAppointmentsToDischarge && minimumAppointmentsToDischarge > 0 ? true : false;
          vm.HasAppointmentReason = vm.Referral.ClosedWithoutReachingMinimumAppointmentsReason;
        }, function(error) {
          toaster.pop('error', "Could not retrieve the data", error.statusText);
        })
        .finally(function() {
          vm.loading = false;
        });
    }

    function Submit(exportToPdf) {
      vm.loading = true;

      if(vm.Discharge.DischargeRequestMoreSessions && vm.Discharge.DischargeExtraSessionsNumber > 0) {
        vm.Discharge.IsArchived = false;
        // efoson exoume extra sessions request pername sto object ExtraSessions afta pou prepei gia na apothikeftoun
        vm.Discharge.ExtraSessions = [];
        vm.Discharge.ExtraSessions[0] = {};
        vm.Discharge.ExtraSessions[0].ExtraSessionAmount = vm.Discharge.DischargeExtraSessionsNumber;
        vm.Discharge.ExtraSessions[0].ExtraSessionStatus = ExtraSessionStatus[0];
      }


      if(!angular.isUndefined(vm.Discharge.DischargeGoalsAchieved) && vm.Discharge.DischargeGoalsAchieved) {
        vm.Discharge.DischargeGoalsFailedExplanation = null;
      }

      vm.Discharge.SurveyStatus = vm.SendEmail ? "SENT" : "NOT_SENT";

      if(vm.Discharge.SurveyStatus==="SENT" && (!vm.Discharge.DischargeRequestMoreSessions || vm.Discharge.DischargeExtraSessionsNumber == 0))
      {
        vm.Discharge.IsArchived = true;
      }

      SurveysService
        .SubmitDischarge(vm.Discharge)
        .then(function(discharge) {

          if(vm.Referral.ClosedWithoutReachingMinimumAppointments) {
            SurveysService
              .SubmitReferral(vm.Referral)
              .then(function(referral) {
                finishSubmit(exportToPdf);
              });
          }
          else {
            finishSubmit(exportToPdf);
          }

        }, function(error) {
          vm.loading = false;
          toaster.pop('error', "Could not save the data", error.statusText);
        });
    }

    // TODO: NA GINEI ENA SERVICE
    function CustomFilter() {
      // afora to pain recovery kai an epileksei to excellent progress
      if(vm.Discharge.DischargePain > 0 && vm.Referral.ClosedWithoutReachingMinimumAppointmentsReason === 'HAS_MADE_EXCELLENT_PROGRESS_AND_CAN_SELF_MANAGE') {
        vm.CanDischarge = false;
        vm.DisplayMessageForValidDischargeWithPainScoreForFullRecovery = true;
      }
      else if(vm.HasAppointmentReason === null && vm.CanDischargeOrCloseCase && vm.CanEdit) {
        vm.CanDischarge = true;
        vm.DisplayMessageForValidDischargeWithPainScoreForFullRecovery = false;
      }
    }

    function finishSubmit(exportToPdf) {
      vm.Submitted = true;
      toaster.pop('success',"Discharge was saved.", "Saved!");
      if(!angular.isUndefined(exportToPdf)) {
        vm.ExportPDF();
      }

      vm.LoadDischarge();
    }

    function ExportPDF() {
      SurveyPdfService
        .download(vm.CustomQuestionnaire, vm.Discharge, vm.Questionnaire, vm.Referral);
    }
  }
})();
