/* jshint -W097, -W033 */
(function() {
  'use strict';

  angular
    .module('app.admin.fixedcontent')
    .factory('FixedContentService', FixedContentService);

  FixedContentService.$inject = ['$http', '$uibModal', 'ContentConstant'];

  function FixedContentService($http, $uibModal, ContentConstant) {

    var service = {
      Load: Load,
      Modal: Modal,
    };

    return service;

    function Load(content, size) {
      return $http
        .get("assets/static/" + APP.Client.System.Language + "/" + content.View);
    }

    function Modal(alias, size) {
      var content = _.find(ContentConstant, function(item) {
        return item.Alias == alias;
      });

      service
        .Load(content)
        .then(function(response) {

          $uibModal.open({
            templateUrl: 'src/com.admin/fixedcontent/views/modal.html',
            size: size || "md",
            resolve: {
              Title: function() {
                return content.Title;
              },
              Content: function() {
                return response.data;
              }
            },
            controllerAs: "vm",
            controller: function(Title, Content) {
              var vm = this;
              vm.Title = Title;
              vm.Content = Content;
            }
          });
        });
    }
  }

}());
