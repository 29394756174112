/* jshint -W097, -W064 */
(function() {
  'use strict';

  angular
    .module('app.landing.referral')
    .run(appRun);

  appRun.$inject = ['routerHelper'];

  function appRun(routerHelper) {
    routerHelper.configureStates(getStates());
  }

  function getStates() {
    return [{
      state: "landing.referral",
      config: {
        url: '/referral',
        controller: "ReferralLandingController" ,
        controllerAs: "vm",
        templateUrl: 'src/com.landing/referral/views/referralform.html',
      }
    }, {
      state: "landing.redirect",
      config: {
        url: '/redirect',
        controller: "ReferralRedirectController",
        controllerAs: "vm",
        templateUrl: 'src/com.landing/referral/views/redirect.html',
      }
    }];
  }

}());
