(function() {
  'use strict';

  angular
    .module('app.landing.surveypdf')
    .controller('SurveyPdfLandingController', SurveyPdfLandingController);

  SurveyPdfLandingController.$inject = ['$stateParams', 'SurveyPdfService'];

  function SurveyPdfLandingController($stateParams, SurveyPdfService) {

    var vm = this;

    vm.SurveyPdf = null;
    vm.DownloadPdf = DownloadPdf;

    activate();

    function activate() {
      vm.EmailIsNotValid = false;
    }

    function DownloadPdf() {
      vm.loading = true;
      SurveyPdfService
        .getSurveyPdfByCodeAndEmail($stateParams.code, vm.PdfEmail)
        .then(function(validSurveyPdf) {
          vm.SurveyPdf = validSurveyPdf;

          vm.SurveyPdf.SurveySchema = validSurveyPdf.SurveySchema !== null ? JSON.parse(validSurveyPdf.SurveySchema.Configuration) : null;
          vm.SurveyPdf.ReferralSchema = validSurveyPdf.SurveySchema !== null ? JSON.parse(validSurveyPdf.ReferralSchema.Configuration) : null;

          SurveyPdfService
            .download(
              vm.SurveyPdf.SurveySchema,
              vm.SurveyPdf.SurveyData,
              vm.SurveyPdf.ReferralSchema,
              vm.SurveyPdf.ReferralData);
        })
        .finally(function() {
          vm.loading = false;
          vm.EmailIsNotValid = vm.SurveyPdf === null;
        });
    }

  }
}());
