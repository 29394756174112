/* jshint -W097, -W033 */
(function() {
  'use strict';

  angular
    .module('app.shared')
    .filter('htmlToPlaintext', htmlToPlaintext)

  htmlToPlaintext.$inject = []

  function htmlToPlaintext() {
    return function(text) {
      return text ? String(text).replace(/<[^>]+>/gm, '') : '';
    }
  }

}());
