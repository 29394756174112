/* jshint -W097, -W033 */
(function () {
  'use strict';

  angular
    .module('app.shared.auth')
    .factory('AuthModals', AuthModals);

  AuthModals.$inject = ['modalHelper'];

  function AuthModals(modalHelper) {
    return modalHelper.configureModals(getModals());

    function getModals() {
      return [{
        modal: "ForgotPassword",
        config: {
          templateUrl: 'src/com.shared/_common/modals/forgotpass.html',
          controller: "ForgotPassController",
        }

      }, {
        modal: "ResetExpPassword",
        config: {
          templateUrl: 'src/com.shared/auth/reset-exp-pass/reset-exp-pass.view.html',
          controller: "AuthResetExpPassController",
        }

      }];
    }
  }

}());