(function() {
  'use strict';

  angular
    .module('app.shared.auth')
    .controller('AuthRoleChoiceController', AuthRoleChoiceController);

  AuthRoleChoiceController.$inject = ['AuthService', '$stateParams', '$state', 'FixedContentService', 'LoginMessages', 'ClientService', 'LoginRoles'];

  function AuthRoleChoiceController(AuthService, $stateParams, $state, FixedContentService, LoginMessages, ClientService, LoginRoles) {
    var vm = this;

    vm.loading = false;
    vm.Proceed = Proceed;

    vm.Roles = LoginRoles;
    vm.Modal = Modal;

    activate();

    function activate() {
      vm.SelectedRole = false;
      switch ($stateParams.ref) {
        case "au": //Anauthorized
          vm.Alert = LoginMessages.ANAUTHORIZED;
          break;
        case "so": //Sign Out
          vm.Alert = LoginMessages.SIGNOUT;
          break;
        case "re": //registered
          vm.Alert = LoginMessages.REGISTERED;
          break;
        case "to": //registered
          vm.Alert = LoginMessages.TIMEOUT;
          break;
        default:
          break;
      }
    }

    function Proceed() {
      console.log(vm.Roles);
      console.log(AuthService.Roles);
      console.log(vm.SelectedRole);
      $cookies.put('rl', vm.SelectedRole);
      // $state.go("rd");
    }

    function FilterRoles() {
      _.map(AuthService.Roles, function(role) {
        if(LoginRoles.indexOf(role) != -1) {
          vm.Roles.push(role);
        }
      });
    }

    function Modal(alias) {
      FixedContentService.Modal(alias);
    }

  }

}());
