  (function() {
    'use strict';
    
    angular
      .module('app.admin.clients')
      .controller('EmailByConditionTypeFormController', EmailByConditionTypeFormController);

      EmailByConditionTypeFormController.$inject = ['ModalParams', '$modalInstance', 'ClientsService','ConditionType', 'toaster', '$filter'];

    function EmailByConditionTypeFormController(ModalParams, $modalInstance, ClientsService, ConditionType, toaster, $filter) {
      var vm = this;

      vm.Save = Save;
      vm.loading = false;

      activate();

      function activate() {
        vm.ConditionTypes = ConditionType;
        vm.conditionTypeEmails = ModalParams.ConditionTypeMails!=''? JSON.parse(ModalParams.ConditionTypeMails):{};
      }

      function Save() {
        vm.loading = true;
        ClientsService
          .SaveConfiguration({
            "ConditionTypeMails.data": JSON.stringify(vm.conditionTypeEmails)
          })
          .then(function() {
            vm.loading = false;

            toaster.pop('success',"Extra Configuration was saved.", "Saved!");
            $modalInstance.close();
          }, function(error) {
            toaster.pop('error', "Could not save the data", error.statusText);
          });
      }
    }
  }());
