/* jshint -W097, -W033 */
(function() {
  'use strict';

  angular
    .module('app.shared')
    .directive('slPrint', slPrint)

  slPrint.$inject = ['$compile', '$sce', '$templateRequest', '$templateCache'];

  function slPrint($compile, $sce, $templateRequest, $templateCache) {

    var directive = {
      // templateUrl: 'src/com.shared/directives/multilingualinput/multilingualinput.html',
      restrict: 'EA',
      // link: link,
      scope: {
        model: "="
      },
      link: function(scope, element, attrs) {

        element.bind('click', function(event) {

          $templateRequest(attrs.template)
            .then(function(html) {
              html = scope.$eval(html);

              scope.$watch(
                function(scope) {
                  // watch the 'compile' expression for changes
                  return scope.$eval(html);
                },
                function(value) {
                  // when the 'compile' expression changes
                  // assign it into the current DOM
                  element.html(value);

                  // compile the new DOM and link it to the current
                  // scope.
                  // NOTE: we only compile .childNodes so that
                  // we don't get into infinite loop compiling ourselves
                  $compile(element.contents())(scope);
                }
              );

              var iframe;
              //var html = $templateCache.get(attrs.template);
              var elm = document.createElement('iframe');
              
              elm.setAttribute('class', 'print-frame');
              elm.setAttribute('style', 'display: none;');

              element.parent().append(elm);

              iframe = element.parent().find('.print-frame')[0];

              var compiled = $compile(html)(scope);

              var doc;
              if (iframe.contentDocument) { // DOM
                doc = iframe.contentDocument;
              } else if (iframe.contentWindow) { // IE win
                doc = iframe.contentWindow.document;
              } else {
                alert('Wonder what browser this is... ' + navigator.userAgent);
              }

              doc.write(html);
              doc.close();

              if (window.navigator.userAgent.indexOf("MSIE") > 0) {
                iframe.contentWindow.document.execCommand('print', false, null);
              } else {
                iframe.contentWindow.focus();
                iframe.contentWindow.print();
              }
            });
        })
      }
    };

    return directive;
  }



}());
