(function() {
  'use strict';

  angular
    .module('app.admin.clients')
    .controller('AuditSettingsController', AuditSettingsController);

  AuditSettingsController.$inject = ['$state', '$stateParams','ClientsService', 'ModalParams', 'toaster', '$modalInstance'];

  function AuditSettingsController($state, $stateParams, ClientsService, ModalParams, toaster, $modalInstance) {
    var vm = this;

    vm.Save = Save;

    activate();

    function activate() {
      ClientsService._ClientID = $stateParams.ID;
      vm.AuditNumber = ModalParams.AuditNumber;
      vm.CommaSeperatedEmails = ModalParams.CommaSeperatedEmails;
    }

    function Save() {
      vm.loading = true;
      ClientsService
        .SaveConfiguration({
          "Audit.AuditNumber": vm.AuditNumber,
          "Audit.CommaSeperatedEmails": vm.CommaSeperatedEmails
        }, false)
        .then(function() {
          vm.loading = false;

          APP.Client.Audit.AuditNumber = vm.AuditNumber;
          APP.Client.Audit.CommaSeperatedEmails = vm.CommaSeperatedEmails;

          toaster.pop('success',"Terms of Use were saved.", "Saved!");
          $modalInstance.close();
        });
    }
  }
})();
