(function() {
  'use strict';

  angular
    .module('app.admin.manager')
    .controller('AppointmentController', AppointmentController);

  AppointmentController.$inject = ['$stateParams', '$modalInstance','EnumsService', 'SurveysService', 'toaster', 'AttendStatus', 'AppointmentType', 'ModalParams'];

  function AppointmentController($stateParams, $modalInstance,EnumsService, SurveysService, toaster, AttendStatus, AppointmentType, ModalParams) {
    var vm = this;

    // enums
    vm.AttendStatus = AttendStatus;
    vm.AppointmentType = EnumsService.GetClientEnum(AppointmentType); 

    vm.LoadAppointment = LoadAppointment;

    vm.Submit = Submit;

    activate();

    function activate() {
      if(APP.Client.Appointment.Times.constructor === Array) {
        // epeidi einai string thn ora pou to eftiaxna paizei to else, alla ean meta dosoume diaxeirish kai apothikevei sosta array de thelo na spasei to project
      }
      else {
        var times = JSON.parse("[" + APP.Client.Appointment.Times + "]");
        vm.Times = times[0];
      }

      vm.CustomQuestionnaire = ModalParams.CustomQuestionnaire;

      if(ModalParams.Appointment) {
        vm.LoadAppointment(ModalParams.Appointment);
      }
      else {
        vm.Appointment = {};
        vm.Appointment.ReferralID = ModalParams.ReferralID;
        vm.Appointment.AppointmentDate = new Date();
      }
    }

    function LoadAppointment(Appointment) {
      var hour = ('0' + moment.utc(Appointment.AppointmentDate).get("hour")).slice(-2);
      var minutes = ('0' + moment.utc(Appointment.AppointmentDate).get("minutes")).slice(-2);
      vm.AppointmentTime = hour + ":" + minutes;
      vm.Appointment = ModalParams.Appointment;
    }

    function Submit() {
      var appointmentDate = moment(vm.Appointment.AppointmentDate);

      vm.Appointment.AppointmentDate = moment.utc({
          year: appointmentDate.year(),
          month: appointmentDate.month(),
          day: appointmentDate.date()
      })
      .add(moment(vm.AppointmentTime, "HH:mm").hours(), 'h')
      .add(moment(vm.AppointmentTime, "HH:mm").minutes(), 'm')
      .toISOString();
      // .toISOString();

      // vm.Appointment.AppointmentDate = moment(vm.Appointment.AppointmentDate)
      //   .add(moment(vm.AppointmentTime, "HH:mm").hours(), 'h')
      //   .add(moment(vm.AppointmentTime, "HH:mm").minutes(), 'm');

      SurveysService
        .SubmitAppointment(vm.Appointment)
        .then(function(appointment) {
          toaster.pop('success',"Appointment was saved.", "Saved!");
          $modalInstance.close(appointment);
        }, function(error) {
          vm.loading = false;
          toaster.pop('error', "Could not save the data", error.statusText);
        });
    }
  }
})();
