(function() {
  'use strict';

  angular
    .module('app.admin', [

      'app.admin.clients',
      'app.admin.configuration',
      'app.admin.fixedcontent',
      'app.admin.manage',
      'app.admin.manager',
      'app.admin.menus',
      'app.admin.users',

    ]);
})();
