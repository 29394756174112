/* jshint -W097, -W033 */
(function() {
  'use strict';

  angular
    .module('app.shared')
    .run(RoutesConfig);

  RoutesConfig.$inject = ['configHelper', 'routerHelper'];

  function RoutesConfig(configHelper, routerHelper) {

    var _states = getStates();
    routerHelper.configureStates(_states, "/rd");

    configHelper.notifyConfig("Basic Routes", _.map(_states, function(_state) {
      return _state.state;
    }));

  }

  function getStates() {
    return [{
      state: 'rd',
      config: {
        url: "/rd",
        controller: function($state, AuthService) {
          if (!AuthService.IsAuth && APP.Domain === null) {
            $state.go("auth.login");
            return;
          }
          if (!AuthService.IsAuth && APP.Domain !== null) {
            $state.go(APP.States.Anonymous);
            return;
          }

          if (AuthService.IsAuth && APP.Domain !== null && AuthService.Roles[0] === "MANAGER") {
            $state.go(APP.States.Manager);
            return;
          }

          if (AuthService.IsAuth && APP.Domain !== null && AuthService.Roles[0] !== "MANAGER") {
            $state.go("auth.logout");
            return;
          }

          if (AuthService.IsAuth && APP.Domain === null && AuthService.Roles[0] === "MANAGER") {
            $state.go("auth.logout");
            return;
          }

          if (AuthService.IsAuth && APP.Domain === null && AuthService.Roles[0] !== "MANAGER") {
            $state.go(APP.States.Admin);
            return;
          }

        }
      }
    }, {
      state: 'rc',
      config: {
        url: "/rc",
        controller: function($state, AuthService) {
          if (!AuthService.IsAuth && APP.Domain === null) {
            $state.go("auth.login");
            return;
          }
          if (!AuthService.IsAuth && APP.Domain !== null) {
            $state.go(APP.States.Anonymous);
            return;
          }

          if (AuthService.IsAuth && APP.Domain !== null && AuthService.Roles[0] === "MANAGER") {
            $state.go("auth.rc");
            return;
          }

          if (AuthService.IsAuth && APP.Domain !== null && AuthService.Roles[0] !== "MANAGER") {
            $state.go("auth.logout");
            return;
          }

          if (AuthService.IsAuth && APP.Domain === null && AuthService.Roles[0] === "MANAGER") {
            $state.go("auth.logout");
            return;
          }

          if (AuthService.IsAuth && APP.Domain === null && AuthService.Roles[0] !== "MANAGER") {
            $state.go("auth.rc");
            return;
          }

        }
      }
    }, {
      state: 'error',
      config: {
        url: '/error?err',
        templateUrl: "src/com.shared/views/_common/error.html",
        params: {
          err: "GENERIC"
        },
        controller: function($scope, $stateParams, ErrorPages) {
          $scope.Err = ErrorPages[$scope.$stateParams.err];
        }
      }
    }];
  }

}());
