(function () {
  'use strict';

  angular
    .module('app')
    .controller('AuthResetExpPassController', AuthResetExpPassController);

  AuthResetExpPassController.$inject = ['$state', '$stateParams', 'AuthService', 'toaster', '$modalInstance'];

  function AuthResetExpPassController($state, $stateParams, AuthService, toaster, $modalInstance) {
    var vm = this;

    if (APP.Client.UserSettings) {
      vm.IntervMonths = APP.Client.UserSettings.PassIntervMonths;
    } else {
      vm.IntervMonths = "2";
    }
    vm.UserResetPassword = UserResetPassword;

    activate();

    function activate() {}


    function UserResetPassword() {

      vm.error = null

      if (!vm.OldPass) {
        vm.error = "Old password is required"
        return;
      }
      if (typeof vm.NewPass === "undefined" || vm.NewPass.length < 6) {
        vm.error = "Password has to be 6 characters or more"
        return;
      }
      if (vm.NewPass !== vm.NewPassRepeat) {
        vm.error = "Passwords must match"
        return;
      }

      vm.loading = true;
      AuthService
        .ResetExpPassword({
          OldPass: vm.OldPass,
          NewPass: vm.NewPass,
          IntervMonths: vm.IntervMonths,
        })
        .then(function () {
          vm.loading = false;
          $modalInstance.close("OK");
        }, function (error) {
          vm.loading = false;
          toaster.pop('error', "Could not reset the password", error.data.Message);
        });
    }
  }

}());