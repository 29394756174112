(function() {
  'use strict';

  angular
    .module('app.admin.clients')
    .controller('ImageitemsController', ImageitemsController);

  ImageitemsController.$inject = ['$stateParams', 'SurveysService', 'ClientsModals'];

  function ImageitemsController($stateParams, SurveysService, ClientsModals) {

    var vm = this;

    vm.LoadImageItems = LoadImageItems;
    vm.ImageItemModal = ImageItemModal;
    vm.DeleteImageItem = DeleteImageItem;

    activate();

    function activate() {
      SurveysService._ClientID = $stateParams.ID;
      vm.LoadImageItems();
    }

    function LoadImageItems() {
      vm.loading = true;
      SurveysService
        .GetImageItems()
        .then(function(imageItems) {
          vm.ImageItems = imageItems;
        })
        .finally(function() {
          vm.loading = false;
        });
    }


    function ImageItemModal(_imageItemID) {
      ClientsModals
        .ImageItem({
          ImageItemID: _imageItemID
        })
        .then(function() {
          vm.LoadImageItems();
        });
    }

    function DeleteImageItem(ImageItemID) {
      vm.loading = true;
       SurveysService
         .DeleteImageItem(ImageItemID)
         .then(function(response) {
           vm.loading = false;
           vm.LoadImageItems();
         });
    }
  }
}());
