(function() {
  'use strict';

  angular.module('app', [
    'app.admin',
    // 'app.master',
    'app.landing',
    'app.shared',
  ]);

}());
