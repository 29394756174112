/* jshint -W097, -W064 */
(function() {
  'use strict';

  angular
    .module('app.admin.configuration')
    .factory('ConfigurationService', ConfigurationService);

  ConfigurationService.$inject = ['$http'];

  function ConfigurationService($http) {
    var service = {
      Save: Save,
      UploadFile: UploadFile,
    };

    return service;

    function Save(data) {
      return $http
        .post(APP.Service + '/configuration', data)
        .then(function(results) {
          return results.data;
        });
    }

    function UploadFile(data) {
      return $http
        .post(APP.Service + '/configuration/upload', data)
        .then(function(results) {
          return results.data;
        });
    }

  }
}());
