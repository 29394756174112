(function() {
  'use strict';

  angular
    .module('app.shared.client')
    .factory('ClientInterceptor', ClientInterceptor);

  ClientInterceptor.$inject = ['$q'];

  function ClientInterceptor($q) {

    return {
      request: function(config) {
        config.headers = config.headers || {};
        if (APP.Domain != null) {
          config.headers["X-Domain"] = APP.Domain;
        }
        return config;
      }
    };

  }
}());
