/* jshint -W097, -W033 */
(function() {
  'use strict';

  angular
    .module('app.shared')
    .config(DatepickerConfig);

  DatepickerConfig.$inject = ['configHelperProvider','uibDatepickerConfig', 'uibDatepickerPopupConfig'];

  function DatepickerConfig(configHelperProvider, uibDatepickerConfig, uibDatepickerPopupConfig) {
    
    uibDatepickerPopupConfig.showButtonBar = false;
    uibDatepickerPopupConfig.datepickerPopup = 'mediumDate';
    uibDatepickerConfig.showWeeks = false;
    uibDatepickerConfig.startingDay = 1;

    configHelperProvider.$get().notifyConfig("Datepicker", {
      DP: uibDatepickerConfig,
      DPPU: uibDatepickerPopupConfig,
    });
  }


}());
