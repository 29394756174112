(function() {
  'use strict';

  angular
    .module('app.admin.clients')
    .controller('ReferralsCalendarController', ReferralsCalendarController);

  ReferralsCalendarController.$inject = ['$state', '$stateParams','SurveysService', 'AuthService'];

  function ReferralsCalendarController($state, $stateParams, SurveysService, AuthService) {
    var vm = this;

    vm.LoadCalendar = LoadCalendar;

    activate();

    function activate() {
      SurveysService._ClientID = $stateParams.ID;
      vm.LoadCalendar();
    }

    function LoadCalendar() {
      vm.CalendarOptions = SurveysService.CalendarOptions({
        loading: function(isLoading) {
          vm.loading_calendar = isLoading;
        }
      });

      vm.CalendarSources = [{
        // currentTimezone: APP.Client.System.Timezone,
        events: function(start, end, timezone, callback) {
          SurveysService
            .GetCalendarEvents({
              From: start.toISOString(),
              To: end.toISOString()
            })
            .then(function(appointments) {
              appointments = appointments.data;
              if (appointments.length) {
                vm.Appointments = appointments;
                callback(appointments);
              }
              vm.loading_calendar = false;
            }, function(error) {
              vm.loading_calendar = false;
              toaster.pop('error', "Could not retrieve the data", error.statusText);
            });
        }
      }];
    }
  }
})();
