(function () {
  'use strict';

  angular
    .module('app.admin.users')
    .controller('UsersController', UsersController);

  UsersController.$inject = ['UsersService', 'UsersModals', 'toaster'];

  function UsersController(UsersService, UsersModals, toaster) {

    var vm = this;

    vm.UserForm = UserForm;
    vm.UserResetPassword = UserResetPassword;
    vm.UserActivityLog = UserActivityLog;
    vm.UserDelete = UserDelete;
    vm.ForceExpirePassword = ForceExpirePassword;
    activate();

    function activate() {
      GetUsers();
    }

    function GetUsers() {
      vm.loading = true;
      return UsersService
        .GetUsers()
        .then(function (response) {
          _.each(response, function (user, key) {
            user.Roles = _.map(user.Roles, function (role) {
              return role.RoleId;
            });
          });

          vm.Users = response;
        })
        .finally(function () {
          vm.loading = false;
        });
    }

    function UserForm(UserID) {
      UsersModals
        .UserForm({
          UserID: UserID
        })
        .then(function () {
          // do nothing...
        })
        .finally(function () {
          GetUsers();
        });
    }

    function UserResetPassword(User) {
      UsersModals
        .UserChangePassword({
          User: User
        })
        .then(function () {
          //  GetUsers();
        });
    }

    function ForceExpirePassword(User) {
      UsersService
        .ForceExpirePassword(User)
        .then(function () {
          toaster.pop('success', "User password expired");
        })
    }

    function UserActivityLog(User) {}

    function UserDelete(User) {
      vm.loading = true;
      UsersService
        .DeleteUser(User)
        .then(function () {
            GetUsers();
          })
        .finally(function () {
          vm.loading = false;
        });
    }
  }

}());