(function() {
  'use strict';

  angular
    .module('app.admin.manager')
    .controller('ReferralImportController', ReferralImportController);

  ReferralImportController.$inject = ['$stateParams', '$modalInstance', 'SurveysService', 'toaster', 'ModalParams'];

  function ReferralImportController($stateParams, $modalInstance, SurveysService, toaster, ModalParams) {
    var vm = this;

    var supportedFileFormats = ["msg"];

    vm.SubmitFiles = SubmitFiles;

    activate();

    function activate() {

    }

    function SubmitFiles() {
      vm.loading = true;

      if(!ValidateFiles(vm.Files)) {
        toaster.pop(
          'error',"Error!",
          "Some of the chosen file formats are not supported. Please select files of the following formats <br /><br /> <strong>Supported formats:</strong> <em>" + SupportedFileFormatsForErrorMessage() + "</em>",
          3000,
          'trustedHtml'
        );
        vm.loading = false;
        return;
      }

      SurveysService
        .MessagesSend(vm.Files)
        .then(function(fileURIs) {

          var _fileURIs = [];
          angular.forEach(fileURIs, function(value, key) {
            _fileURIs.push(value.FileURI);
          });

          SurveysService
            .ImportReferrals(_fileURIs)
            .then(function(referralsInserted) {
              vm.loading = false;
              $modalInstance.close(referralsInserted);
            }, function(error) {
              toaster.pop('error',"Error!", "Message(s) could not be converted into Referral(s)");
              vm.loading = false;
            });
        });
    }

    function ValidateFiles(Files) {
      var allValid = true;

      angular.forEach(Files, function(file, index) {
        var ext = file.name.split(".").pop();
        if(supportedFileFormats.indexOf(ext) === -1) {
          allValid = false;
        }
      });

      return allValid;
    }

    function SupportedFileFormatsForErrorMessage() {
      var supportedFileFormatsForErrorMessage = [];

      angular.forEach(supportedFileFormats, function(supportedFileFormat, index) {
        supportedFileFormatsForErrorMessage.push("." + supportedFileFormat);
      });

      return supportedFileFormatsForErrorMessage.join(', ');
    }
  }
})();
