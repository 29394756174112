(function() {
  'use strict';

  angular
    .module('app.admin.clients')
    .controller('ReferralsSurveyController', ReferralsSurveyController);

  ReferralsSurveyController.$inject = ['$stateParams', 'AuthService', 'SurveysService'];

  function ReferralsSurveyController($stateParams, AuthService, SurveysService) {
    var vm = this;

    vm.LoadSurveys = LoadSurveys;
    vm.LoadReferral = LoadReferral;

    activate();

    function activate() {
      vm.Back = $stateParams.IsArchived ? "admin.clients.client.archived" : $stateParams.IsCaseClosed ? "admin.clients.client.closedcases" : "admin.clients.client.referrals";
      SurveysService._ClientID = $stateParams.ID;
      vm.HideFirstMeeting = $stateParams.HideFirstMeeting;
      vm.ShowFinalDischarge = $stateParams.ShowFinalDischarge;
      vm.LoadSurveys();
    }

    function LoadSurveys() {
      vm.loading = true;
      SurveysService
        .GetSurveys(true)
        .then(function(surveys) {
          _.each(surveys, function(survey, key) {
            survey.Route = "admin.clients.client.survey." + survey.code;
          });

          vm.Surveys = surveys;
        }, function(error) {
          toaster.pop('error', "Could not retrieve the survey", error.statusText);
        })
        .finally(function() {
          vm.LoadReferral();
        });
    }

    function LoadReferral() {
      SurveysService
        .GetReferralWithInfo($stateParams.ReferralID)
        .then(function(referral) {
        //  console.log("referral",referral);
          vm.Referral = referral;
            vm.HideFirstMeeting = referral.data.HideFirstMeeting;
            vm.ClientID = $stateParams.ID;
        }, function(error) {
          toaster.pop('error', "Could not retrieve the survey", error.statusText);
        })
        .finally(function() {
          vm.loading = false;
        });
    }

  }
})();
