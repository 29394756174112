(function() {
  'use strict';

  angular
    .module('app')
    .controller('AuthResetPassController', AuthResetPassController);

  AuthResetPassController.$inject = ['$state', '$stateParams', 'AuthService', 'toaster'];

  function AuthResetPassController($state, $stateParams, AuthService, toaster) {
    var vm = this;

    vm.ValidPassword = ValidPassword;
    vm.UserResetPassword = UserResetPassword;

    activate();

    function activate() {}

    function ValidPassword() {
        if(typeof vm.User === "undefined")
        {
          return false;
        }
        else if(vm.User !== null)
        {
          if(typeof vm.User.newPass === "undefined")
          {
            return false;
          }
          if(vm.User.newPass.length >= 6)
          {
            return true;
          }
          else
          {
            return false;
          }
        }
        else
        {
          return false;
        }
      }

   	function UserResetPassword(){
      vm.loading = true;
   		AuthService
   			.ResetPassword({
   				UserName: vm.User.UserName,
   				NewPassword: vm.User.newPass,
   				Token: $stateParams.token
   			})
   			.then(function() {
          vm.loading = true;
          $state.go('auth.login');
        }, function(error) {
          vm.loading = false;
          toaster.pop('error', "Could not reset the password", error.data.Message);
        });
   	}
  }

}());
