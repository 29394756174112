/* jshint -W097, -W033 */
(function() {
  'use strict';

  angular
    .module('app.admin.manage')
    .run(appRun);

  appRun.$inject = ['routerHelper'];

  function appRun(routerHelper) {
    routerHelper.configureStates(getStates());
  }

  function getStates() {
    return [{
      state: 'admin.manage',
      config: {
        abstract: true,
        url: '/manage',
        controller: 'ManageController',
        templateUrl: 'src/com.admin/manage/_layout.html'
      }
    }];
  }

}());
