(function() {
  'use strict';

  angular
    .module('app.admin.clients')
    .controller('ReferralSearchController', ReferralSearchController);

  ReferralSearchController.$inject = ['$stateParams', '$modalInstance', 'SurveysService', 'toaster'];

  function ReferralSearchController($stateParams, $modalInstance, SurveysService, toaster) {
    var vm = this;

    vm.SearchReferrals = SearchReferrals;
    vm.ReferralSelect = ReferralSelect;

    activate();

    function activate() {
      vm.IsArchived = $stateParams.IsArchived;
      vm.IsCaseClosed = $stateParams.IsCaseClosed;
      vm.SearchTerm = "";
    }

    function SearchReferrals() {
      if(vm.SearchTerm.length < 3) {
        toaster.pop('warning',"Search failed!", "Please enter a search term with at least 3 characters");
        return;
      }

      vm.loading = true;
      SurveysService
        .SearchReferrals(vm.SearchTerm, vm.IsArchived, vm.IsCaseClosed)
        .then(function(data) {
            vm.ReferralsWithInfo = data;
            vm.ClientID = $stateParams.ID;
        }, function(error) {
          toaster.pop('error', "Could not retrieve the data", error.statusText);
        })
        .finally(function() {
          vm.loading = false;
        });
    }

    function ReferralSelect(ReferralID, HideFirstMeeting, ShowFinalDischarge) {
      var referralInfo = {
        referralID: ReferralID,
        hideFirstMeeting: HideFirstMeeting,
        showFinalDischarge: ShowFinalDischarge
      };
      $modalInstance.close(referralInfo);
    }
  }
})();
