/* jshint -W097, -W033 */
(function() {
  'use strict';

  angular
    .module('app.shared')
    .factory('MultilingualService', MultilingualService)

  MultilingualService.$inject = ["LocaleMap"];

  function MultilingualService(LocaleMap) {

    var service = {
      Extend: Extend,
      Clean: Clean,
    }

    return service;

    function Extend(item) {
      var _ret = [];
      _.each(LocaleMap, function(locale,language) {

        if (typeof item === "undefined") {
          item = {};
        }
        if (typeof item.Translations === "undefined") {
          item.Translations = [];
        }

        var _i = _.find(item.Translations, function(translation) {
          return translation.Language.Code == locale;
        })

        if (_.isUndefined(_i)) {
          _ret.push({
            Language: {
              Code: locale
            }
          });
        } else {
          _i.Language = {
            Code: locale
          }
          _ret.push(_i);
        }

      })

      item.Translations = _ret;
      return item;
    }

    function Clean(_prop) {
      if (_.isArray(_prop)) {
        _prop = _.map(_prop, function(item) {
          return _cleanItem(item);
        });
      } else {
        _prop = _cleanItem(_prop);
      }
      return _prop;
    }

    function _cleanItem(item) {
      var _d = _.find(item.Translations, function(i) {
        return i.Language.Code == APP.Client.System.Locale;
      });
      _d = _.omit(_d, ['Language', 'ID']);
      _.extend(item, _d);
      return _.omit(item, 'Translations');
    }

  }
}());
