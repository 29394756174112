(function() {
  'use strict';

  angular
    .module('app.admin.clients')
    .run(appRun);

  appRun.$inject = ['routerHelper'];
  function appRun(routerHelper) {
    routerHelper.configureStates(getStates());
  }

  function getStates() {
    return [{
      state: 'admin.clients',
      config: {
        abstract: true,
        url: "/clients",
        template: '<ui-view/>',
      }
    }, {
      state: 'admin.clients.overview',
      config: {
        url: "/",
        templateUrl: 'src/com.admin/clients/overview/view.html',
        controller: "ClientsOverviewController",
      }
    }, {
      state: 'admin.clients.client',
      config: {
        abstract: true,
        url: "/:ID",
        params: {
          ID: null,
        },
        templateUrl: 'src/com.admin/clients/client/view.html',
        controller: "ClientController",
        resolve: {
          _LoadClient: _LoadClient,
          _LoadConfiguration: _LoadConfiguration
        }
      }
    }, {
      state: 'admin.clients.client.dashboard',
      config: {
        url: "/dashboard",
        templateUrl: 'src/com.admin/clients/dashboard/view.html',
        controller: "ClientDashboardController",
      }
    }, {
      state: 'admin.clients.client.surveys',
      config: {
        url: "/surveys",
        templateUrl: 'src/com.admin/clients/surveys/view.html',
        controller: "ClientSurveysController",
      }
    }, {
      state: 'admin.clients.client.configuration',
      config: {
        url: "/configuration",
        templateUrl: 'src/com.admin/clients/configuration/view.html',
        controller: "ClientConfigurationController",
      }
    }, {
      state: 'admin.manage.validation',
      config: {
        url: "/validate",
        templateUrl: 'src/com.admin/clients/validation/view.html',
        controller: "ClientValidateController",
      }
    }, {
      state: 'admin.clients.client.imageitems',
      config: {
        url: "/imageitems",
        templateUrl: 'src/com.admin/clients/imageitems/view.html',
        controller: "ImageitemsController",
      }
    }, {
      state: 'admin.clients.client.portalmanagers',
      config: {
        url: "/portalmanagers",
        templateUrl: 'src/com.admin/clients/portalmanagers/view.html',
        controller: "PortalManagersController",
      }
    }, {
      state: 'admin.clients.client.reports',
      config: {
        url: "/reports",
        templateUrl: 'src/com.admin/clients/reports/view.html',
        controller: "ReportsController",
      }
    }];
  }

  _LoadClient.$inject = ['ClientsService', 'toaster', '$stateParams'];
  function _LoadClient(ClientsService, toaster, $stateParams) {
    return ClientsService
      .GetClient($stateParams.ID)
      .then(function(client) {
        //console.log("client",client);
        ClientsService._ClientID = $stateParams.ID;
        ClientsService._ClientDomain = client.Domain;
      });
  }

  _LoadConfiguration.$inject = ['ClientsService', '_LoadClient'];
  function _LoadConfiguration(ClientsService, _LoadClient) {
    return ClientsService
      .GetConfiguration()
      .then(function(configuration) {
        APP.Client.Appointment = {};
        APP.Client.Business = {};
        APP.Client.Audit = {};
        APP.Client.Extra = {};
        APP.Client.Appointment.Duration = configuration["Appointment.Duration"];
        APP.Client.Appointment.Times = configuration["Appointment.Times"];
        APP.Client.Audit.AuditNumber = configuration["Audit.AuditNumber"];
        APP.Client.Audit.CommaSeperatedEmails = configuration["Audit.CommaSeperatedEmails"];
        APP.Client.Business.Duration = configuration["Business.Duration"];
        APP.Client.Business.HoursFrom = configuration["Business.HoursFrom"];
        APP.Client.Business.HoursTo = configuration["Business.HoursTo"];
        APP.Client.Extra.MinimumAppointmentsToDischarge = configuration["Extra.MinimumAppointmentsToDischarge"];
      });
  }

}());
