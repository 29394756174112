/* jshint -W097, -W064 */
(function() {
  'use strict';

  angular
    .module('app.shared.filemanager')
    .factory('FileManagerModals', FileManagerModals);

  FileManagerModals.$inject = ['modalHelper'];

  function FileManagerModals(modalHelper) {
    return modalHelper.configureModals(getModals());

    function getModals() {
      return [{
        modal: "FileForm",
        config: {
          templateUrl: 'src/com.shared/filemanager/views/form.modal.html',
          controller: "FileManagerFormController",
          size: "md",
        },
      }];
    }
  }

}());
