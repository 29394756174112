(function() {
  'use strict';

  angular
    .module('app.landing.referral')
    .controller('ReferralLandingController', ReferralLandingController);

  ReferralLandingController.$inject = [
    '$sce', '$state','$resource', '$cookies','EnumsService', 'SurveysService','ReferralType','Gender',
    'WorkStatus', 'toaster', 'ConditionType', 'NatureOfWork', 'TypeOfReferral', 'AnsaJobTitle', 'AnsaWorkLocation', 'MechanismOfInjury'];

  function ReferralLandingController(
    $sce, $state, $resource, $cookies,EnumsService, SurveysService, ReferralType, Gender,
      WorkStatus, toaster, ConditionType, NatureOfWork, TypeOfReferral, AnsaJobTitle, AnsaWorkLocation, MechanismOfInjury) {

    var vm = this;

    // enums
    vm.Gender = Gender;
    
    vm.ConditionType = ConditionType;
    vm.NatureOfWork = NatureOfWork;
    
    vm.AnsaJobTitle = AnsaJobTitle;
    vm.MechanismOfInjury = MechanismOfInjury;

    // custom variables
    vm.ClientManagers = [];

    vm.OhsListChanged = OhsListChanged;
    vm.ManagerListChanged = ManagerListChanged;
    vm.GetSurveyByCode = GetSurveyByCode;
    vm.Submit = Submit;

    vm.Disclaimer = "";
    vm.DisclaimerChecked = false;

    activate();

    function activate() {
      vm.ReferralType =EnumsService.GetClientEnum(ReferralType,APP.Domain);
      vm.ReferralType.sort();
      vm.WorkStatus =EnumsService.GetClientEnum(WorkStatus,APP.Domain);
      vm.TypeOfReferral =EnumsService.GetClientEnum(TypeOfReferral,APP.Domain);
      vm.AnsaWorkLocation = EnumsService.GetClientEnum(AnsaWorkLocation,APP.Domain);
      vm.AnsaJobTitle = EnumsService.GetClientEnum(AnsaJobTitle,APP.Domain);
    //   vm.ReferralType =  _.filter(ReferralType, function(type)
    //                                                     { var result = false;
    //                                                       var elements = type.split('#'); 
    //                                                       if(elements.length>1 && APP.Domain.toUpperCase()==elements[0].toUpperCase())
    //                                                       {
    //                                                         result =true;
    //                                                       }
                                                          
    //                                                       return result
    //                                                     });
    //   vm.ReferralType = vm.ReferralType.length == 0 ? _.filter(ReferralType, function(type){var elements = type.split('#'); return elements.length<=1; }):vm.ReferralType;

    //   vm.TypeOfReferral =  _.filter(TypeOfReferral, function(type)
    //   { var result = false;
    //     var elements = type.split('#'); 
    //     if(elements.length>1 && APP.Domain.toUpperCase()==elements[0].toUpperCase())
    //     {
    //       result =true;
    //     }
        
    //     return result
    //   });

    //  vm.TypeOfReferral = vm.TypeOfReferral.length == 0 ? _.filter(TypeOfReferral, function(type){var elements = type.split('#'); return elements.length<=1; }):vm.TypeOfReferral;

      vm.Disclaimer = $sce.trustAsHtml(APP.Client.Company.Disclaimer);
      vm.GetSurveyByCode();
    }

    function GetSurveyByCode() {
      vm.Referral = {};
      vm.Referral.ReferralAbsenceDate = new Date();
      vm.Referral.ReferralReturnedToWorkDate = new Date();
      vm.Referral.ReferralExpectedDateOfReturn = new Date();

      SurveysService
        .GetAnonymousSurvey()
        .then(function(referral) {
          referral = referral.data;
          vm.IsActive = referral.CustomSurvey.IsActive;
          vm.Questionnaire = vm.IsActive ? JSON.parse(referral.CustomSurvey.Configuration) : '';
          vm.Questionnaire.CanEdit = true;
          vm.CanEdit = true;
        }, function(error) {
          toaster.pop('error', "Could not retrieve the data", error.statusText);
        })
        .finally(function() {
          getManagerList();
          getOcupHealthNurseList();
        });
    }

    function getManagerList() {
      SurveysService
        .GetPortalManagers()
        .then(function(portalManagers) {
          vm.ClientManagers = portalManagers;
          vm.DisplayManagerListInput = vm.ClientManagers.length === 0;
        });
    }

    function ManagerListChanged() {
      if(_.isUndefined(vm.Referral.ReferralManager)) {
        vm.Referral.ReferralManager = {};
        vm.Referral.ReferralManager.Email = {};
      }
      vm.Referral.ReferralManager.First = vm.selectedPortalManager.Person.Full;
      vm.Referral.ReferralManager.Email.Address = vm.selectedPortalManager.Person.Email.Address;
    }

    function getOcupHealthNurseList() {
      SurveysService
        .GetOcupHealthNurseList()
        .then(function(OcupHealthNurses) {
          vm.clientOhs = OcupHealthNurses;
          vm.DisplayOhsListInput = vm.clientOhs.length === 0;
        });
    }

    function OhsListChanged() {
      if(_.isUndefined(vm.Referral.ReferralOcHealthNurse)) {
        vm.Referral.ReferralOcHealthNurse = {};
        vm.Referral.ReferralOcHealthNurse.Email = {};
      }
      vm.Referral.ReferralOcHealthNurse.First = vm.selectedOhs.Person.Full;
      vm.Referral.ReferralOcHealthNurse.Email.Address = vm.selectedOhs.Person.Email.Address;
    }

    function Submit() {
      vm.loading = true;
      var tempReferralType = vm.Referral.ReferralType;
      var tempTypeOfReferral = vm.Referral.TypeOfReferral;
      if(!_.isUndefined(vm.Referral.ReferralType) && vm.Referral.ReferralType.indexOf('#')>0)
      {
        vm.Referral.ReferralType = vm.Referral.ReferralType.substring(vm.Referral.ReferralType.indexOf('#')+1);
      }

      if(!_.isUndefined(vm.Referral.TypeOfReferral) && vm.Referral.TypeOfReferral.indexOf('#')>0)
      {
        vm.Referral.TypeOfReferral = vm.Referral.TypeOfReferral.substring(vm.Referral.TypeOfReferral.indexOf('#')+1);
      }
      
      SurveysService
        .SubmitReferral(vm.Referral)
        .then(function(referral) {
          vm.loading = false;

          if(APP.Client.Extra.PortalRedirectToCounter <= 0) {
            window.location.href = APP.Client.Extra.PortalRedirectTo;
          }
          else {
            window.location.href = "/redirect";
          }
        }, function(error) {
          vm.loading = false;
          toaster.pop('error', "Could not save the data", error.statusText);
        })
        .finally(function(){
            vm.Referral.ReferralType=tempReferralType;
            vm.Referral.TypeOfReferral= tempTypeOfReferral;
        });
    }
  }
}());
