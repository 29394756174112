/* jshint -W097, -W033 */
(function() {
  'use strict';

  angular
    .module('app.shared')
    .run(MwlconfirmConfig);

  MwlconfirmConfig.$inject = ['configHelper', 'confirmationPopoverDefaults'];

  function MwlconfirmConfig(configHelper, confirmationPopoverDefaults) {

    confirmationPopoverDefaults.confirmText = "Yes";
    confirmationPopoverDefaults.cancelText = "No";
    confirmationPopoverDefaults.confirmButtonType = "danger";
    confirmationPopoverDefaults.cancelButtonType = "default";
    confirmationPopoverDefaults.placement = "left";

    configHelper.notifyConfig("Popover Confirm", confirmationPopoverDefaults)

  }

}());
