/* jshint -W097, -W064 */
(function() {
  'use strict';

  angular
    .module('app.shared.filemanager')
    .controller("FileManagerFormController", FileManagerFormController);

  FileManagerFormController.$inject = ['$modalInstance', 'FileManagerService', 'ModalParams', 'toaster'];

  function FileManagerFormController($modalInstance, FileManagerService, ModalParams, toaster) {
    var vm = this;

    vm.Save = Save;

    activate();

    function activate() {
      vm.loading = true;
      FileManagerService
        .GetFile(ModalParams.FileID)
        .then(function(response) {
          vm.File = response;
        })
        .finally(function() {
          vm.loading = false;
        });
    }

    function Save() {
      return FileManagerService
        .SaveFile(vm.File)
        .then(function(response) {
          $modalInstance.close(response);
        })
        .catch(function(reason) {
          toaster.pop('error', reason, "Save Failed!");
          $modalInstance.dismiss(reason);
        });
    }
  }

}());
