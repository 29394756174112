(function() {
  'use strict';

  angular
    .module('app.admin.configuration')
    .controller('LocalizationFormController', LocalizationFormController);

  LocalizationFormController.$inject = ['ModalParams', '$modalInstance', 'ConfigurationService', 'toaster', 'LocalesConstant', 'TimezonesConstant', 'ClientService'];

  function LocalizationFormController(ModalParams, $modalInstance, ConfigurationService, toaster, LocalesConstant, TimezonesConstant, ClientService) {
    var vm = this;
    vm.Locales = LocalesConstant;
    vm.Locale = APP.Client.System.Locale;
    vm.Timezones = TimezonesConstant;
    vm.Timezone = APP.Client.System.Timezone;
    vm.Save = Save;
    vm.loading = false;
    activate();

    function activate() {}

    function Save() {
      vm.loading = true;

      var _data = {};

      _.mapObject(vm.Company, function(val, key) {
        _data["Company." + key] = val;
      });

      ConfigurationService
        .Save({
          "System.Language": vm.Language,
          "System.Locale": vm.Locale,
        })
        .then(function() {
          vm.loading = false;

          APP.Client.System.Locale = vm.Locale;
          APP.Client.System.Timezone = vm.Timezone;

          ClientService.LoadSettings();

          toaster.pop('success',"Localization were saved.", "Saved!");
          $modalInstance.close();
        });
    }

  }

}());
