(function() {
  'use strict';

  angular
    .module('app.admin.clients')
    .controller('ClientLocalizationFormController', ClientLocalizationFormController);

  ClientLocalizationFormController.$inject = ['ModalParams', '$modalInstance', 'ClientsService', 'toaster', 'LocalesConstant', 'TimezonesConstant'];

  function ClientLocalizationFormController(ModalParams, $modalInstance, ClientsService, toaster, LocalesConstant, TimezonesConstant) {
    var vm = this;
    vm.Locales = LocalesConstant;
    vm.Timezones = TimezonesConstant;
    vm.Save = Save;
    vm.loading = false;

    activate();

    function activate() {
      vm.Locale = ModalParams.Locale;
      vm.Timezone = ModalParams.Timezone;
    }

    function Save() {
      vm.loading = true;

      ClientsService
        .SaveConfiguration({
          "System.Language": vm.Language,
          "System.Locale": vm.Locale,
          "System.Timezone": vm.Timezone
        })
        .then(function() {
          vm.loading = false;

          toaster.pop('success',"Localization were saved.", "Saved!");
          $modalInstance.close();
        }, function(error) {
          toaster.pop('error', "Could not save the data", error.statusText);
        });
    }

  }

}());
