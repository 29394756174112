(function () {
  'use strict';

  angular
    .module('app.shared.auth')
    .controller('AuthLoginController', AuthLoginController);

  AuthLoginController.$inject = ['AuthService', '$stateParams', '$state', 'FixedContentService', 'LoginMessages', 'ClientService', 'AuthModals'];

  function AuthLoginController(AuthService, $stateParams, $state, FixedContentService, LoginMessages, ClientService, AuthModals) {
    var vm = this;

    vm.loading = false;
    vm.SignIn = SignIn;
    vm.ForgotPassword = ForgotPassword;

    vm.Modal = Modal;

    activate();

    function activate() {
      switch ($stateParams.ref) {
        case "au": //Anauthorized
          vm.Alert = LoginMessages.ANAUTHORIZED;
          break;
        case "so": //Sign Out
          vm.Alert = LoginMessages.SIGNOUT;
          break;
        case "re": //registered
          vm.Alert = LoginMessages.REGISTERED;
          break;
        case "to": //registered
          vm.Alert = LoginMessages.TIMEOUT;
          break;
        default:
          break;
      }

      if (APP.MockData) {
        vm.credentials = {
          username: APP.MockData.Username,
          password: APP.MockData.Password
        };
      }

    }

    function SignIn() {

      vm.loading = true;
      vm.Alert = false;
      AuthService
        .SignIn(vm.credentials)
        .then(function (response) {
          ClientService
            .GetSettings()
            .then(function () {
              ClientService.LoadSettings();
              $state.go("rd");
              // $state.go("rc");
            });

        }, function (response) {
          if (response.error) {
            vm.Alert = LoginMessages.AUTHERROR;
            vm.Alert.Details = response.error_description;
          } else {
            vm.Alert = LoginMessages.UNAVAILABLE;
          }

        })
        .finally(function () {
          vm.loading = false;
        });
    }

    function Modal(alias) {
      FixedContentService.Modal(alias);
    }

    function ForgotPassword() {
      AuthModals
        .ForgotPassword()
        .then(function () {
          console.log('Modal opened');
        });
    }

  }

}());