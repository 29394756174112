/* jshint -W097, -W064 */
(function() {
  'use strict';

  angular
    .module('app.landing')
    .run(appRun);

  appRun.$inject = ['routerHelper'];

  function appRun(routerHelper) {
    routerHelper.configureStates(getStates());
  }

  function getStates() {
    return [{
      state: "landing",
      config: {
        abstract: true,
        templateUrl: "src/com.landing/_common/views/_layout.html",
        controller: (function($state) {
          if (APP.Domain === null) {
            $state.go("rd");
          }
        })
      }
    }, {
      state: "landing.terms",
      config: {
        url: '/terms',
        templateUrl: 'src/com.landing/_common/views/terms.html'
      }
    }, {
      state: "landing.error",
      config: {
        url: '/error?err',
        params: {
          err: "generic"
        },
        controller: "LandingErrorController",
        templateUrl: 'src/com.landing/error/view.html',
      }
    }];
  }

}());
