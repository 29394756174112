(function() {
  'use strict';

  angular
  .module('app.admin.clients')
  .controller('PortalManagerEditController', PortalManagerEditController);

  PortalManagerEditController.$inject = ["ModalParams", 'SurveysService', 'ClientsService', '$modalInstance', 'toaster'];

  function PortalManagerEditController(ModalParams, SurveysService, ClientsService, $modalInstance, toaster) {

    var vm = this;

    vm.LoadPortalManager = LoadPortalManager;
    vm.Save = Save;

    activate();

    function activate() {
      if(!_.isUndefined(ModalParams.PortalManagerID)) {
        vm.LoadPortalManager();
      }
    }

    function LoadPortalManager() {
      vm.loading = true;
      SurveysService
        .GetPortalManagerById(ModalParams.PortalManagerID)
        .then(function(PortalManager) {
          vm.PortalManager = PortalManager;
        })
        .finally(function() {
          vm.loading = false;
        });
    }

    function Save() {
      SurveysService
        .SavePortalManager(vm.PortalManager)
        .then(function(PortalManager){
          toaster.pop('success', "Portal Manager was saved.", "Saved!");
          $modalInstance.close();
        });
    }
  }
}());
