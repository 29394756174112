/* jshint -W097, -W033 */
(function() {
  'use strict';

  angular
    .module('app.shared')
    .config(DynLocaleConfig);

  DynLocaleConfig.$inject = ['configHelperProvider', 'tmhDynamicLocaleProvider'];

  function DynLocaleConfig(configHelperProvider, tmhDynamicLocaleProvider) {
    var _pattern = null;
    if (APP.Debug) {
      _pattern = "/libs/angular-i18n/angular-locale_{{locale}}.js";
    } else {
      _pattern = "//cdnjs.cloudflare.com/ajax/libs/angular-i18n/1.4.7/angular-locale_{{locale}}.js";
    }
    tmhDynamicLocaleProvider.localeLocationPattern(_pattern)

    configHelperProvider.$get().notifyConfig("Angular Locale", _pattern);
  }

}());
