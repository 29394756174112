(function() {
  'use strict';

  angular
    .module('app.admin.clients', [
      'colorpicker.module',
      'ngCookies',
      'chart.js'
    ]);
})();
