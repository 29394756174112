/* jshint -W097, -W064 */
(function () {
    'use strict';

    angular
        .module('app.shared')
        .factory('EnumsService', EnumsService);

    EnumsService.$inject = ['$q', '$http', '$filter', '$cookies', 'ClientsService'];

    function EnumsService($q, $http, $filter, $cookies, ClientsService) {

        var service = {
            GetClientEnum: GetClientEnum
        };

        return service;

        function GetClientEnum(enumList,domain) {
            var _currentDomain = ClientsService._ClientDomain;
            if(!_.isUndefined(domain) && domain!= null && domain.length>0 )
            {
                _currentDomain = domain;
            }
            var clientEnum = [];
            //Ελέγχουμε αν υπάρχουν δηλωμένα types ειδικά για αυτό το συμβόλαιο
            var existsExplicitRefTypesForContract = _.find(enumList, function (type) {
                var result = false;
                var elements = type.split('#');
                if (elements.length > 1 && _currentDomain.toUpperCase() == elements[0].toUpperCase()) {
                    result = true;
                }
                return result
            });
            //Φιλτράρουμε τα types για το συμβόλαιο
            clientEnum = _.filter(enumList, function (type) {
                var result = false;
                var elements = type.split('#');
                if (elements.length > 1 && _currentDomain.toUpperCase() == elements[0].toUpperCase()) {
                    result = true;
                }
                else if (elements.length == 1 && !existsExplicitRefTypesForContract) {
                    result = true;
                }

                return result
            });

            //Αφαιρούμε από τα types την ενδειξη του συμβολαίου για να δουλέψει η προεπιλογή ngmodel με αυτό που επιστρέφει το api.
            clientEnum = _.map(clientEnum, function (type) {
                var result = '';
                var elements = type.split('#');
                if (elements.length > 1 && _currentDomain.toUpperCase() == elements[0].toUpperCase()) {
                    result = elements[1].toUpperCase();
                }
                else if (elements.length == 1 && !existsExplicitRefTypesForContract) {
                    result = type;
                }

                return result
            });

            return clientEnum;
        }

    }

}());
