(function() {
  'use strict';

  angular.module('app')

  .constant('ScheduleTypes', [{
    "label": "Every Week",
    "value": "WEEKDAY",
  }, {
    "label": "Once a Month",
    "value": "MONTHDAY",
  }])

  .constant('DurationTypes', [{
    "label": "45 mins",
    "value": 45,
  }, {
    "label": "60 mins",
    "value": 60,
  }, {
    "label": "120 mins",
    "value": 120,
  }])

  .constant('WeekDays', ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'])

  .constant('CalendarLanguages', {
    "greek": "el",
    "english": "en"
  });

}());
