(function() {
  'use strict';

  angular
    .module('app.admin.clients')
    .controller('ReferralsNotesController', ReferralsNotesController);

  ReferralsNotesController.$inject = ['$scope', '$stateParams','EnumsService', 'AuthService', 'SurveysService', 'toaster', 'ReferralType', 'Gender', 'WorkStatus', 'SurveyPdfService'];

  function ReferralsNotesController($scope, $stateParams,EnumsService, AuthService, SurveysService, toaster, ReferralType, Gender, WorkStatus, SurveyPdfService) {
    var vm = this;

    // enums
    vm.Gender = Gender;
    vm.ReferralType =EnumsService.GetClientEnum(ReferralType);
    vm.WorkStatus = EnumsService.GetClientEnum(WorkStatus);

    vm.LoadSurvey = LoadSurvey;
    vm.LoadManagerNotes = LoadManagerNotes;

    vm.Submit = Submit;
    vm.ExportPDF = ExportPDF;

    activate();

    function activate() {
      vm.Back = $scope.$parent.vm.Back;
      vm.LoadSurvey();
    }

    function LoadSurvey() {
      vm.loading = true;
      SurveysService
        .GetSurveyByCode("managernotes", true)
        .then(function(CustomAndReferralSurvey) {
          vm.IsActive = CustomAndReferralSurvey.CustomSurvey.IsActive;
          vm.CanEdit = CustomAndReferralSurvey.CustomSurvey.CanEdit;
          vm.CustomQuestionnaire = vm.IsActive ? JSON.parse(CustomAndReferralSurvey.CustomSurvey.Configuration) : '';
          vm.Questionnaire = JSON.parse(CustomAndReferralSurvey.ReferralSurvey.Configuration);
        }, function(error) {
          toaster.pop('error', "Could not retrieve the survey", error.statusText);
        })
        .finally(function() {
          vm.LoadManagerNotes();
        });
    }

    function LoadManagerNotes() {
      SurveysService
        .GetManagerNoteByReferralId($stateParams.ReferralID, false)
        .then(function(managernotesWithInfo) {
          managernotesWithInfo = managernotesWithInfo.data;
          vm.Referral = SurveysService.SetupReferralInfo(managernotesWithInfo.Referral);
          if(managernotesWithInfo.ManagerNote !== null){
            vm.ManagerNotes = managernotesWithInfo.ManagerNote;
          }
          else {
            vm.ManagerNotes = {};
            vm.ManagerNotes.ReferralID = $stateParams.ReferralID;
          }
        }, function(error) {
          toaster.pop('error', "Could not retrieve the manager notes", error.statusText);
        })
        .finally(function() {
          vm.loading = false;
        });
    }

    function Submit(exportToPdf) {
      SurveysService
        .SubmitManagerNote(vm.ManagerNotes)
        .then(function(managernotes) {
          toaster.pop('success',"Manager Notes were saved.", "Saved!");

          if(!angular.isUndefined(exportToPdf)) {
            vm.ExportPDF();
            vm.loading = false;
          }
          else {
            vm.LoadManagerNotes();
          }
        }, function(error) {
          vm.loading = false;
          toaster.pop('error', "Could not save the data", error.statusText);
        });
    }

    function ExportPDF() {
      SurveyPdfService
        .download(vm.CustomQuestionnaire, vm.ManagerNotes, vm.Questionnaire, vm.Referral);
    }
  }
})();
