/* jshint -W097, -W064 */
(function() {
  'use strict';

  angular
    .module('app.shared')
    .factory('SurveyPdfService', SurveyPdfService);

  SurveyPdfService.$inject = ['$q', '$http', '$filter', '$cookies', 'SurveysService'];

  function SurveyPdfService($q, $http, $filter, $cookies, SurveysService) {

    /*
      TODO:
        guid gia to name tou arxeiou
        den deixnei to extrasessionnumber sto pdf eno sto map yparxei
    */

    var pdfColumns = 2;

    var dynFunctions = {
      referral: _referralData,
      phonetriage: _phonetriageData,
      firstmeeting: _firstmeetingData,
      discharge: _dischargeData,
      managernotes: _managernotesData,
      finaldischarge: _dischargeData,
    };

    var service = {
      download: download,
      getSurveyPdfByCodeAndEmail: getSurveyPdfByCodeAndEmail
    };

    return service;

    function download(schema, data, referralSchema, referralData) {
      var cloneReferralSchema = angular.copy(referralSchema);
      var cloneReferralData = angular.copy(referralData);
      var cloneSchema = angular.copy(schema);
      var cloneData = angular.copy(data);

      getSettings()
        .then(function(settings) {
          var keyValuePairs = {};

          // ta tou referral data (pou deixnoume panta)
          if(!angular.isUndefined(cloneReferralSchema)) {
            angular.merge(keyValuePairs, _mapSurvey(cloneReferralSchema, cloneReferralData, ["description_of_current_injury"]));
          }

          // ta tou survey data
          angular.merge(keyValuePairs, _mapSurvey(cloneSchema, cloneData));

          _load(keyValuePairs, settings)
            .then(function(_schema) {
              pdfMake
                .createPdf(_schema)
                .download("TruePhysio" + ".pdf");
            });
      });
    }

    function _load(keyValuePairs, settings) {

      var _schema = {
        pageMargins: [ 20, 100, 20, 20 ],

        header: function(currentPage, pageCount) {
          return _header(currentPage, pageCount, settings);
        },

        footer: function(currentPage, pageCount) {
          return _footer(currentPage, pageCount, settings);
        },

        content: _container(keyValuePairs),

        styles: {
          header: {
      			alignment: 'center',
            bold: true,
            fontSize: 15
      		},
      		subheader: {
      			alignment: 'center',
            bold: true,
            fontSize: 12
      		},
      		sectionHeader: {
      			fontSize: 12,
      			bold: true,
      			margin: [0, 30, 0, 8]
      		},
      		tableContainer: {
      			margin: [0, 5, 0, 15],
      		},
      		tableHeader: {
      			bold: true,
      			alignment: 'right'
      		}
        },

        defaultStyle: {
          fontSize: 9,
        },
      };

      return $q.resolve(_schema);
    }

    function _header(currentPage, pageCount, settings) {
      if(settings.Base64ClientCompanyLogo !== "" && settings.Base64MasterCompanyLogo !== "") {
        return {
            margin: [20, 20, 20, 0],
            columns: [
              {
                  image: settings.Base64ClientCompanyLogo,
                  width: 120,
              },
              [
                  {
                      text: APP.Client.Company.Name,
                      style: 'header'
                  },
                  {
                      text: '- TRUE PHYSIO REFERRAL -',
                      style: 'subheader'
                  }
              ],
              {
                  image: settings.Base64MasterCompanyLogo,
                  width: 120,
              },
          ]
        };
      }
      else if(settings.Base64ClientCompanyLogo === "" && settings.Base64MasterCompanyLogo !== "") {
        return {
            margin: [20, 20, 20, 0],
            columns: [
              {
                  text: "",
                  width: 120,
              },
              [
                  {
                      text: APP.Client.Company.Name,
                      style: 'header'
                  },
                  {
                      text: '- TRUE PHYSIO REFERRAL -',
                      style: 'subheader'
                  }
              ],
              {
                  image: settings.Base64MasterCompanyLogo,
                  width: 120,
              },
          ]
        };
      }
      else if(settings.Base64ClientCompanyLogo !== "" && settings.Base64MasterCompanyLogo === "") {
        return {
            margin: [20, 20, 20, 0],
            columns: [
              {
                  image: settings.Base64ClientCompanyLogo,
                  width: 120,
              },
              [
                  {
                      text: APP.Client.Company.Name,
                      style: 'header'
                  },
                  {
                      text: '- TRUE PHYSIO REFERRAL -',
                      style: 'subheader'
                  }
              ],
              {
                  text: "",
                  width: 120,
              },
          ]
        };
      }
    }

    function _footer(currentPage, pageCount, settings) {
      return {
          text: currentPage.toString() + ' of ' + pageCount,
          alignment: 'center'
      };
    }

    function _container(keyValuePairs) {
      var _body = [];

      angular.forEach(keyValuePairs, function(keyValuePairGroup, alias) {
        // group label
        _body.push({
          text: keyValuePairGroup.title,
          style: 'sectionHeader'
        });

        // exoume N columns
        var _columns = [];
        var maxColumns = keyValuePairGroup.keyValuePairs.length >= pdfColumns ? pdfColumns : keyValuePairGroup.keyValuePairs.length;

        var _tableContainer = {
          style: 'tableContainer',
    			table: {
  	        widths: [],
    				body: []
    			},
    			layout: 'noBorders'
        };

        // ftiaxnoume N columns me max to poso exei oristhei. an einai ligotera, kratame to length ton items pou uparxoun se ena group
        // kathos kai to width tou table container
        for(var i = 0; i < maxColumns; i++) {
          _columns[i] = {
            table: {
                widths: [100, 100],
                body: []
            },
            layout: 'noBorders'
          };

          _tableContainer.table.widths.push('*');
        }

        var columnIndex = 0;
        angular.forEach(keyValuePairGroup.keyValuePairs, function(keyValuePair, index) {
          _columns[columnIndex].table.body.push([{
              text: keyValuePair.key,
              style: 'tableHeader'
          }, keyValuePair.value+""]);

          if(++columnIndex >= pdfColumns) {
            columnIndex = 0;
          }
        });

        _tableContainer.table.body.push(_columns);

        _body.push(_tableContainer);
      });

      return _body;
    }

    /* Check if valid for download */
    function getSurveyPdfByCodeAndEmail(Code, RecipientEmail) {
      return $http
        .get(APP.Service + "/surveypdfs?code=" + Code + "&email=" + RecipientEmail)
        .then(function(results){
          return results.data;
        });
    }

    /* Settings */
    function getSettings() {
      if(SurveysService._ClientID !== null) {
          return $http
            .get(APP.Service + "/surveypdfs/settings/?CID=" + SurveysService._ClientID + '&rl=' + $cookies.get('rl'))
            .then(function(results){
              return results.data;
            });
        }
        else {
          return $http
            .get(APP.Service + "/surveypdfs/settings")
            .then(function(results){
              return results.data;
            });
        }
    }

    /* Mapping Functions - Schema with Data */
    function _mapSurvey(schema, data, aliasesToIgnore) {
      var keyValuePairs = {};

      angular.forEach(schema.groups, function(group, groupAlias) {
        if(group.questions.length > 0 && groupAlias != "nogroup") {
          keyValuePairs[groupAlias] = {
            title: group.title,
            keyValuePairs: []
          };

          angular.forEach(group.questions, function(question, questionIndex) {
            if(angular.isUndefined(aliasesToIgnore) || (!angular.isUndefined(aliasesToIgnore) && aliasesToIgnore.indexOf(question.alias) === -1)) {
              var keyValues = dynFunctions[schema.alias](question.alias, data);
              angular.forEach(keyValues, function(keyValue, index) {
                keyValuePairs[groupAlias].keyValuePairs.push(keyValue);
              });
            }
          });

          // ean to group den exei katholou items tote mhn to deikseis
          if(keyValuePairs[groupAlias].keyValuePairs.length === 0) {
            delete keyValuePairs[groupAlias];
          }
        }
      });

      return keyValuePairs;
    }

    function _referralData(questionAlias, data) {
      var keyValues = [];

      switch(questionAlias) {
        case 'referral_type':
          keyValues.push({
            key: "Work Area",
            value: $filter('translate')(data.ReferralType) || " - "
          });
          break;
        case 'ansa_employee_job_title':
          keyValues.push({
            key: "Job Title (employee)",
            value: $filter('translate')(data.ReferralType) || " - "
          });
          break;
        case 'workplace':
          keyValues.push({
            key: "Workplace",
            value: data.ReferralServiceArea || " - "
          });
          break;
        case 'ansa_employee_work_location':
          keyValues.push({
            key: "Work Location (employee)",
            value: $filter('translate')(data.ReferralServiceAreaEnum) || " - "
          });
          break;
        case 'forename':
          keyValues.push({
            key: "Forename",
            value: data.ReferralPerson.First || " - "
          });
          break;
        case 'surname':
          keyValues.push({
            key: "Surname",
            value: data.ReferralPerson.Last || " - "
          });
          break;
        case 'home_address':
          keyValues.push({
            key: "Home Address",
            value: data.ReferralAddress.Street || " - "
          });
          break;
        case 'postcode':
          keyValues.push({
            key: "Postcode",
            value: data.ReferralAddress.Zip || " - "
          });
          break;
        case 'date_of_birth':
          keyValues.push({
            key: "Date of birth",
            value: $filter('date')(data.ReferralDateOfBirth, 'dd/MM/yyyy')  || " - "
          });
          break;
        case 'gender':
          keyValues.push({
            key: "Gender",
            value: $filter('translate')(data.ReferralPerson.Gender) || " - "
          });
          break;
        case 'telephone_number':
          keyValues.push({
            key: "Telephone number",
            value: data.ReferralPerson.Phone || " - "
          });
          break;
        case 'ansa_contactable_telephone_number':
          keyValues.push({
            key: "Contactable Telephone number",
            value: data.ReferralPerson.Phone || " - "
          });
          break;
        case 'email':
          keyValues.push({
            key: "Email",
            value: data.ReferralPerson.Email.Address || " - "
          });
          break;
        case 'payroll_number':
          keyValues.push({
            key: "Payroll number",
            value: data.PayrollNumber || " - "
          });
          break;
        case 'work_status':
          keyValues.push({
            key: "Work Status",
            value: $filter('translate')(data.WorkStatus) || " - "
          });

          if(data.WorkStatus == 'RETURNED_TO_WORK' || data.WorkStatus == 'OFF_WORK') {
            keyValues.push({
              key: "Date absence started",
              value: $filter('date')(data.ReferralAbsenceDate, 'dd/MM/yyyy') || " - "
            });
          }

          if(data.WorkStatus == 'RETURNED_TO_WORK') {
            keyValues.push({
              key: "Date returned to work",
              value: $filter('date')(data.ReferralReturnedToWorkDate, 'dd/MM/yyyy') || " - "
            });
          }

          if(data.WorkStatus == 'OFF_WORK') {
            keyValues.push({
              key: "Expected date of return",
              value: $filter('date')(data.ReferralExpectedDateOfReturn, 'dd/MM/yyyy') || " - "
            });

            keyValues.push({
              key: "Are Occupational Health aware of absence?",
              value: data.OccupationalHealthAwareOfAbsence ? 'Yes' : 'No' || " - "
            });
          }
          break;
        case 'job':
          keyValues.push({
            key: "Job/job role",
            value: data.ReferralProfession || " - "
          });
          break;
        case 'managers_name':
        case 'ansa_managers_name_list':
          keyValues.push({
            key: "Managers name",
            value: data.ReferralManager.First || " - "
          });
          break;
        case 'managers_phone_number':
          keyValues.push({
            key: "Managers telephone number",
            value: data.ReferralManager.Phone || " - "
          });
          break;
        case 'managers_email':
          keyValues.push({
            key: "Managers email",
            value: data.ReferralManager.Email.Address || " - "
          });
          break;
        case 'work_location':
          keyValues.push({
            key: "Work Location",
            value: data.ReferralWorkLocation || " - "
          });
          break;
        case 'description_of_current_injury':
          keyValues.push({
            key: "Description of Current Injury",
            value: data.ReferralDescription || " - "
          });
          break;
        case 'ansa_description_of_symptoms':
          keyValues.push({
            key: "Description of symptoms",
            value: data.ReferralDescription || " - "
          });
          break;
        case 'condition_type':
          keyValues.push({
            key: "Please state which of the following applies to this condition",
            value: $filter('translate')(data.ConditionType) || " - "
          });

          break;
        case 'nature_of_work':
          keyValues.push({
            key: "Nature of work",
            value: $filter('translate')(data.NatureOfWork) || " - "
          });

          break;
        case 'type_of_referral':
          keyValues.push({
            key: "Type of Referral",
            value: $filter('translate')(data.TypeOfReferral) || " - "
          });

          break;
      }

      return keyValues;
    }

    function _phonetriageData(questionAlias, data) {
      var keyValues = [];

      switch(questionAlias) {
        case 'assessment_date':
          keyValues.push({
            key: "Date of assessment",
            value: $filter('date')(data.PhoneTriageDate, 'dd/MM/yyyy') || " - "
          });
          break;
        case 'expected_discharge_date':
          keyValues.push({
            key: "Expected discharge date",
            value: $filter('date')(data.PhoneTriageExpectedDischargeDate, 'dd/MM/yyyy') || " - "
          });
          break;
        case 'doctor':
          keyValues.push({
            key: "Assessor",
            value: data.PhoneTriageDoctor || " - "
          });
          break;
        case 'seen_gp':
          keyValues.push({
            key: "Seen by GP",
            value: data.PhoneTriageGP ? 'Yes' : 'No' || " - "
          });
          break;
        case 'fit_note':
          keyValues.push({
            key: "FIT note",
            value: data.PhoneTriageFIT ? 'Yes' : 'No' || " - "
          });

          if(data.PhoneTriageFIT) {
            keyValues.push({
              key: "FIT note",
              value: data.PhoneTriageHasReturnPlan ? 'Yes' : 'No' || " - "
            });
          }

          if(data.PhoneTriageHasReturnPlan) {
            keyValues.push({
              key: "FIT note",
              value: data.PhoneTriageReturnPlan || " - "
            });
          }
          break;
        case 'work_status':
          keyValues.push({
            key: "Working status",
            value: $filter('translate')(data.WorkStatus) || " - "
          });

          if(data.WorkStatus == 'RETURNED_TO_WORK' || data.WorkStatus == 'OFF_WORK') {
            keyValues.push({
              key: "Date absence started",
              value: $filter('date')(data.PhoneTriageAbsenceDate, 'dd/MM/yyyy') || " - "
            });
          }

          if(data.WorkStatus == 'RETURNED_TO_WORK') {
            keyValues.push({
              key: "Date returned to work",
              value: $filter('date')(data.PhoneTriageReturnedToWorkDate, 'dd/MM/yyyy') || " - "
            });
          }

          if(data.WorkStatus == 'OFF_WORK') {
            keyValues.push({
              key: "Expected date of return",
              value: $filter('date')(data.PhoneTriageExpectedDateOfReturn, 'dd/MM/yyyy') || " - "
            });

            keyValues.push({
              key: "Are Occupational Health aware of absence?",
              value: data.OccupationalHealthAwareOfAbsence ? 'Yes' : 'No' || " - "
            });
          }
          break;
        case 'diagnosis':
          keyValues.push({
            key: "Possible diagnosis",
            value: $filter('translate')(data.Diagnosis)  || " - "
          });

          if(data.Diagnosis == 'PLEASE_STATE') {
            keyValues.push({
              key: "",
              value: data.PhoneTriageDiagnosisOther || " - "
            });
          }

          if(data.AltDiagnosis !== null) {
            keyValues.push({
              key: "Second Possible diagnosis",
              value: $filter('translate')(data.AltDiagnosis)  || " - "
            });

            if(data.AltDiagnosis == 'PLEASE_STATE') {
              keyValues.push({
                key: "",
                value: data.PhoneTriageAltDiagnosisOther || " - "
              });
            }
          }
          break;
        case 'triage_findings':
          keyValues.push({
            key: "Triage assessment impression",
            value: data.PhoneTriageFindings || " - "
          });
          break;
        case 'tasks_avoid':
          keyValues.push({
            key: "Tasks to avoid",
            value: data.PhoneTriageAvoid || " - "
          });
          break;
        case 'tasks_cando':
          keyValues.push({
            key: "Tasks can do",
            value: data.PhoneTriageCando || " - "
          });
          break;
        case 'no_treatment_recovery':
          keyValues.push({
            key: "Average Days of absence without treatment",
            value: data.PhoneTriageNoTreatment || " - "
          });
          break;
        case 'actionplans':
          keyValues.push({
            key: "Action plan",
            value: data.PhoneTriageActionplans || " - "
          });
          break;
        case 'proposedmanagement':
          keyValues.push({
            key: "Proposed Management",
            value: data.ProposedManagement || " - "
          });
          break;
        case 'comments':
          keyValues.push({
            key: "General comments",
            value: data.PhoneTriageComments || " - "
          });
          break;
        case 'injury_related':
          keyValues.push({
            key: "Is injury related",
            value: data.IsInjuryRelated ? 'Yes' : 'No' || " - "
          });
          break;
        case 'pain_score':
          keyValues.push({
            key: "Pain score (VAS)",
            value: data.PhoneTriagePain
          });
          break;
        case 'information_to_manager_and_patient':
          keyValues.push({
            key: "Information to Manager and Patient",
            value: data.PhoneTriageInformationToManagerAndPatient || " - "
          });
          break;
        case 'condition_type':
          keyValues.push({
            key: "Please state which of the following applies to this condition",
            value: $filter('translate')(data.ConditionType) || " - "
          });

          break;
        case 'nature_of_work':
          keyValues.push({
            key: "Nature of work",
            value: $filter('translate')(data.NatureOfWork) || " - "
          });

          break;
        case 'informed_consent_gained_to_inform':
          keyValues.push({
            key: "Informed consent gained from employee to inform Occupational Health / Industrial Hygiene",
            value: data.InformedConsentGainedToInform  ? 'Yes' : 'No' || " - "
          });

          break;
        case 'informed_consent_gained_to_assess_and_treat':
          keyValues.push({
            key: "Informed consent gained from employee to assess and treat",
            value: data.InformedConsentGainedToAssessAndTreat  ? 'Yes' : 'No' || " - "
          });

          break;
        case 'informed_consent_gained_of_consequences':
          keyValues.push({
            key: "Consent to inform patient of any potential consequences of withdrawing consent, that their employer will be informed of their withdrawal of consent and may make fitness for work decisions without the results of the physiotherapy assessment",
            value: data.InformedConsentConsequences  ? 'Yes' : 'No' || " - "
          });

          break;
        case 'informed_consent_gained_of_right_withdraw':
          keyValues.push({
            key: "Consent to inform patient of their right to withdraw consent to participate in the assessment or in any part of the assessment and for the report to be sent",
            value: data.InformedConsentRightWithDraw  ? 'Yes' : 'No' || " - "
          });

          break;
        case 'informed_consent_gained_of_right_report':
          keyValues.push({
            key: "Consent to inform patient of right to request to see and comment on the report before it is sent, to whom the report arising from the assessment will be sent to, and how they can access a copy",
            value: data.InformedConsentRightReport  ? 'Yes' : 'No' || " - "
          });

          break;
        case 'informed_consent_gained_of_limit_confidentiality':
          keyValues.push({
            key: "Consent to inform patient of limits to confidentiality and what information is to be shared with whom (i.e. medical or non-medical such as Human Resources or Health and Safety)",
            value: data.InformedConsentLimitConfidentiality  ? 'Yes' : 'No' || " - "
          });

          break;
        case 'risk_asses':
          keyValues.push({
            key: "Manual Handling Risk Assessment",
            value: data.PhoneTriageRiskasses ? 'Yes' : 'No'
          });
          break;
        case 'dse_asses':
          keyValues.push({
            key: "DSE Assessment",
            value: data.PhoneTriageDseasses ? 'Yes' : 'No'
          });

          if(data.PhoneTriageDseasses) {
            keyValues.push({
              key: "DSE Assessment",
              value: $filter('translate')(data.PhoneTriageAdvanced) || " - "
            });
          }
          break;
        case 'body_region_of_injury':
          keyValues.push({
            key: "Body region of injury",
            value: data.BodyRegionOfInjury || " - "
          });
          break;
          
        case 'caused_by_accident':
            keyValues.push({
                key: "Was the condition caused by an accident at work?",
                value: data.CausedByAccident ? 'Yes' : 'No'
            });
            break;
        case 'caused_by_ergonomic_factors':
              keyValues.push({
                  key: "Do you (Physio) or the employee believe the condition was caused by ergonomic factors at work?",
                  value: data.CausedByErgonomicFactors ? 'Yes' : 'No'
              });
            break;
        case 'is_aggravated':
              keyValues.push({
                  key: "Is the condition aggravated (made worse) by work tasks?",
                  value: data.IsAggravated ? 'Yes' : 'No'
              });
            break;
        case 'initiation':
              keyValues.push({
                  key: "Was your pain/problem initiated by a specific event in time?",
                  value: data.InitiatedTime ? 'Yes' : 'No'
              });
            break;
        case 'has_gp_seen':
            keyValues.push({
                key: "Has the patient seen their GP regarding their symptoms? (If so when and where?)",
                value: data.SeenGP
            });
            break;
        case 'assessment_findings':
              keyValues.push({
                  key: "Triage assessment findings (History, symptoms, and aggravating/easing factors. Avoid medical jargon and abbreviations). Include ACTIVITIES OUTSIDE OF WORK here and their relevance to the condition.",
                  value: data.AssessmentFindings
              });
            break;
        case 'triage_advice':
              keyValues.push({
                  key: "Triage advice given for managing at work and for ADL's.",
                  value: data.TriageAdvice
              });
            break;
        case 'proposed_plan':
            keyValues.push({
                key: "Proposed plan to return to work OR useful advice for modifications, rest breaks at work. Please detail amendments, requirements or restrictions.",
                value: data.ReturnProposedPlan
            });
            break;
        case 'handson_info':
            keyValues.push({
                key: "If applicable please detail relevant information for 'hands-on' physiotherapy regarding location, therapist, any preferences or requirements for access/gender of treating therapist etc.",
                value: data.HandsonInfo
            });
            break;
      }

      return keyValues;
    }

    function _firstmeetingData(questionAlias, data) {
      var keyValues = [];

      switch(questionAlias) {
        case 'assessment_date':
          keyValues.push({
            key: "Date of assessment",
            value: $filter('date')(data.FirstMeetingDate, 'dd/MM/yyyy') || " - "
          });
          break;
        case 'expected_discharge_date':
          keyValues.push({
            key: "Expected discharge date",
            value: $filter('date')(data.FirstMeetingExpectedDischargeDate, 'dd/MM/yyyy') || " - "
          });
          break;
        case 'doctor':
          keyValues.push({
            key: "Assessor",
            value: data.FirstMeetingDoctor || " - "
          });
          break;
        case 'seen_gp':
          keyValues.push({
            key: "Seen by GP",
            value: data.FirstMeetingGP ? 'Yes' : 'No' || " - "
          });
          break;
        case 'fit_note':
          keyValues.push({
            key: "FIT note",
            value: data.FirstMeetingFIT ? 'Yes' : 'No' || " - "
          });

          if(data.FirstMeetingFIT) {
            keyValues.push({
              key: "FIT note",
              value: data.FirstMeetingHasReturnPlan ? 'Yes' : 'No' || " - "
            });
          }

          if(data.FirstMeetingHasReturnPlan) {
            keyValues.push({
              key: "FIT note",
              value: data.FirstMeetingReturnPlan || " - "
            });
          }
          break;
        case 'work_status':
          keyValues.push({
            key: "Working status",
            value: $filter('translate')(data.WorkStatus) || " - "
          });

          if(data.WorkStatus == 'RETURNED_TO_WORK' || data.WorkStatus == 'OFF_WORK') {
            keyValues.push({
              key: "Date absence started",
              value: $filter('date')(data.FirstMeetingAbsenceDate, 'dd/MM/yyyy') || " - "
            });
          }

          if(data.WorkStatus == 'RETURNED_TO_WORK') {
            keyValues.push({
              key: "Date returned to work",
              value: $filter('date')(data.FirstMeetingReturnedToWorkDate, 'dd/MM/yyyy') || " - "
            });
          }

          if(data.WorkStatus == 'OFF_WORK') {
            keyValues.push({
              key: "Expected date of return",
              value: $filter('date')(data.FirstMeetingExpectedDateOfReturn, 'dd/MM/yyyy') || " - "
            });
          }
          break;
        case 'diagnosis':
          keyValues.push({
            key: "Possible diagnosis",
            value: $filter('translate')(data.Diagnosis)  || " - "
          });

          if(data.Diagnosis == 'PLEASE_STATE') {
            keyValues.push({
              key: "",
              value: data.FirstMeetingDiagnosisOther || " - "
            });
          }

          if(data.AltDiagnosis !== null) {
            keyValues.push({
              key: "Second Possible diagnosis",
              value: $filter('translate')(data.AltDiagnosis)  || " - "
            });

            if(data.AltDiagnosis == 'PLEASE_STATE') {
              keyValues.push({
                key: "",
                value: data.FirstMeetingAltDiagnosisOther || " - "
              });
            }
          }
          break;
        case 'clinical_findings':
          keyValues.push({
            key: "Clinical assessment findings",
            value: data.FirstMeetingFindings || " - "
          });
          break;
        case 'tasks_avoid':
          keyValues.push({
            key: "Tasks to avoid",
            value: data.FirstMeetingAvoid || " - "
          });
          break;
        case 'tasks_cando':
          keyValues.push({
            key: "Tasks can do",
            value: data.FirstMeetingCando || " - "
          });
          break;
        case 'no_treatment_recovery':
          keyValues.push({
            key: "Average Days of absence without treatment",
            value: data.FirstMeetingNoTreatment || " - "
          });
          break;
        case 'risk_asses':
          keyValues.push({
            key: "Manual Handling Risk Assessment",
            value: data.FirstMeetingRiskasses ? 'Yes' : 'No'
          });
          break;
        case 'dse_asses':
          keyValues.push({
            key: "DSE Assessment",
            value: data.FirstMeetingDseasses ? 'Yes' : 'No'
          });

          if(data.FirstMeetingDseasses) {
            keyValues.push({
              key: "DSE Assessment",
              value: $filter('translate')(data.FirstMeetingAdvanced) || " - "
            });
          }
          break;
        case 'ergo_asses':
          keyValues.push({
            key: "Ergonomic Assessment",
            value: data.FirstMeetingErgoasses ? 'Yes' : 'No'
          });
          break;
        case 'pain_score':
          keyValues.push({
            key: "Pain score (VAS)",
            value: data.FirstMeetingPain 
          });
          break;
        case 'therapygoals':
          var therapygoals = [];
          angular.forEach(data.TherapyGoals, function(therapygoal, index) {
            if(!angular.isUndefined(therapygoal.FirstGoalText1)) {
              therapygoals.push(therapygoal.FirstGoalText1);
            }
            if(!angular.isUndefined(therapygoal.FirstGoalText2)) {
              therapygoals.push(therapygoal.FirstGoalText2);
            }
          });

          therapygoals = therapygoals.length > 0 ? therapygoals.join(', ') : " - ";

          keyValues.push({
            key: "Therapy Goals",
            value: therapygoals
          });
          break;
        case 'information_to_manager_and_patient':
          keyValues.push({
            key: "Information to Manager and Patient",
            value: data.FirstMeetingInformationToManagerAndPatient || " - "
          });
          break;
        case 'informed_consent_gained_to_assess_and_treat':
          keyValues.push({
            key: "Informed consent gained from employee to assess and treat",
            value: data.InformedConsentGainedToAssessAndTreat  ? 'Yes' : 'No' || " - "
          });
          break;
        case 'chronicity_of_injury':
          keyValues.push({
            key: "Chronicity of injury",
            value: $filter('translate')(data.ChronicityOfInjury) || " - "
          });
          break;
      }

      return keyValues;
    }

    function _dischargeData(questionAlias, data) {
      var keyValues = [];

      switch(questionAlias) {
        case 'discharge_date':
          keyValues.push({
            key: "Date of discharge",
            value: $filter('date')(data.DischargeDate, 'dd/MM/yyyy') || " - "
          });
          break;
        case 'doctor':
          keyValues.push({
            key: "Assessor",
            value: data.DischargeDoctor || " - "
          });
          break;
        case 'treatments':
          keyValues.push({
            key: "Number of treatments",
            value: data.DischargeTreatments || " - "
          });
          break;
        case 'phonetriage':
          keyValues.push({
            key: "Telephone triage",
            value: data.DischargePhoneTriage || " - "
          });

          if(data.FirstMeetingFIT) {
            keyValues.push({
              key: "FIT note",
              value: data.FirstMeetingHasReturnPlan ? 'Yes' : 'No' || " - "
            });
          }

          if(data.FirstMeetingHasReturnPlan) {
            keyValues.push({
              key: "FIT note",
              value: data.FirstMeetingReturnPlan || " - "
            });
          }
          break;
        case 'handson':
          keyValues.push({
            key: "Hands on",
            value: data.DischargeHandsOn || " - "
          });

          // if(data.WorkStatus == 'OFF_WORK') {
          //   keyValues.push({
          //     key: "Date absence started",
          //     value: $filter('date')(data.FirstMeetingAbsenceDate, 'dd/MM/yyyy') || " - "
          //   });
          // }
          //
          // if(data.WorkStatus == 'RETURNED_TO_WORK') {
          //   keyValues.push({
          //     key: "Date returned to work",
          //     value: $filter('date')(data.FirstMeetingReturnedToWorkDate, 'dd/MM/yyyy') || " - "
          //   });
          // }
          break;
        case 'pain_score':
          keyValues.push({
            key: "Final pain score (VAS)",
            value: data.DischargePain 
          });
          break;
        case 'progress_therapist':
          keyValues.push({
            key: "Percentage progress ( Therapist Perception )",
            value: data.DischargePercentageProgressTherapist || " - "
          });
          break;
        case 'progress_patient':
          keyValues.push({
            key: "Percentage progress ( Patient Perception )",
            value: data.DischargePercentageProgressPatient || " - "
          });

          if(data.FirstMeetingDseasses) {
            keyValues.push({
              key: "DSE Assessment",
              value: data.FirstMeetingAdvanced || " - "
            });
          }
          break;
        case 'work_status':
          keyValues.push({
            key: "Working status",
            value: $filter('translate')(data.WorkStatus) || " - "
          });

          if(data.WorkStatus == 'RETURNED_TO_WORK' || data.WorkStatus == 'OFF_WORK') {
            keyValues.push({
              key: "Date absence started",
              value: $filter('date')(data.DischargeAbsenceDate, 'dd/MM/yyyy') || " - "
            });
          }

          if(data.WorkStatus == 'RETURNED_TO_WORK') {
            keyValues.push({
              key: "Date returned to work",
              value: $filter('date')(data.DischargeReturnedToWorkDate, 'dd/MM/yyyy') || " - "
            });
          }

          if(data.WorkStatus == 'OFF_WORK') {
            keyValues.push({
              key: "Expected date of return",
              value: $filter('date')(data.DischargeExpectedDateOfReturn, 'dd/MM/yyyy') || " - "
            });
          }
          break;
        case 'not_working_details':
          keyValues.push({
            key: "If not back, please detail reason and intended action plan",
            value: data.DischargeNotWorkingDetails  || " - "
          });
          break;
        case 'summary':
          keyValues.push({
            key: "Treatment summary and outcome",
            value: data.DischargeOutcome || " - "
          });
          break;
        case 'more_sessions':
          keyValues.push({
            key: "Request more Sessions",
            value: data.DischargeRequestMoreSessions ? 'Yes' : 'No' || " - "
          });

          if(data.DischargeRequestMoreSessions) {
            keyValues.push({
              key: "Extra Sessions Requested",
              value: data.DischargeExtraSessionsNumber || " - "
            });
          }
          break;
        case 'goals_achieved':
          keyValues.push({
            key: "Goals Achieved?",
            value: data.DischargeGoalsAchieved ? 'Yes' : 'No' || " - "
          });

          if(!data.DischargeGoalsAchieved) {
            keyValues.push({
              key: "Why?",
              value: data.DischargeGoalsFailedExplanation || " - "
            });
          }
          break;
        case 'information_to_manager_and_patient':
          keyValues.push({
            key: "Information to Manager and Patient",
            value: data.DischargeInformationToManagerAndPatient || " - "
          });
          break;
        case 'informed_consent_gained_to_assess_and_treat':
          keyValues.push({
            key: "Informed consent gained from employee to assess and treat",
            value: data.InformedConsentGainedToAssessAndTreat  ? 'Yes' : 'No' || " - "
          });

          break;
        case 'seen_gp':
          keyValues.push({
            key: "Seen by GP",
            value: data.DischargeGP ? 'Yes' : 'No' || " - "
          });
          break;
      }

      return keyValues;
    }

    function _managernotesData(questionAlias, data) {
      var keyValues = [];

      switch(questionAlias) {
        case 'manager_notes':
          keyValues.push({
            key: "Comments",
            value: data.ManagerNoteComments || " - "
          });
          break;
      }

      return keyValues;
    }
  }

}());
