(function() {
  'use strict';

  angular
    .module('app.admin.clients')
    .controller('ClientInitController', ClientInitController);

  ClientInitController.$inject = ['ClientsService', '$modalInstance'];

  function ClientInitController(ClientsService, $modalInstance) {
    var vm = this;

    vm.Client = {};
    vm.SaveClient = SaveClient;

    activate();

    function activate() {}

    function SaveClient() {
      
      vm.loading = true;
      ClientsService
        .SaveClient(vm.Client)
        .then(function(response) {
          $modalInstance.close(response.Client);
        })
        .finally(function() {
          vm.loading = false;
        });
    }

  }

}());
