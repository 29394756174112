/* jshint -W097, -W064 */
(function() {
  'use strict';

  angular
    .module('app.shared')
    .factory('AuditsService', AuditsService);

  AuditsService.$inject = ['$q', '$http', '$filter', '$cookies', 'SurveysService'];

  function AuditsService($q, $http, $filter, $cookies, SurveysService) {

    var service = {
      getAudits: getAudits,
      createAudit: createAudit
    };

    return service;

    function getAudits() {
      if(SurveysService._ClientID !== null) {
          return $http
            .get(APP.Service + "/audits/?CID=" + SurveysService._ClientID + '&rl=' + $cookies.get('rl'))
            .then(function(results){
              return results.data;
            });
        }
        else {
          return $http
            .get(APP.Service + "/audits")
            .then(function(results){
              return results.data;
            });
        }
    }

    function createAudit() {
      return $http
        .post(APP.Service + '/audits/createaudit?CID=' + SurveysService._ClientID + '&rl=' + $cookies.get('rl'))
        .then(function(results) {
            return results.data;
        });
    }
  }

}());
