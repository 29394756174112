(function() {
  'use strict';

  angular
    .module('app.admin.clients')
    .controller('ReferralsFirstMeetingController', ReferralsFirstMeetingController);

  ReferralsFirstMeetingController.$inject = [
    '$scope', '$stateParams','EnumsService', 'AuthService', 'SurveysService', 'WorkStatus',
    'Diagnosis', 'ActionPlan', 'ProposedManagement', 'AssessmentTypes', 'toaster',
    'ReferralType', 'Gender', 'SurveyPdfService', 'ConditionType', 'NatureOfWork', 'TypeOfReferral', 'ChronicityOfInjury'];

  function ReferralsFirstMeetingController(
    $scope, $stateParams,EnumsService, AuthService, SurveysService, WorkStatus,
    Diagnosis, ActionPlan, ProposedManagement, AssessmentTypes, toaster,
    ReferralType, Gender, SurveyPdfService, ConditionType, NatureOfWork, TypeOfReferral, ChronicityOfInjury) {
    var vm = this;

    // enums
    vm.Gender = Gender;
    vm.ReferralType =EnumsService.GetClientEnum(ReferralType);
    vm.WorkStatus =EnumsService.GetClientEnum(WorkStatus);
    vm.Diagnosis = Diagnosis;
    vm.ActionPlan = ActionPlan;
    vm.ProposedManagement = ProposedManagement;
    vm.AssessmentTypes = AssessmentTypes;
    vm.ConditionType = ConditionType;
    vm.NatureOfWork = NatureOfWork;
    vm.TypeOfReferral = EnumsService.GetClientEnum(TypeOfReferral);
    vm.ChronicityOfInjury = ChronicityOfInjury;
    vm.SplitNum = 2;

    vm.DSEChanged = DSEChanged;
    vm.LoadSurvey = LoadSurvey;
    vm.LoadFirstMeeting = LoadFirstMeeting;

    vm.RemoveTherapyGoal = RemoveTherapyGoal;
    vm.Submit = Submit;
    vm.ExportPDF = ExportPDF;

    activate();

    function activate() {
      vm.Back = $scope.$parent.vm.Back;
      vm.LoadSurvey();
    }

    function LoadSurvey() {
      vm.loading = true;
      SurveysService
        .GetSurveyByCode("firstmeeting", true)
        .then(function(CustomAndReferralSurvey) {
          vm.IsActive = CustomAndReferralSurvey.CustomSurvey.IsActive;
          vm.CanEdit = CustomAndReferralSurvey.CustomSurvey.CanEdit;
          vm.CustomQuestionnaire = vm.IsActive ? JSON.parse(CustomAndReferralSurvey.CustomSurvey.Configuration) : '';
          vm.Components = vm.IsActive ? SurveysService.ManageSurveyComponentsForView(vm.CustomQuestionnaire, vm.SplitNum) : '';
          vm.Questionnaire = JSON.parse(CustomAndReferralSurvey.ReferralSurvey.Configuration);
        })
        .finally(function() {
          vm.LoadFirstMeeting();
        });
    }

    function LoadFirstMeeting() {
      vm.FirstMeeting = {};
      vm.FirstMeeting.TherapyGoals = [];
      vm.FirstMeeting.ReferralID = $stateParams.ReferralID;
      vm.FirstMeeting.FirstMeetingDate = new Date();
      vm.FirstMeeting.FirstMeetingAbsenceDate = new Date();
      vm.FirstMeeting.FirstMeetingReturnedToWorkDate = new Date();
      vm.FirstMeeting.FirstMeetingExpectedDateOfReturn = new Date();
      vm.FirstMeeting.FirstMeetingExpectedDischargeDate = new Date();
      vm.DisplaySaveAndSend = true;
      vm.WithoutAppointment = false;

      SurveysService
        .GetFirstMeetingByReferralId($stateParams.ReferralID)
        .then(function(firstmeetingWithInfo) {
          firstmeetingWithInfo = firstmeetingWithInfo.data;
          vm.Referral = SurveysService.SetupReferralInfo(firstmeetingWithInfo.Referral);

          if(firstmeetingWithInfo.FirstMeeting !== null && !angular.isUndefined(firstmeetingWithInfo.FirstMeeting.TherapyGoals)) {
            vm.DisplayGoal = firstmeetingWithInfo.FirstMeeting.TherapyGoals.length >= 1 ? true : false;
            vm.DisplayGoal1 = firstmeetingWithInfo.FirstMeeting.TherapyGoals.length >= 2 ? true : false;
            vm.DisplayGoal2 = firstmeetingWithInfo.FirstMeeting.TherapyGoals.length >= 3 ? true : false;
          }

          // ean einai null den theloume na spasei to object pou ftiaksame tin arxh
          if(firstmeetingWithInfo.FirstMeeting !== null) {
            vm.FirstMeeting = firstmeetingWithInfo.FirstMeeting;
            vm.DisplayAltDiagnosis = vm.FirstMeeting.AltDiagnosis !== null ? true : false;
            vm.DisplaySaveAndSend = vm.FirstMeeting.SurveyStatus == "NOT_SENT";
          }
          else{
            // orizoume thn timh sto min gia na min exei NaN sto init
            var _questions = SurveysService.GetSurveyQuestions(vm.CustomQuestionnaire, ["pain_score","no_treatment_recovery"]);
            vm.FirstMeeting.FirstMeetingPain  = _questions.pain_score.min;
            vm.FirstMeeting.FirstMeetingNoTreatment = _questions.no_treatment_recovery.min;
            vm.DisplayAltDiagnosis = false;
          }

          vm.WithoutAppointment = firstmeetingWithInfo.WithoutAppointment;
          vm.DisplaySaveAndSend = vm.DisplaySaveAndSend && !vm.WithoutAppointment;
        }, function(error) {
          toaster.pop('error', "Could not retrieve the data", error.statusText);
        })
        .finally(function() {
          vm.loading = false;
        });
    }

    function Submit(exportToPdf) {
      vm.loading = true;
      vm.DisplayGoal = false;
      vm.DisplayGoal1 = false;
      vm.DisplayGoal2 = false;

      if(!vm.DisplayAltDiagnosis) {
        vm.FirstMeeting.AltDiagnosis = null;
        vm.FirstMeeting.PhoneTriageAltDiagnosisOther = null;
      }

      vm.FirstMeeting.SurveyStatus = vm.SendEmail ? "SENT" : "NOT_SENT";

      SurveysService
        .SubmitFirstMeeting(vm.FirstMeeting)
        .then(function(firstmeeting) {
          toaster.pop('success',"First Meeting was saved.", "Saved!");

          if(!angular.isUndefined(exportToPdf)) {
            vm.ExportPDF();
          }

          vm.LoadFirstMeeting();
        }, function(error) {
          vm.loading = false;
          toaster.pop('error', "Could not save the data", error.statusText);
        });
    }

    function RemoveTherapyGoal(key) {
      vm.FirstMeeting.TherapyGoals.splice(key, 1);
    }

    function ExportPDF() {
      SurveyPdfService
        .download(vm.CustomQuestionnaire, vm.FirstMeeting, vm.Questionnaire, vm.Referral);
    }

    function DSEChanged()
    {
        if(vm.PhoneTriage.PhoneTriageDseasses)
        {
          vm.PhoneTriage.PhoneTriageAdvanced = "STANDARD";
        }
        else {
          vm.PhoneTriage.PhoneTriageAdvanced =null;
        }
    }

  }
})();
