/* jshint -W097, -W033 */
(function() {
  'use strict';

  angular
    .module('app.shared')
    .provider('$data', DataProvider);

  DataProvider.$inject = [];

  function DataProvider() {
    var provider = this;
    provider.list = list;
    provider.get = get;
    provider.$get = $data;

    function list(resource, query) {
      return [
        'data',
        function(data) { // inject the data service
          return data.list(resource, query);
        }
      ]
    }

    function get(resource, query) {
      return [
        'data',
        function(data) {
          return data.get(resource, query);
        }
      ]
    }

    $data.$inject = ['api'];
    function $data(api) {

      var service = {
        list: list,
        get: get,
        save: save,
        create: create,
        update: update,
        remove: remove
      };

      return service;


      function list(resource, query) {
        return api[resource].query(query).$promise;
      }

      function get(resource, query) {
        return api[resource].get(query).$promise;
      }

      function save(resource, model) {
        if (model.ID) {
          return service.update(resource, model);
        } else {
          return service.create(resource, model);
        }
      }

      function create(resource, model) {
        return api[resource].save(model).$promise;
      }

      function update(resource, model) {
        return api[resource].update(model).$promise;
      }

      function remove(resource, model) {
        return api[resource].remove({
          ID: model.ID
        }).$promise;
      }

    }
  }

}());
