  (function() {
    'use strict';

    angular
      .module('app.admin.clients')
      .controller('SurveyPdfFormController', SurveyPdfFormController);

    SurveyPdfFormController.$inject = ['ModalParams', '$modalInstance', 'ConfigurationService', 'toaster'];

    function SurveyPdfFormController(ModalParams, $modalInstance, ConfigurationService, toaster) {
      var vm = this;
      vm.Pdf = _.clone(APP.Client.Pdf);

      vm.Save = Save;
      vm.SaveWithLogo = SaveWithLogo;
      vm.SimpleSave = SimpleSave;

      activate();

      function activate() {}

      function Save() {
        vm.loading = true;

        if(vm.File) {
          vm.SaveWithLogo();
        }
        else {
          vm.SimpleSave();
        }
      }

      function SaveWithLogo() {
        if(vm.File) {
          ConfigurationService
            .UploadFile({
              File: vm.File
            })
            .then(function(_logo) {
              vm.Pdf.CompanyLogo = _logo;
              vm.SimpleSave();
            });
        }
      }

      function SimpleSave() {
        var _data = {};

        _.mapObject(vm.Pdf, function(val, key) {
          _data["Pdf." + key] = val;
        });

        ConfigurationService
          .Save(_data)
          .then(function(response) {
            vm.loading = false;
            APP.Client.Pdf = vm.Pdf;

            toaster.pop('success',"Pdf details were saved.", "Saved!");
            $modalInstance.close();
          });
      }
    }
  }());
