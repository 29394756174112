(function() {
  'use strict';

  angular
    .module('app.shared', [

      'app.shared.auth',
      'app.shared.client',
      'app.shared.filemanager',

      // Third Parties
      'LocalStorageModule',
      'angular-google-analytics',
      'checklist-model',
      'credit-cards',
      'daterangepicker',
      'mwl.confirm',
      'ngFileUpload',
      'ngResource',
      'ngSanitize',
      'pascalprecht.translate',
      'permission',
      'tmh.dynamicLocale',
      'toaster',
      'ui.bootstrap',
      'ui.calendar',
      'ui.router',
      'ui.router.tabs',
      'dndLists',
      'rzModule',

      // 'ngBootstrap',
      // 'jkuri.slimscroll',
      // 'ncy-angular-breadcrumb',
    ]);
})();
