(function() {
  'use strict';

  angular
    .module('app.landing')
    .controller('LandingErrorController', LandingErrorController);

  LandingErrorController.$inject = ['$stateParams'];

  function LandingErrorController($stateParams) {

    var _errors = {
      generic: {
        Icon: "exclamation-triangle",
        Message: "An Error was occured",
        Description: "If the problem insists please contact us",
      },
      noclient: {
        Icon: "unlink",
        Message: "No client was selected",
        Description: "-",
      }
    };

    var vm = this;
    vm.Icon = _errors[$stateParams.err].Icon;
    vm.Message = _errors[$stateParams.err].Message;
    vm.Description = _errors[$stateParams.err].Description;

  }
  
}());
