(function() {
  'use strict';

  angular
    .module('app.landing.referral')
    .controller('ReferralRedirectController', ReferralRedirectController);

  ReferralRedirectController.$inject = ['$interval', '$sanitize', '$scope'];

  function ReferralRedirectController($interval, $sanitize, $scope) {

    var vm = this;

    activate();

    function activate() {
      vm.Counter = APP.Client.Extra.PortalRedirectToCounter;
      var _message = $sanitize(APP.Client.Extra.PortalRedirectToMessage).split("__DISPLAY_COUNTER__");

      $scope.$watch("vm.Counter", function (value) {
        DisplayMessage(_message, value);
      });

      Redirect();
    }

    function DisplayMessage(_message, counter) {
      if(_message.length > 1) {
        vm.Message = _message[0] + vm.Counter + _message[1];
      }
      else {
        vm.Message = _message[0];
      }
    }

    function Redirect() {
      $interval(function() {
        RedirectTo();
        --vm.Counter;
      }, 1000);
    }

    function RedirectTo() {
      if(vm.Counter <= 0) {
        window.location.href = APP.Client.Extra.PortalRedirectTo;
        return false;
      }
    }
  }
}());
