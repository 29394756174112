(function() {
  'use strict';

  angular.module('app.shared')

  .constant("EventTypes", [
      "CREATE",
      "UPDATE",
      "DELETE",
      "OTHER"
    ])
    
    .constant("DataTypes", [
//      "SESSION",
//      "CLASS",
//      "STUDENT",
//      "ENROLMENT"
    ])

}());
