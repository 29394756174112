/* jshint -W097, -W033 */
(function() {
  'use strict';

  angular
    .module('app.admin.manage')
    .constant('ManageMenu', [{
      Label: "Configuration",
      Icon: "cogs",
      Route: "admin.manage.configuration.system",
      Parent: "admin.manage.configuration",
    },
    {
      Label: "Users & Roles",
      Icon: "users",
      Route: "admin.manage.users",
    },
    {
      Label: "Clients Validation",
      Icon: "check",
      Route: "admin.manage.validation",
      Hide: true,
    },
    {
      Label: "Data Import & Export",
      Icon: "cloud",
      Route: "admin.manage.dataie",
      Hide: true,
    },
    {
      Label: "Help & Support",
      Icon: "question-circle",
      Route: "admin.manage.help",
      Hide: true,
    }, {
      Label: "Terms of Use",
      Icon: "pencil-square-o",
      Route: "admin.manage.terms",
      Hide: true,
    }]);


}());
