/* jshint -W097, -W033 */
(function() {
  'use strict';

  angular
    .module('app.shared')
    .config(AnalyticsConfig);

  AnalyticsConfig.$inject = ['configHelperProvider', 'AnalyticsProvider'];

  function AnalyticsConfig(configHelperProvider, AnalyticsProvider) {

    if (APP.Client.System.Debug) {
      return;
    }

    AnalyticsProvider.setAccount(APP.GoogleTrackCode);

    AnalyticsProvider.trackUrlParams(true);
    AnalyticsProvider.ignoreFirstPageLoad(true);
    AnalyticsProvider.setPageEvent('$stateChangeSuccess');

    if (APP.Client.System.Debug) {
      AnalyticsProvider.logAllCalls(true);
    }

    configHelperProvider.$get().notifyConfig("Google Analytics", APP.GoogleTrackCode);

  }

}());
