(function() {
  'use strict';

  angular
    .module('app.admin.clients')
    .controller('ClientConfigurationController', ClientConfigurationController);

  ClientConfigurationController.$inject = ['$scope', '$stateParams', 'ClientsModals', 'ClientsService', 'LocalesConstant'];

  function ClientConfigurationController($scope, $stateParams, ClientsModals, ClientsService, LocalesConstant) {

    var vm = this;

    vm.LoadConfiguration = LoadConfiguration;
    vm.SwitchDebug = SwitchDebug;
    vm.FindLocale = FindLocale;

    // modals
    vm.CompanyModal = CompanyModal;
    vm.TosModal = TosModal;
    vm.DisclaimerModal = DisclaimerModal;
    vm.LocalizationModal = LocalizationModal;
    vm.ThemeModal = ThemeModal;
    vm.ExtrasModal = ExtrasModal;
    vm.PdfModal = PdfModal;
    vm.AppointmentTypeCostModal = AppointmentTypeCostModal;

    vm.EmailByConditionModal = EmailByConditionModal;
    vm.SwitchEmailByCondition = SwitchEmailByCondition;

    activate();

    function activate() {
      ClientsService._ClientID = $stateParams.ID;
      vm.LoadConfiguration();
    }

    function LoadConfiguration() {
      ClientsService
        .GetConfiguration()
        .then(function(configuration) {
          vm.Configuration = configuration;
          vm.FindLocale();
        }, function(error) {
          toaster.pop('error', "Could not load the data", error.statusText);
        });
    }

    function SwitchDebug() {
      vm.Configuration["System.Debug"] = vm.Configuration["System.Debug"] == 'TRUE' ? 'FALSE' : 'TRUE';
      ClientsService
        .SaveConfiguration({
          "System.Debug": vm.Configuration["System.Debug"]
        })
        .then(function() {
          activate();
        }, function(error) {
          toaster.pop('error', "Could not save the data", error.statusText);
        });
    }

    function SwitchEmailByCondition() {
      vm.Configuration["ConditionTypeMails.Enable"] = vm.Configuration["ConditionTypeMails.Enable"] == 'TRUE' ? 'FALSE' : 'TRUE';
      ClientsService
        .SaveConfiguration({
          "ConditionTypeMails.Enable": vm.Configuration["ConditionTypeMails.Enable"]
        })
        .then(function() {
          activate();
        }, function(error) {
          toaster.pop('error', "Could not save the data", error.statusText);
        });
    }

    function EmailByConditionModal() {
      ClientsModals.EmailByConditionTypeForm({
           ConditionTypeMails:vm.Configuration["ConditionTypeMails.Data"] 
        })
        .then(function() {
          activate();
        });
    }

    


    function FindLocale(){
      vm.Locale = _.find(LocalesConstant,function(locale){
        return locale.Code == vm.Configuration["System.Locale"];
      });
    }

    function CompanyModal() {
      ClientsModals.CompanyForm({
          Company: {
            Address: vm.Configuration["Company.Address"],
            Email: vm.Configuration["Company.Email"],
            Logo: vm.Configuration["Company.Logo"],
            Name: vm.Configuration["Company.Name"],
            Person: vm.Configuration["Company.Person"],
            Phone: vm.Configuration["Company.Phone"],
            ExtraSessionEmails: vm.Configuration["Company.ExtraSessionEmails"],
            ExtraSessionResponseEmails: vm.Configuration["Company.ExtraSessionResponseEmails"],
            ExtraNewReferralEmails: vm.Configuration["Company.ExtraNewReferralEmails"]
          }
        })
        .then(function() {
          activate();
        });
    }

    function TosModal() {
      ClientsModals.CompanyTos({
          Tos: vm.Configuration["Company.Tos"]
        })
        .then(function() {
          activate();
        });
    }

    function DisclaimerModal() {
      ClientsModals.CompanyDisclaimer({
          Disclaimer: vm.Configuration["Company.Disclaimer"]
        })
        .then(function() {
          activate();
        });
    }

    function LocalizationModal() {
      ClientsModals.LocalizationForm({
          Locale: vm.Configuration["System.Locale"],
          Timezone: vm.Configuration["System.Timezone"]
        })
        .then(function() {
          activate();
        });
    }

    function ThemeModal() {
      ClientsModals.ThemeForm({
          Theme: {
            Title: vm.Configuration["Theme.Title"],
            Logo: vm.Configuration["Theme.Logo"],
            ColorScheme: vm.Configuration["Theme.ColorScheme"],
            Thank: vm.Configuration["Theme.Thank"],
          },
          PortalRedirectTo: vm.Configuration["Extra.PortalRedirectTo"],
          PortalRedirectToMessage: vm.Configuration["Extra.PortalRedirectToMessage"],
          PortalRedirectToCounter: vm.Configuration["Extra.PortalRedirectToCounter"]
        })
        .then(function() {
          activate();
        });
    }

    function ExtrasModal() {
      ClientsModals.ExtrasForm({
          MinimumAppointmentsToDischarge: vm.Configuration["Extra.MinimumAppointmentsToDischarge"],
          // PortalRedirectTo: vm.Configuration["Extra.PortalRedirectTo"],
          // PortalRedirectToMessage: vm.Configuration["Extra.PortalRedirectToMessage"],
          // PortalRedirectToCounter: vm.Configuration["Extra.PortalRedirectToCounter"]
        })
        .then(function() {
          activate();
        });
    }

    function PdfModal() {
      ClientsModals.PdfForm({
          Pdf: {
            CompanyLogo: vm.Configuration["Pdf.CompanyLogo"]
          }
        })
        .then(function() {
          activate();
        });
    }

    function AppointmentTypeCostModal() {
      ClientsModals.AppointmentTypeCostForm()
        .then(function() {
          activate();
        });
    }
  }
}());
