/* jshint -W097, -W033 */
(function() {
  'use strict';

  angular
    .module('app.admin.fixedcontent')
    .run(appRun);

  appRun.$inject = ['routerHelper', 'ContentConstant'];

  function appRun(routerHelper, ContentConstant) {
    routerHelper.configureStates(getStates(ContentConstant));
  }

  function getStates(Contents) {
    var _routes = [];
    angular.forEach(Contents, function(c) {
      _routes.push({
        state: c.State,
        config: {
          url: c.Url,
          params: {
            Content: c
          },
          controller: "FixedContentController",
          templateUrl: 'src/com.admin/fixedcontent/views/fixedcontent.html'
        }
      });
    });

    return _routes;
  }

}());
