/* jshint -W097, -W064 */
(function() {
  'use strict';

  angular
    .module('app')
    .directive('goBack', goBack);

  goBack.$inject = ['$window'];

  function goBack($window) {
    return {
      restrict: 'A',
      link: function(scope, element) {
        element.bind('click', function() {
          $window.history.back();
        });
      }
    };
  }
}());
