/* jshint -W097, -W064 */
(function() {
  'use strict';

  angular
    .module('app.landing', [
      'app.landing.referral',
      'app.landing.surveypdf',
      'bootstrapLightbox',
    ]);

}());
