/* jshint -W097, -W033 */
(function() {
  'use strict';

  angular
    .module('app.admin')
    .run(RoutesConfig);

  RoutesConfig.$inject = ['routerHelper'];

  function RoutesConfig(routerHelper) {
    routerHelper.configureStates(getStates());
  }

  function getStates() {
    return [{
      state: 'admin',
      config: {
        abstract: true,
        templateUrl: "src/com.admin/_common/_layout.html",
        data: {
          permissions: {
            except: ['Anonymous'],
            redirectTo: 'auth.login'
          }
        },
        controller: "AdminController",
      }
    }];
  }

}());
