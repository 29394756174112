/* jshint -W097, -W064 */
(function() {
  'use strict';

  angular
    .module('app.admin.menus')

  .constant('AdminTopMenu', [{
    Label: "Clients",
    Roles: ["MASTER", "PHYSIO", "MODERATOR", "RECEPTIONIST"],
    Route: "admin.clients.overview",
    Parent: "admin.clients",
    IsRight: false,
  }, {
    Label: "Manage",
    Roles: ["MASTER"],
    Route: "admin.manage.configuration.system",
    Parent: "admin.manage",
    IsRight: false,
  }, {
    Label: "Dashboard",
    Roles: ["MANAGER"],
    Route: "admin.manager",
    IsRight: false,
  }, {
    Label: "Referrals",
    Roles: ["MANAGER"],
    Route: "admin.manager_referrals",
    IsRight: false,
  }, {
    Label: "Calendar",
    Roles: ["MANAGER"],
    Route: "admin.manager_calendar",
    IsRight: false,
  }, {
    Label: "Archived",
    Roles: ["MANAGER"],
    Route: "admin.manager_archived",
    IsRight: false,
  }, {
    Label: "Closed Cases",
    Roles: ["MANAGER"],
    Route: "admin.manager_closedcases",
    IsRight: false,
  }, {
    Label: "Reports",
    Roles: ["MANAGER"],
    Route: "admin.manager_reports",
    IsRight: false,
  }, {
    Label: "MENU_LANDING",
    Roles: ["MANAGER"],
    Icon: "globe",
    IsRight: true,
    Route: "landing.referral",
  }, {
    Label: "MENU_SIGNOUT",
    Icon: "power-off",
    Route: "auth.logout",
    IsRight: true,
  }]);


}());
