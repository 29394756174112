/* jshint -W097, -W033 */
(function() {
  'use strict';

  angular
    .module('app.admin.clients')
    .constant('ClientStatuses', [
      "PENDING",
      "ACTIVE",
      "UNSUBSCRIBED",
      "DELETED"
    ])
    .constant('ClientMenu', [{
      Label: "Dashboard",
      Icon: "dashboard",
      Route: "admin.clients.client.dashboard",
      Roles: ["MASTER","PHYSIO","MANAGER","MODERATOR","RECEPTIONIST"],
      Constraint:["NOTEXTERNAL"],
    }, {
      Label: "Surveys",
      Icon: "question-circle",
      Route: "admin.clients.client.surveys",
      Roles: ["MASTER"],
    }, {
      Label: "Referrals",
      Icon: "book",
      Route: "admin.clients.client.referrals",
      Roles: ["PHYSIO","MODERATOR","RECEPTIONIST"],
    }, {
      Label: "Calendar",
      Icon: "calendar",
      Route: "admin.clients.client.calendar",
      Roles: ["PHYSIO","MODERATOR","RECEPTIONIST"],
      Constraint:["NOTEXTERNAL"],
    }, {
      Label: "Archived",
      Icon: "archive",
      Route: "admin.clients.client.archived",
      Roles: ["MODERATOR"],
    }, {
      Label: "Closed Cases",
      Icon: "folder",
      Route: "admin.clients.client.closedcases",
      Roles: ["MODERATOR"],
    }, {
      Label: "Exercise Images",
      Icon: "eye",
      Route: "admin.clients.client.imageitems",
      Roles: ["MASTER"],
    }, {
      Label: "Portal Managers",
      Icon: "users",
      Route: "admin.clients.client.portalmanagers",
      Roles: ["MASTER"],
    }, {
      Label: "Audits",
      Icon: "search",
      Route: "admin.clients.client.audits",
      Roles: ["MODERATOR"],
    }, {
      Label: "Reports",
      Icon: "bar-chart",
      Route: "admin.clients.client.reports",
      Roles: ["MODERATOR"],
    }, {
      Label: "Configuration",
      Icon: "gear",
      Route: "admin.clients.client.configuration",
      Roles: ["MASTER"],
    }])

}());
