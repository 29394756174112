(function() {
  'use strict';

  angular
    .module('app.admin.manager')
    .controller('ExtraSessionController', ExtraSessionController);

  ExtraSessionController.$inject = ['$stateParams', '$modalInstance', 'SurveysService', 'toaster', 'ModalParams'];

  function ExtraSessionController($stateParams, $modalInstance, SurveysService, toaster, ModalParams) {
    var vm = this;

    vm.SubmitAnswer = SubmitAnswer;

    activate();

    function activate() {
      vm.ExtraSession = ModalParams.ExtraSession;
      vm.IsArchived = $stateParams.IsArchived;
    }

    function SubmitAnswer(Answer) {
      vm.ExtraSession.ExtraSessionStatus = Answer;
      vm.loading = true;
      SurveysService
        .SubmitExtraSession(vm.ExtraSession)
        .then(function(extraSession) {
          vm.loading = false;
          $modalInstance.close(extraSession);
        }, function(error) {
          toaster.pop('error', "Could not save the data", error.statusText);
        });
    }
  }
})();
