(function() {
  'use strict';

  angular
    .module('app.admin.manager')
    .controller('ManagerDashboardController', ManagerDashboardController);

  ManagerDashboardController.$inject = ['$stateParams', 'DashboardService', 'AuthService', 'SurveysService'];

  function ManagerDashboardController($stateParams, DashboardService, AuthService, SurveysService) {

    var vm = this;

    activate();

    function activate() {
      GetChart();
      GetRecentReferrals();
      GetLateReferrals();
      GetStatistics();
      GetLatestAppointments();

      vm.Display = AuthService.Roles[0] === "MANAGER" || AuthService.Roles[0] === "MODERATOR";

      if(vm.Display) {
        GetDischargesPerMonth();
        GetNewUsersPerMonth();
        GetNumberOfDaysSavedPerMonth();
      }
    }

    function GetChart() {
      vm.ChartLoading = true;
      DashboardService
        .GetChart()
        .then(function(result) {
          SetChart(result);
          vm.ChartLoading = false;
        });
    }

    function SetChart(serviceResults) {
      vm.Chart = {};
      vm.Chart.Data = [];
      vm.Chart.Labels = [];
      vm.Chart.Series = ['Archived Referrals', 'New Referrals', 'Total Referrals', 'Total Appointments'];
      vm.Chart.Options = {legend:{display:false}};

      var closedCases = [];
      var archivedReferrals = [];

      var activeReferrals = [];
      var totalReferrals = [];
      var totalAppointments = [];
      angular.forEach(serviceResults, function(result, label) {
        vm.Chart.Labels.push(label);

        //closedCases.push(result.Data.CaseClosed);
        archivedReferrals.push(result.Data.ArchivedReferrals);
        activeReferrals.push(result.Data.ActiveReferrals);
        totalReferrals.push(result.Data.ArchivedReferrals +result.Data.CaseClosed + result.Data.ActiveReferrals);
        totalAppointments.push(result.Data.Appointments);
      });

      vm.Chart.Data.push(archivedReferrals, activeReferrals, totalReferrals, totalAppointments);
    }

    function GetRecentReferrals() {
      vm.RecentReferralsLoading = true;
      DashboardService
        .GetRecentReferrals()
        .then(function(result) {
          vm.RecentReferrals = result;
          vm.RecentReferralsLoading = false;
        }, function(error) {
          toaster.pop('error', "Could not retrieve the recent referrals", error.statusText);
        });
    }

    function GetLateReferrals() {
      vm.LateReferralsLoading = true;
      DashboardService
        .GetLateReferrals()
        .then(function(result) {
          vm.LateReferrals = result;
          vm.LateReferralsLoading = false;
        }, function(error) {
          toaster.pop('error', "Could not retrieve the late referrals", error.statusText);
        });
    }

    function GetStatistics() {
      vm.StatisticsLoading = true;
      DashboardService
        .GetStatistics()
        .then(function(result) {
          vm.Statistics = result;
          vm.StatisticsLoading = false;
        }, function(error) {
          toaster.pop('error', "Could not retrieve the statistics", error.statusText);
        });
    }

    function GetLatestAppointments() {
      vm.LatestAppointmentsLoading = true;
      DashboardService
        .GetLatestAppointments()
        .then(function(result) {
          vm.LatestAppointments = result;
          vm.LatestAppointmentsLoading = false;
        }, function(error) {
          toaster.pop('error', "Could not retrieve the latest appointments", error.statusText);
        });
    }

    function GetNewUsersPerMonth() {
      vm.NewUsersPerMonthCount = 0;
      vm.NewUsersPerMonthCountLoading = true;
      DashboardService
        .GetNewUsersPerMonth()
        .then(function(result) {
          vm.NewUsersPerMonthCount = result;
          vm.NewUsersPerMonthCountLoading = false;
        }, function(error) {
          toaster.pop('error', "Could not retrieve the new users", error.statusText);
        });
    }

    function GetDischargesPerMonth() {
      vm.DischargesPerMonthCount = 0;
      vm.DischargesPerMonthCountLoading = true;
      DashboardService
        .GetDischargesPerMonth()
        .then(function(result) {
          vm.DischargesPerMonthCount = result;
          vm.DischargesPerMonthCountLoading = false;
        }, function(error) {
          toaster.pop('error', "Could not retrieve the discharges", error.statusText);
        });
    }

    function GetNumberOfDaysSavedPerMonth() {
      vm.NumberOfDaysSaved = 0;
      vm.NumberOfDaysSavedLoading = true;
      DashboardService
        .GetNumberOfDaysSavedPerMonth()
        .then(function(result) {
          vm.NumberOfDaysSaved = result;
          vm.NumberOfDaysSavedLoading = false;
        }, function(error) {
          toaster.pop('error', "Could not retrieve the number of days saved", error.statusText);
        });
    }

  }
}());
