/* jshint -W097, -W033 */
(function () {
  'use strict';

  angular
    .module('app.shared.auth')
    .factory('AuthService', AuthService);

  AuthService.$inject = ['$http', '$q', 'localStorageService', 'ClientService'];

  function AuthService($http, $q, localStorageService, ClientService) {
    var service = {
      IsAuth: false,
      Username: null,
      ExpiresAt: 0,
      Roles: [],
      IsExternal: false,

      Init: Init,
      SignIn: SignIn,
      SignOut: SignOut,
      ConfirmAccount: ConfirmAccount,

      ForgotPassword: ForgotPassword,
      ResetPassword: ResetPassword,
      ResetExpPassword: ResetExpPassword,
    }

    return service;

    function Init() {

      var AuthorizationData = localStorageService.get('AuthorizationData');
      // console.log("AuthorizationData",AuthorizationData);
      if (!_.isEmpty(AuthorizationData)) {
        service.IsAuth = true;
        service.Username = AuthorizationData.Username;
        service.Roles = AuthorizationData.Roles;
        service.ExpiresAt = AuthorizationData.ExpiresAt;
        service.isTemporaryPassword = AuthorizationData.isTemporaryPassword;
        service.IsExternal = AuthorizationData.IsExternal;
      }

    }

    function SignIn(credentials) {

      return $http
        .post(APP.Service + '/token', {
          grant_type: "password",
          Username: credentials.username,
          password: credentials.password,
          client_id: APP.ClientID
        }, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          transformRequest: function (value) {
            return $.param(value);
          },
        })
        .then(function (response) {
          // console.log("response.data",response.data);
          // console.log("Sum", moment().unix() + response.data.expires_in)
          localStorageService.set('AuthorizationData', {
            Token: response.data.access_token,
            ExpiresAt: moment().unix() + response.data.expires_in,
            Username: response.data.userName,
            Roles: response.data.roles.split(","),
            isTemporaryPassword: response.data.isTemporaryPassword,
            IsExternal: response.data.IsExternal === "true"
          });

          if (APP.UseRefreshTokens) {
            localStorageService.set('RefreshToken', response.data.refresh_token);
          }

          service.IsAuth = true;
          service.Roles = response.data.roles.split(",");
          service.Username = credentials.Username;
          service.isTemporaryPassword = response.data.isTemporaryPassword;
          service.ExpiresAt = moment().unix() + response.data.expires_in;
          service.IsExternal = response.data.IsExternal === "true";

        }, function (err) {
          service.SignOut();
          return $q.reject(err.data);
        });

    }

    function SignOut() {
      localStorageService.remove('AuthorizationData');
      localStorageService.remove('RefreshToken');
      service.IsAuth = false;
      service.Username = null;
    }

    function ConfirmAccount(data) {
      return $http.post(APP.Service + '/confirm', data);
    }

    function ForgotPassword(data) {
      return $http.post(APP.Service + '/forgotpassword', data);
    }

    function ResetPassword(data) {
      return $http.post(APP.Service + '/resetpassword', data);
    }

    function ResetExpPassword(data) {
      return $http.post(APP.Service + '/resetexppassword', data);
    }
  }

}());