  (function() {
    'use strict';

    angular
      .module('app.admin.users')
      .controller('UserFormController', UserFormController);

    UserFormController.$inject = ['UsersService', 'ClientsService', 'ModalParams', 'UserRoles', "$modalInstance"];

    function UserFormController(UsersService, ClientsService, ModalParams, UserRoles, $modalInstance) {
      var vm = this;
      vm.Roles = UserRoles;
      vm.SaveUser = SaveUser;
      vm.RoleChanged = RoleChanged;

      activate();

      function activate() {
        vm.loading = true;
        ClientsService
          .GetClients()
          .then(function(data) {
            var clients = [];
            _.each(data, function(client, key) {
              clients.push({key: client.ID, value: client.CompanyName});
            });

            vm.Clients = clients;
          })
          .finally(function() {
            if (ModalParams.UserID) {
              LoadUser();
            }
            else {
              vm.User = {};
              vm.User.Physio = {};
              vm.User.Physio.IsExternal = 0;
              vm.loading = false;
            }
          });
      }

      function LoadUser() {
        UsersService
          .GetUser(ModalParams.UserID)
          .then(function(response) {
            if(response.Role === "MANAGER") {
              response.Clients = response.Clients[0];
            }
            vm.User = response;
            vm.User.Id = ModalParams.UserID;
          })
          .finally(function() {
            vm.loading = false;
          });
      }

      function SaveUser() {
        vm.loading = true;

        if(!angular.isArray(vm.User.Clients)) {
          vm.User.Clients = [vm.User.Clients];
        }

        UsersService
          .SaveUser(vm.User)
          .then(function(response) {
            $modalInstance.close(response);
          }, function(reason) {
            $modalInstance.dismiss(reason);
          })
          .finally(function() {
            vm.loading = false;
          });
      }

      function RoleChanged() {
        delete vm.User.Clients;
      }
    }
  }());
