  (function() {
    'use strict';

    angular
      .module('app.admin.clients')
      .controller('ClientCompanyFormController', ClientCompanyFormController);

    ClientCompanyFormController.$inject = ['ModalParams', '$modalInstance', 'ClientsService', 'toaster'];

    function ClientCompanyFormController(ModalParams, $modalInstance, ClientsService, toaster) {
      var vm = this;

      vm.Save = Save;
      vm.SaveWithLogo = SaveWithLogo;
      vm.SimpleSave = SimpleSave;

      activate();

      function activate() {
        vm.Company = ModalParams.Company;
      }

      function Save() {
        vm.loading = true;

        if(vm.File) {
          vm.SaveWithLogo();
        }
        else {
          vm.SimpleSave();
        }
      }

      function SaveWithLogo() {
          ClientsService
            .UploadFile({
              File: vm.File
            })
            .then(function(_logo) {
              vm.Company.Logo = _logo;
              vm.SimpleSave();
            }, function(error) {
              toaster.pop('error', "Could not upload the logo", error.statusText);
            });
      }

      function SimpleSave() {
        var _data = {};

        vm.Company.ExtraSessionEmails = vm.Company.ExtraSessionEmails.replace(/[\s]/g, '');
        vm.Company.ExtraSessionResponseEmails = vm.Company.ExtraSessionResponseEmails.replace(/[\s]/g, '');
        vm.Company.ExtraNewReferralEmails = vm.Company.ExtraNewReferralEmails.replace(/[\s]/g, '');

        _.mapObject(vm.Company, function(val, key) {
          _data["Company." + key] = val;
        });

        ClientsService
          .SaveConfiguration(_data)
          .then(function(_config) {

            ClientsService
              .GetClient(ClientsService._ClientID)
              .then(function(_client) {

                _client.CompanyName = vm.Company.Name;
                _client.Contact = {
                  Name: vm.Company.Person,
                  Email: vm.Company.Email
                };

                ClientsService
                  .SaveClient(_client)
                  .then(function(response) {
                    vm.loading = false;

                    toaster.pop('success',"Company details were saved.", "Saved!");
                    $modalInstance.close();
                  });

              }, function(error) {
                toaster.pop('error', "Could not save the data", error.statusText);
              });

          });
      }
    }
  }());
