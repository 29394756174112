  (function() {
    'use strict';

    angular
      .module('app.admin.clients')
      .controller('ClientDisclaimerFormController', ClientDisclaimerFormController);

    ClientDisclaimerFormController.$inject = ['ModalParams', '$modalInstance', 'ClientsService', 'toaster'];

    function ClientDisclaimerFormController(ModalParams, $modalInstance, ClientsService, toaster) {
      var vm = this;

      vm.Save = Save;
      vm.loading = false;

      activate();

      function activate() {
        vm.Disclaimer = ModalParams.Disclaimer;
      }

      function Save() {
        vm.loading = true;

        ClientsService
          .SaveConfiguration({
            "Company.Disclaimer": vm.Disclaimer
          })
          .then(function() {
            vm.loading = false;

            toaster.pop('success',"Disclaimer was saved.", "Saved!");
            $modalInstance.close();
          }, function(error) {
            toaster.pop('error', "Could not save the data", error.statusText);
          });
      }
    }
  }());
