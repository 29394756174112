/* jshint -W097, -W033 */
(function() {
  'use strict';

  angular
    .module('app.shared')
    .directive('clickOff', clickOff)

  clickOff.$inject = []

  function clickOff() {
    return {
      restrict: 'A',
      link: function(scope, element, attrs) {
        var isOff;
        isOff = true;
        angular.element(document.body).bind('click', function() {
          if (isOff) {
            scope.$eval(attrs.clickOff);
            scope.$digest();
          }
          return isOff = true;
        });
        return element.bind('click', function() {
          isOff = false;
        });
      }
    };
  }
}());
