/* jshint -W097, -W033 */
(function() {
  'use strict';

  angular
    .module('app.shared')
    .config(HttpConfig);

  HttpConfig.$inject = ['configHelperProvider', '$httpProvider'];

  function HttpConfig(configHelperProvider, $httpProvider) {

    var _interceptors = [
      //'ErrorInterceptor',
      'AuthInterceptor',
      'ClientInterceptor',
    ];

    _.each(_interceptors, function(_interceptor) {
      $httpProvider.interceptors.push(_interceptor);
    })

    configHelperProvider.$get().notifyConfig("Http Interceptors", _interceptors);
  }

}());
