(function() {
  'use strict';

  angular
    .module('app.admin.manager')
    .controller('ExerciseController', ExerciseController);

  ExerciseController.$inject = ['$stateParams', '$modalInstance', 'SurveysService', 'toaster', 'ModalParams'];

  function ExerciseController($stateParams, $modalInstance, SurveysService, toaster, ModalParams) {
    var vm = this;

    vm.ChosenImages = [];

    vm.LoadIllnesses = LoadIllnesses;
    vm.LoadImageItems = LoadImageItems;
    vm.LoadOrCreateExercise = LoadOrCreateExercise;
    vm.CheckAll = CheckAll;
    vm.FilterImageItems = FilterImageItems;
    vm.Submit = Submit;

    activate();

    function activate() {
      vm.CustomQuestionnaire = ModalParams.CustomQuestionnaire;
      vm.LoadIllnesses();
    }

    function LoadIllnesses() {
      vm.loading = true;
      SurveysService
        .GetIllnesses()
        .then(function(illnesses) {
          vm.Illnesses = illnesses;
        }, function(error) {
          toaster.pop('error', "Could not retrieve the data", error.statusText);
        })
        .finally(function() {
          vm.loading = false;
          vm.LoadImageItems();
        });
    }

    function LoadImageItems() {
      vm.loading = true;
      SurveysService
        .GetImageItems()
        .then(function(imageItems) {
          vm.ImageItems = imageItems;
        }, function(error) {
          toaster.pop('error', "Could not retrieve the data", error.statusText);
        })
        .finally(function() {
          vm.LoadOrCreateExercise();
          vm.loading = false;
        });
    }

    function LoadOrCreateExercise() {
      if(ModalParams.Exercise) {
        vm.Exercise = ModalParams.Exercise;
      }
      else {
        vm.Exercise = {};
        vm.Exercise.ReferralID = ModalParams.ReferralID;
      }
      InitExerciseImages();
    }

    function CheckAll() {
      vm.ChosenImages = _.map(vm.ImageItems, function(exerciseImage) {
        return exerciseImage.ID;
      });
    }

    function FilterImageItems() {
      if(vm.SelectedIllness !== null) {
        _.each(vm.ImageItems, function(exerciseImage, index) {
          exerciseImage.IsActive = false;
          _.each(exerciseImage.Illnesses, function(illness, illnessIndex) {
            if(illness.IllnessID == vm.SelectedIllness) {
              exerciseImage.IsActive = true;
            }
          });
       });
      }
      else {
        _.each(vm.ImageItems, function(exerciseImage, index) {
          exerciseImage.IsActive = true;
        });
      }
    }

    function Submit() {
      vm.loading = true;
      vm.Exercise.ImageItems = ModifyExerciseImages();
      vm.Exercise.SurveyStatus = vm.SendEmail ? "SENT" : "NOT_SENT";
      SurveysService
        .SubmitExercise(vm.Exercise)
        .then(function(exercise) {
          vm.loading = false;
          toaster.pop('success',"Exercise was saved.", "Saved!");
          $modalInstance.close(exercise);
        }, function(error) {
          vm.loading = false;
          toaster.pop('error', "Could not retrieve the data", error.statusText);
        });
    }

    function InitExerciseImages() {
      _.each(vm.Exercise.ImageItems, function(existingExerciseImage, existingExerciseImageIndex) {
         _.each(vm.ImageItems, function(exerciseImage, exerciseImageIndex) {
          if(exerciseImage.ID == existingExerciseImage.ImageID) {
            vm.ChosenImages.push(existingExerciseImage.ImageID);
          }
        });
      });
    }

    function ModifyExerciseImages() {
      return _.map(vm.ChosenImages, function(imageID) {
        return {
          ExerciseID: -1, // to ekana etsi giati panta sto admin vazoume nea den kanoume pote update, apla to vazo gia na uparexei kati kai na mhn einai null
          ImageID: imageID
        };
      });
    }
  }
})();
