(function() {
  'use strict';

  angular
    .module('app.admin.manager')
    .controller('ManagerFinalDischargeController', ManagerFinalDischargeController);

  ManagerFinalDischargeController.$inject = ['$scope', '$stateParams','EnumsService', 'WorkStatus', 'AuthService', 'SurveysService', 'toaster', 'ReferralType', 'Gender', 'SurveyPdfService'];

  function ManagerFinalDischargeController($scope, $stateParams,EnumsService, WorkStatus, AuthService, SurveysService, toaster, ReferralType, Gender, SurveyPdfService) {
    var vm = this;

    // enums
    vm.Gender = Gender;
    vm.ReferralType =EnumsService.GetClientEnum(ReferralType,APP.Domain);
    vm.WorkStatus = EnumsService.GetClientEnum(WorkStatus,APP.Domain);
    vm.SplitNum = 2;

    vm.LoadSurvey = LoadSurvey;
    vm.LoadFinalDischarge = LoadFinalDischarge;

    vm.Submit = Submit;
    vm.ExportPDF = ExportPDF;

    activate();

    function activate() {
      vm.Back = $scope.$parent.vm.Back;
      vm.LoadSurvey();
    }

    function LoadSurvey() {
      vm.loading = true;
      SurveysService
        .GetSurveyByCode("finaldischarge", true)
        .then(function(CustomAndReferralSurvey) {
          vm.IsActive = CustomAndReferralSurvey.CustomSurvey.IsActive;
          vm.CanEdit = CustomAndReferralSurvey.CustomSurvey.CanEdit;
          vm.CustomQuestionnaire = vm.IsActive ? JSON.parse(CustomAndReferralSurvey.CustomSurvey.Configuration) : '';
          vm.Components = vm.IsActive ? SurveysService.ManageSurveyComponentsForView(vm.CustomQuestionnaire, vm.SplitNum) : '';
          vm.Questionnaire = JSON.parse(CustomAndReferralSurvey.ReferralSurvey.Configuration);
        }, function(error) {
          toaster.pop('error', "Could not retrieve the survey", error.statusText);
        })
        .finally(function() {
          vm.LoadFinalDischarge();
        });
    }

    function LoadFinalDischarge() {
      vm.Discharge = {};
      vm.Discharge.ReferralID = $stateParams.ID;
      vm.Discharge.IsFinal = 1;
      vm.Discharge.IsArchived = true;
      vm.Discharge.DischargeDate = new Date();
      vm.Discharge.DischargeAbsenceDate = new Date();
      vm.Discharge.DischargeReturnedToWorkDate = new Date();
      vm.Discharge.DischargeExpectedDateOfReturn = new Date();
      vm.DisplaySaveAndSend = true;

      SurveysService
        .GetDischargeByReferralId($stateParams.ID, true)
        .then(function(dischargeWithInfo) {
          vm.Referral = SurveysService.SetupReferralInfo(dischargeWithInfo.Referral);

          // ean einai null den theloume na spasei to object pou ftiaksame tin arxh
          if(dischargeWithInfo.Discharge !== null) {
            vm.Discharge = dischargeWithInfo.Discharge;
            vm.DisplaySaveAndSend = vm.Discharge.SurveyStatus == "NOT_SENT";
          }
          else{
            // orizoume thn timh sto min gia na min exei NaN sto init
            var _questions = SurveysService.GetSurveyQuestions(vm.CustomQuestionnaire, ["pain_score"]);
            vm.Discharge.DischargePain = _questions.pain_score.min;
          }
        }, function(error) {
          toaster.pop('error', "Could not retrieve the data", error.statusText);
        })
        .finally(function() {
          vm.loading = false;
        });
    }

    function Submit(exportToPdf) {
      vm.loading = true;
      vm.Discharge.SurveyStatus = vm.SendEmail ? "SENT" : "NOT_SENT";

      SurveysService
        .SubmitDischarge(vm.Discharge)
        .then(function(discharge) {
          toaster.pop('success',"Final Discharge was saved.", "Saved!");

          if(!angular.isUndefined(exportToPdf)) {
            vm.ExportPDF();
          }

          vm.LoadFinalDischarge();
        }, function(error) {
          vm.loading = false;
          toaster.pop('error', "Could not save the data", error.statusText);
        });
    }

    function ExportPDF() {
      SurveyPdfService
        .download(vm.CustomQuestionnaire, vm.Discharge, vm.Questionnaire, vm.Referral);
    }
  }
})();
