/* jshint -W097, -W033 */
(function() {
  'use strict';

  angular
  .module('app.shared.auth')
  .run(appRun);

  appRun.$inject = ['routerHelper'];

  function appRun(routerHelper) {
    routerHelper.configureStates(getStates());
  }

  function getStates() {
    return [{
      state: 'auth',
      config: {
        abstract: true,
        template: "<ui-view/>",
      }
    }, {
      state: 'auth.login',
      config: {
        url: '/login',
        params: {
          ref: null
        },
        templateUrl: "src/com.shared/auth/views/login.html",
        controller: "AuthLoginController",
        data: {
          permissions: {
            only: ['Anonymous'],
            redirectTo: "rd"
          }
        }
      }
    }, {
      state: 'auth.logout',
      config: {
        url: '/logout',
        template: "<ui-view/>",
        controller: function(AuthService, $state) {
          AuthService.SignOut();
          $state.go("auth.login", {
            ref: "so"
          });
        },
      }
    }, {
      state: 'auth.confirm',
      config: {
        url: '/confirm?:u&:token',
        templateUrl: "src/com.shared/auth/views/confirm.html",
        controller: "AuthConfirmController",
        data: {
          permissions: {
            only: ['Anonymous'],
            redirectTo: "rd"
          }
        }
      }
    }, {
      state: 'auth.forgotpass',
      config: {
        url: '/reset?:u&:token',
        templateUrl: "src/com.shared/auth/forgotpass/reset.html",
        controller: "AuthResetPassController",
        data: {
          permissions: {
            only: ['Anonymous'],
            redirectTo: "auth.login"
          }
        }
      }
    }, {
      state: 'auth.rc',
      config: {
        url: '/rc',
        params: {
          ref: null
        },
        templateUrl: "src/com.shared/auth/views/role_choice.html",
        controller: "AuthRoleChoiceController",
        data: {
          permissions: {
            only: ['Anonymous'],
            redirectTo: "rd"
          }
        }
      }
    }];
  }

}());
