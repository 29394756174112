/* jshint -W097, -W033 */
(function() {
  'use strict';

  angular
    .module('app.shared')
    .config(TranslateConfig);

  TranslateConfig.$inject = ['configHelperProvider', '$translateProvider'];

  function TranslateConfig(configHelperProvider, $translateProvider) {

    $translateProvider.useStaticFilesLoader({
      prefix: '/translations/',
      suffix: '.json'
    });
    // καταστρέφει τα ελληνικα
    // $translateProvider.useSanitizeValueStrategy('sanitize');
    $translateProvider.preferredLanguage('en-gb');

    configHelperProvider.$get().notifyConfig("Translate", "Initialized");

  }

}());
