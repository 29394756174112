(function () {
  'use strict';

  angular
    .module('app.admin')
    .controller('AdminController', AdminController);

  AdminController.$inject = ['$state', '$cookies', 'AuthService', 'AuthModals', 'FixedContentService', 'AdminTopMenu', 'LoginRoles'];

  function AdminController($state, $cookies, AuthService, AuthModals, FixedContentService, AdminTopMenu, LoginRoles) {
    var vm = this;

    vm.ResetSession = ResetSession;
    vm.IsTemporaryPassword = AuthService.IsTemporaryPassword;
    vm.ExpiresAt = AuthService.ExpiresAt;
    vm.Role = AuthService.Roles[0];
    vm.TopMenu = _.filter(AdminTopMenu, function (item) {
      if (!item.Roles) return true;
      return _.contains(item.Roles, AuthService.Roles[0]);
    });
    vm.FixedModal = FixedModal;
    vm.lastMove = new Date()

    activate();

    function activate() {
      ChooseRole();
      vm.Username = AuthService.Username;
      if (AuthService.ExpiresAt < moment().unix()) {
        // console.log("Bilias7");
        $state.go("auth.logout", {
          ref: "to"
        });
        return;
      }

      validatePasswordReset()

      vm.interv = setInterval(function () {
        console.log("last move", vm.lastMove)
        if (moment().subtract(15, 'minutes').isAfter(vm.lastMove)) {
          clearInterval(vm.interv)
          $state.go("auth.logout", {
            ref: "to"
          });
        }
      }, 1 * 60 * 1000)
    }

    function ResetSession() {
      vm.lastMove = new Date();
    }

    function FixedModal(alias, size) {
      FixedContentService.Modal(alias, size);
    }

    function ChooseRole() {
      var roles = [];
      // console.log(AuthService.Roles);
      _.map(AuthService.Roles, function (role) {
        if (LoginRoles.indexOf(role) != -1) {
          roles.push(role);
        }
      });
      $cookies.put('rl', roles[0]);
    }

      function validatePasswordReset() {
          return;
          //if (APP.Client.UserSettings) {
          //  if (moment()
          //    .subtract(parseInt(APP.Client.UserSettings.PassIntervMonths), 'months')
          //    .isBefore(APP.Client.UserSettings.LastPassChange)
          //  ) {
          //    return;
          //  }
          //}
          //AuthModals
          //  .ResetExpPassword()
          //  .then(function (result) {
          //    if (result == "OK") {
          //      $state.go("auth.logout", {
          //        ref: "to"
          //      });
          //    }
          //  })
      }
  }

}());